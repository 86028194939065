import React, { useState } from 'react';
import { Modal, Upload, Button, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { convertEBCDICToASCIIAndSetContent } from './utility-methods/ebcdic2ascii';
import { SharedService } from '../../../services/shared-service';
import { setActiveModal } from '../../../redux/app-global';
import { EAppModal } from '../../../redux/app-global/app-global.types';
import { FaJava } from 'react-icons/fa';
import TrexIcon from '../../../assets/images/svg/t-rex';
import { Row } from './test-chevron.types';
import detectFileEncodingAndLanguage from 'detect-file-encoding-and-language';
import { externalConfig } from 'src/utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';
import axios from 'axios';

interface FileReadResult {
    content: string;
    isAscii: boolean;
    encoding: string;
}

interface UploadCompareModalProps {
    index: number;
    data: any[]; // Define the type as per your data structure
    setData: (data: any[]) => void;
    isVisible: boolean;
    onClose: () => void;
    jobData: Row;
}

const UploadCompareModal: React.FC<UploadCompareModalProps> = ({ index, data, setData, isVisible, onClose, jobData }) => {
    const [javaFile, setJavaFile] = useState<File | null>(null);
    const [mfFile, setMfFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const projectId = getSearchParameter('projectId');
    const dispatch = useDispatch();
    const recordFormat = jobData?.recordFormat.includes('Fixed Block') ? 'Fixed' : (jobData?.recordFormat === 'Variable Block' ? 'Variable Length' : null);
    const readFileAsText = async (file: File): Promise<FileReadResult> => {
        const detected = await detectFileEncodingAndLanguage(file);
        const encoding = detected.encoding || 'EBCDIC';
        console.log(encoding)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result as string;
                let isAscii = true;
                for (let i = 0; i < result.length; i++) {
                    if (result.charCodeAt(i) > 127) {
                        isAscii = false;
                        break;
                    }
                }
                resolve({ content: result, isAscii, encoding });
            };
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    const handleFileUpload = async () => {
        setLoading(true);
        SharedService.javaFileName = javaFile?.name || '';
        SharedService.mfFileName = mfFile?.name || '';
        if (recordFormat) {
          try {
            
            const javaPromise = readFileAsText(javaFile!).then(async ({ content, encoding }) => {
                if(encoding !== 'EBCDIC') {
                    const cfContent = content || '';
                    return {cfContent, encoding};
                }
                const { cfContent } = await convertEBCDICToASCIIAndSetContent(javaFile!, "Java", recordFormat, jobData.recordLength);
                return {cfContent, encoding}; 
            });
      
            
            const mfPromise = readFileAsText(mfFile!).then(async ({ content, encoding }) => {
                if(encoding !== 'EBCDIC') {
                    const mfContent = content || '';
                    return {mfContent, encoding};
                  }
                const { mfContent } = await convertEBCDICToASCIIAndSetContent(mfFile!, "MainFrame", recordFormat, jobData.recordLength);
                return {mfContent, encoding}; 
            });
      
            
            const [resolvedJavaContent, resolvedMfContent] = await Promise.all([javaPromise, mfPromise]);
      
            
            const { cfContent: javaFileContent, encoding: javaEncoding } = resolvedJavaContent;
            const { mfContent: mfFileContent, encoding: mfEncoding } = resolvedMfContent;
    
            SharedService.javaFileContent = javaFileContent;
            SharedService.mfFileContent = mfFileContent;

            SharedService.javaEncoding = javaEncoding;
            SharedService.mfEncoding = mfEncoding;

            setTimeout(() => {
              setLoading(false);
            }, 2000);
      
            if (javaFileContent === mfFileContent) {
              dispatch(setActiveModal(EAppModal.COMPARE_SUCCESS));
              updateRowStatus("PASS", true);
            } else {
              dispatch(setActiveModal(EAppModal.COMPARE_FAIL));
              updateRowStatus("FAIL", true);
            }
      
          } catch (error) {
            console.error("Error reading files:", error);
            setLoading(false); 
          }
      
          onClose(); 
        } else {
          message.error("Uploaded record format not Supported in this version.");
          setLoading(false);
        }
      };
      

    const updateRowStatus = async (validated: string, downloadStatus: boolean) => {
        const newFileData = [...data];
        newFileData[index] = {
            ...newFileData[index],
            validated,
            downloadStatus,
        };
        const jobName = newFileData[index].jobName;
        const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/transform-job/validate-status/${projectId}`;
        const requestBody = {
            artifactName : jobName,
            status: validated,
        }
        try {
            await axios.put(url,requestBody);
        } catch (error) {
            console.error('Error posting validation status:', error);
        }
        setData(newFileData);
    };

    const handleUpload = (info, fileType: 'java' | 'mf') => {
        if (info.file.size > 1024 * 1024 * 2) {
            message.error(`${info.file.name} is larger than 1MB! Please select a smaller file.`);
            return;
        }
        if (fileType === 'java') setJavaFile(info.file);
        if (fileType === 'mf') setMfFile(info.file);

        message.success(`${info.file.name} file uploaded successfully.`);
    };

    const checkBothFilesUploaded = () => {
        if (javaFile && mfFile) {
            handleFileUpload(); // Automatically start comparison when both files are uploaded
        }
    };

    return (
        <Modal
            title={
                <div>
                    <div className='text-center font-semibold pb-4'>
                        Upload Files and Compare
                    </div>
                    <hr />
                </div>
            }
            open={isVisible}
            onCancel={onClose}
            footer={null}
            centered
            width={'50%'}
        >
            <Spin spinning={loading}>
                <div className="p-4 flex flex-col gap-6">
                    <p className="text-gray-700">
                        Please select the Java and Mainframe output files from your system to compare.
                        <strong> The file size should be less than 2MB.</strong>
                    </p>
                    <div className="flex items-center justify-between">
                        <label className="font-semibold flex gap-2 items-center"><FaJava size={28} className='text-orange-500'/> Upload Java Output File</label>
                        <div className='flex gap-2 items-center'>
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    handleUpload({ file }, 'java');
                                    return false;
                                }}
                                onChange={checkBothFilesUploaded}
                            >
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                            {javaFile && <p className="text-sm text-green-500">Selected: {javaFile.name}</p>}
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                    <label className="font-semibold flex gap-2 items-center"><TrexIcon height={28} width={28} fill='#1363DF'/> Upload MainFrame Output File</label>
                        <div className='flex gap-2 items-center'>
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    handleUpload({ file }, 'mf');
                                    return false;
                                }}
                                onChange={checkBothFilesUploaded}
                            >
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                            {mfFile && <p className="text-sm text-green-500">Selected: {mfFile.name}</p>}
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default UploadCompareModal;
