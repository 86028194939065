import { useState } from 'react';
import UploadList from '../../../components/upload-list/upload-list';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  setActiveModal,
  setCurrentContent,
  setCurrentIntent,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
} from '../../../redux/app-global/app-global.types';

function UploadArtifact() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );
  const license = useSelector((state: RootState) => state.appGlobal.license);

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth < 768);
  });

  return (
    <div
      className={`container mx-auto p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-[85%]`}
    >
      <h1 className="text-3xl font-semibold mb-4">{currentContent?.heading}</h1>
      <div className="text-justify">
        <div className="space-y-6">
          <div className="space-y-6">
            <p className="text-lg">{currentContent?.description1}</p>
            <ul className="list-disc list-inside flex flex-col gap-2">
              <li className="ml-4">
                <strong>{currentContent?.instructionTitle1}</strong>
                {currentContent?.instruction1}
              </li>
              <li className="ml-4">
                <strong>{currentContent?.instructionTitle2}</strong>
                {currentContent?.instruction2}
              </li>
              <li className="ml-4">
                <strong>{currentContent?.instructionTitle3}</strong>
                {currentContent?.instruction3}
              </li>
              <li className="ml-4">
                <strong>{currentContent?.instructionTitle4}</strong>
                {currentContent?.instruction4}
              </li>
              <li className="ml-4">
                <strong>{currentContent?.instructionTitle5}</strong>
                {currentContent?.instruction5}
              </li>
            </ul>
            <p className="text-lg">{currentContent?.description2}</p>
            {/* <p className="font-semibold mb-6">{currentContent?.description3}</p> */}

            {!license?.Environment.includes('ONPREM') && (
              <p>{currentContent?.description4}</p>
            )}
          </div>
          {/* <div
            style={{ borderWidth: 1 }}
            className="px-4 py-4 rounded-md shadow-sm"
          >
            <h4 className="text-lg font-semibold mb-1">
              Extract Your Mainframe Artifacts
            </h4>
            <p>
              If you don&apos;t have your artifact, you can go through the
              instructions to extract your artifact or you can download our test
              artifact to proceed. These test artifacts are designed to help you
              experience our scanning process without needing your own files.
            </p>
            <div className="mt-6">
              <button
                className=" flex flex-row items-center text-center btn bg-cta hover:shadow-md text-white font-semibold text-sm py-[.15rem] px-4 rounded-full"
                onClick={() => {
                  dispatch(setCurrentIntent(EAppIntent.EXTRACT_ARTIFACT));
                }}
              >
                <div className="text-white text-sm">
                  Extract Your Artifacts{' '}
                </div>
                <div className="text-xl ml-2 text-white">&rarr;</div>
              </button>
            </div>
          </div> */}
          {!license?.Environment.includes('ONPREM') && (
            <div
              style={{ borderWidth: 1 }}
              className="px-4 py-4 rounded-md shadow-sm"
            >
              <h4 className="text-lg font-semibold mb-1">
                Run Assessment On-Premise
              </h4>
              <p>
                Are you unable to upload your artifacts to our application? No
                worries! We&apos;ve got you covered. You can run the assessment
                on-premise, and our team will guide you through the process.
                Schedule a meeting with us to get started.
              </p>
              <div className="mt-6">
                <button
                  className=" flex flex-row items-center text-center btn bg-cta hover:shadow-md text-white font-semibold text-sm py-[.15rem] px-4 rounded-full"
                  onClick={() => {
                    dispatch(setActiveModal(EAppModal.SCHEDULE_CALENDER));
                  }}
                >
                  <div className="text-white text-sm">Schedule Meeting</div>
                  <div className="text-xl ml-2 text-white">&rarr;</div>
                </button>
              </div>
            </div>
          )}
        </div>
        <UploadList />
      </div>
    </div>
  );
}

export default UploadArtifact;
