import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal, setUserDetails } from '../../../redux/app-global';
import axios from 'axios';
import SVGSuccess from '../../../assets/images/svg/success.icon';
import { RootState } from '../../../redux/store';
import useRecaptcha from '../../../hooks/useRecapcha';
import { isCorporateEmailDomain } from '../../../utils/form-validator.utils';
import ReCAPTCHA from '../../recapcha/recapcha';
import { createPortal } from 'react-dom';
import { externalConfig } from '../../../utils/misc.utils';

const FeedbackModal = () => {
  const [name, setName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

  useEffect(() => {
    if (userDetails) {
      setName(userDetails.name);
      setCompanyEmail(userDetails.companyEmail);
    }
  }, [userDetails]);

  const dispatch = useDispatch();

  function validateForm() {
    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name.trim().length === 0) {
      return "Name can't be empty";
    }
    if (companyEmail) {
      if (!EMAIL_REGEX.test(companyEmail)) {
        return 'Invalid email';
      }
      if (!isCorporateEmailDomain(companyEmail)) {
        return 'Invalid email, Please enter your corporate email';
      }
    }
    if (!message) {
      return "Message can't be empty";
    }
    const MIN_WORDS = 10;
    const wordCount = message.trim().split(/\s+/).length;
    if (wordCount < MIN_WORDS) {
      return `Message must contain at least ${MIN_WORDS} words.`;
    }

    return '';
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const error = validateForm();
    if (error) {
      setLoading(false);
      alert(error);
      return;
    }
    try {
      const payload = {
        name,
        email: companyEmail,
        message: message,
        gtoken: capchaToken,
      };
      const result = await axios.post(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/user/feedback`,
        payload,
      );

      if (result.data.recaptchaValid === false) {
        alert('ReCAPTCHA validation failed. Please try again.');
        handleRecaptcha('');
        if (recaptchaRef.current) {
          (recaptchaRef as any).current.reset();
        }
        setLoading(false);
        return;
      }

      if (result.data.status === 'SUCCESS') {
        setName('');
        setCompanyEmail('');
        setShowDialog(false);
        setSubmitted(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  function handleClose() {
    dispatch(setActiveModal(null));
  }

  return createPortal(
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        inset: 0,
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: '10%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          overflow: 'auto',
          maxWidth: '35rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: window.innerHeight * 0.95,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          padding: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {submitted ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem',
              paddingTop: '1.5rem',
            }}
          >
            <div>
              <SVGSuccess />
            </div>
            <div style={{ marginTop: '2rem' }}>
              <p style={{ fontSize: '1.5rem', fontWeight: '600' }}>
                Successfully submitted{' '}
              </p>
            </div>
            <div style={{ marginTop: '.5rem' }}>
              <p style={{ fontSize: '1rem' }}>
                {companyEmail
                  ? 'Thanks for providing the Feedback. Our support team will reach out to you soon.'
                  : 'Thank you for sharing your feedback with us! We appreciate your input and will use it to improve our services.'}
              </p>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4',
                  marginTop: '1rem',
                }}
              >
                <button
                  style={{
                    borderRadius: '0.5rem',
                    border: '0',
                    backgroundColor: 'rgb(19,98,223)',
                    padding: '0.5rem 1rem',
                    fontSize: '1.2rem',
                    color: '#fff',
                    cursor: 'pointer',
                    borderWidth: 0,
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2
              style={{
                fontSize: '1.25rem',
                fontWeight: '500',
                color: '#374151',
              }}
            >
              Provide Feedback
            </h2>
            <button
              style={{
                border: '0',
                color: 'black',
                float: 'right',
                fontSize: '5rem',
                lineHeight: 'none',
                fontWeight: 'medium',
                outline: 'none',
                transition: 'all',
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                right: '1rem',
                background: 'transparent',
                borderWidth: 0,
              }}
              onClick={handleClose}
            >
              <span
                style={{
                  color: 'black',
                  height: '1.5rem',
                  width: '1.5rem',
                  fontSize: '2.5rem',
                  display: 'block',
                  outline: 'none',
                }}
              >
                ×
              </span>
            </button>
            <hr style={{ margin: '.5rem 0 1rem' }} />
            <p
              style={{
                marginBottom: '1rem',
                lineHeight: '1.5',
                color: '#374151',
              }}
            >
              If you had any issues or you liked our product, please share with
              us! Our support team will contact you soon.
            </p>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  lineHeight: '1.5',
                  color: '#374151',
                }}
              >
                <div
                  style={{
                    marginBottom: '.5rem',
                    lineHeight: '1.5',
                    color: '#374151',
                  }}
                >
                  <label
                    htmlFor="email"
                    style={{
                      fontSize: '0.875rem',
                      lineHeight: '1.75',
                      color: '#4a5568',
                      marginBottom: 0,
                    }}
                  >
                    Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    placeholder="Your Name"
                    onChange={e => setName(e.target.value)}
                    style={{
                      width: '100%',
                      borderRadius: '0',
                      border: '1px solid #ccc',
                      backgroundColor: '#fff',
                      padding: '0.5rem 0.75rem',
                      fontSize: '1rem',
                      lineHeight: '1.5',
                      color: '#333',
                      outline: 'none',
                      transition: 'color 0.2s ease-in-out',
                      height: 'unset',
                      fontWeight: '400',
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: '.5rem',
                  }}
                >
                  <label
                    htmlFor="companyEmail"
                    style={{
                      fontSize: '0.875rem',
                      lineHeight: '1.75',
                      color: '#4a5568',
                      marginBottom: 0,
                    }}
                  >
                    Company Email
                  </label>
                  <input
                    type="email"
                    id="companyEmail"
                    placeholder="Company Email"
                    name="companyEmail"
                    value={companyEmail}
                    onChange={e => setCompanyEmail(e.target.value)}
                    style={{
                      width: '100%',
                      borderRadius: '0',
                      border: '1px solid #ccc',
                      backgroundColor: '#fff',
                      padding: '0.5rem 0.75rem',
                      fontSize: '1rem',
                      lineHeight: '1.5',
                      color: '#333',
                      outline: 'none',
                      transition: 'color 0.2s ease-in-out',
                      height: 'unset',
                      fontWeight: '400',
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  marginBottom: '.5rem',
                  marginTop: '.5rem',
                }}
              >
                <label
                  htmlFor="message"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Your Message (Must be at least 10 words)"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  style={{
                    height: '7rem',
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    fontWeight: '400',
                  }}
                ></textarea>
              </div>

              <div
                style={{
                  marginBottom: '1rem',
                  minHeight: '78px',
                }}
              >
                <ReCAPTCHA ref={recaptchaRef} onChange={handleRecaptcha} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  marginTop: '1rem',
                }}
              >
                <button
                  type="submit"
                  disabled={loading || !capchaToken}
                  style={{
                    borderRadius: 4,
                    backgroundColor: 'rgb(19,98,223)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                    fontSize: '1.1rem',
                    color: '#fff',
                    borderWidth: 0,
                    opacity: loading || !capchaToken ? 0.5 : 1,
                  }}
                >
                  Send
                </button>
                <button
                  style={{
                    background: 'transparent',
                    color: 'black',
                    borderWidth: 0,
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </form>
            {showDialog && (
              <p
                style={{
                  marginTop: '0.75rem',
                  fontSize: '0.75rem',
                  color: '#718096',
                }}
              >
                Our support team will contact you soon.
              </p>
            )}
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};

export default FeedbackModal;
