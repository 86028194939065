import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { FaDownload, FaSave, FaSpinner, FaUpload } from 'react-icons/fa';
import {
  commaSeparated,
  downloadExtractJcl,
  externalConfig,
  isValidDataset,
} from '../../../utils/misc.utils';
import axios from 'axios';
import {
  setActiveModal,
  setCurrentIntent,
  setDynamicCallData,
  setIgnoredMissingArtifacts,
  setUploadedMissingArtifacts,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  IUploadedArtifacts,
  TableData,
} from '../../../redux/app-global/app-global.types';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import { PrimaryButton, TooltipHost, Toggle } from '@fluentui/react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import Dropdown from '../../../components/custom-dropdown/custom-dropdown';
import MissingUploadList, {
  UploadMissingListRef,
} from '../../../components/missing-artifact-upload/missing-artifact-upload';
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import { VALID_PROGRAM_FILE_TYPES } from '../missing-program/missing-program-types';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import {
  ExtractJclRequest,
  ExtractType,
} from '../extract-artifact/extract-artifact.model';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { SharedService } from '../../../services/shared-service';
import Swal from 'sweetalert2';
import { MissingPageService } from '../../../services/missing-artifacts.service';
import { useGetMissingDynamicCallsQuery } from '../../../redux/api-slice';
import { startScan } from '../../../components/upload-list/upload-list.service';
import { getSearchParameter } from 'src/utils/url.utils';

interface Artifact {
  uploaded: any;
  variableName: string;
  programName: string;
  value: string;
}

type SelectedValuesState = {
  name: string[];
};

const ResolveDynamic = () => {
  const [tableValue, setTableValue] = useState<Artifact[]>([]);
  const [loading, setLoading] = useState(false);
  const [missingPrograms, setMissingPrograms] = useState<string[]>([]);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const dispatch = useDispatch();
  const pageHelpText = helpTexts[0].RESOLVE_DYNAMIC_CALLS;
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);
  const [missingArtifacts, setMissingArtifacts] =
    useState<IUploadedArtifacts>();
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const [datasetNames, setDatasetNames] = useState<string>('');
  const [errors, setErrors] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
  const options = ['Active', 'Ignore', 'Assembler'];
  const [data, setData] = useState<TableData[]>([]);
  const [headerOption, setHeaderOption] = useState('Active');
  const [alertShown, setAlertShown] = useState({
    missingDynamicCalls: false,
  });
  const [ignoredData, setIgnoredData] = useState([]);
  const projectId = getSearchParameter('projectId');

  const missingDynamicCallsQuery = useGetMissingDynamicCallsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const handleFilesUpdate = updatedFiles => {
    setFiles(updatedFiles);
  };

  useEffect(() => {
    if (
      missingDynamicCallsQuery.isLoading ||
      missingDynamicCallsQuery.isError
    ) {
      return;
    }

    if (missingDynamicCallsQuery.data) {
      const { missingData, ignoredData, programList } =
        missingDynamicCallsQuery.data;

      const transformedData: TableData[] = missingData?.map(
        (item: any, index: number) => ({
          key: index,
          selectedOption: 'Active',
          programName: item.programName,
          requiredBy: item.variableName,
          uploaded: null,
        }),
      );

      const missingLen = transformedData.length;

      ignoredData?.forEach((item: any, index: number) => {
        transformedData.push({
          key: missingLen + index + 1,
          selectedOption: 'Ignore',
          programName: item.programName,
          requiredBy: item.variableName,
          uploaded: null,
        } as TableData);
      });

      setData(transformedData);
      setIgnoredData(ignoredData || []);
      setTableValue([...missingData, ...ignoredData]);
      setMissingPrograms(programList || []);
    }
  }, [missingDynamicCallsQuery]);

  const changeDisplay = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setToggleValue(checked);
  };
  const handleOptionSelects = (variableName: string, option: string) => {
    setTableValue(prevState =>
      prevState.map(artifact =>
        artifact.variableName === variableName
          ? { ...artifact, value: option }
          : artifact,
      ),
    );
    setSelectedValues(prevState => {
      const updatedNames = [...prevState.name, variableName];
      return {
        ...prevState,
        name: updatedNames,
      };
    });
  };

  const handleSubmission = async () => {
    const apiUrl = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/missing-artifact/${projectId}/DYNAMIC_CALL`;

    const formData = new FormData();
    const requestBody: any = [];
    tableValue.forEach((artifact, index) => {
      const status = data[index] ? data[index].selectedOption : 'Active';
      const value = artifact.value
        ? artifact.value
        : data[index]
          ? data[index].uploaded
          : '';

      const item = {
        name: artifact.variableName,
        programName: artifact.programName,
        value: value,
        status: status,
      };
      requestBody.push(item);
    });

    formData.append('missingData', JSON.stringify(requestBody));

    Object.values(files).forEach(file => {
      if (file) {
        formData.append('fileList', (file as any).originalFile);
      }
    });
    try {
      setLoading(true);
      const response = await axios.post(apiUrl, formData);

      if (
        response.data?.status !== 'SUCCESS' ||
        response.data?.message === 'Some artifacts are missing '
      ) {
        Swal.fire({
          title: 'Resolve all missing artifacts',
          text:
            response.data?.message ||
            'Failed to upload missing artifacts. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });

        await missingDynamicCallsQuery.refetch();
        return;
      }

      dispatch(setUploadedMissingArtifacts(missingArtifacts));
      dispatch(setDynamicCallData(data));

      const nextIntent = MissingPageService.getRouteFromAction(
        response.data?.data?.nextAction,
      );

      if (nextIntent === 'REPORT') {
        dispatch(setIgnoredMissingArtifacts(response.data?.data?.ignoredData));
      }

      if (nextIntent === EAppIntent.UPLOADING_ARTIFACT) {
        const scanStarted = await startScan(dispatch);
        if (!scanStarted) {
          return;
        }
      }

      dispatch(setCurrentIntent(nextIntent));
    } catch (err) {
      SharedService.genericAlertModal.title =
        'Upload of Missing Artfacts Failed!';
      SharedService.genericAlertModal.description =
        (err as any)?.message || 'Something went wrong !';

      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
      }, 0);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    function updateFiles() {
      if (uploadMissingListRef.current) {
        const test = uploadMissingListRef.current.getFiles();
        setFiles(test);
      }
    }
    updateFiles();
  }, [uploadMissingListRef.current?.getFiles]);

  const headers: React.ReactNode[] = [
    'Variable Name',
    'Caller Program Name',
    'Callee Program Name',
  ];

  const [selectedValues, setSelectedValues] = useState<SelectedValuesState>({
    name: [],
  });
  const renderRowFields = (artifact: Artifact) => {
    const variableName = artifact.variableName;
    return [
      artifact.variableName,
      artifact.programName,
      <Dropdown
        key={variableName}
        options={missingPrograms}
        selectedOption={artifact.value || 'Select'}
        onOptionSelect={(option: string) =>
          handleOptionSelects(variableName, option)
        }
      />,
    ];
  };

  const uploadHeaders: React.ReactNode[] = [
    <Dropdown
      options={options}
      selectedOption={headerOption}
      onOptionSelect={option => handleHeaderDropdownChange(option)}
      key="1"
    />,
    'Variable Name',
    'Caller Program Name',
    !data.every(item => item.uploaded) && 'Upload',
  ];
  const handleHeaderDropdownChange = newOption => {
    // const newOption = e.target.value;
    setHeaderOption(newOption);
    setData(
      data.map(row => ({
        ...row,
        selectedOption: newOption,
      })),
    );
  };
  const handleOptionSelect = (option, key) => {
    const updatedData = data.map(item => 
      item.key === key ? { ...item, selectedOption: option } : item
    );
    setData(updatedData);
  };
  const triggerFileUpload = (event, itemKey) => {
    if (uploadMissingListRef.current) {
      uploadMissingListRef.current.handleFileUpload(event, itemKey);
    }
  };

  const rowFields = item => {
    const isDisabled =
      selectedValues.name.includes(item.requiredBy) ||
      item.selectedOption === 'Ignore' ||
      item.selectedOption === 'Assembler' ||
      selectedRows[item.key];
    return [
      <Dropdown
        options={options}
        selectedOption={item.selectedOption}
        onOptionSelect={option => handleOptionSelect(option, item.key)}
        key={`dropdown-${item.key}`}
      />,
      <div
        className={`text-sm ${isDisabled ? 'text-gray-400' : 'text-gray-900'}`}
        key={`requiredBy-${item.key}`}
      >
        {item.requiredBy}
      </div>,
      <div
        className={`text-sm ${isDisabled ? 'text-gray-400' : 'text-gray-900'}`}
        key={`programName-${item.key}`}
      >
        {item.programName}
      </div>,
      selectedRows[item.key] ? (
        item.uploaded ? (
          <MissingUploadBtn
            handleFileUpload={triggerFileUpload}
            item={item}
            buttonStyle={{ cursor: 'not-allowed', backgroundColor: '#f0f0f0' }}
          />
        ) : (
          ''
        )
      ) : (
        !item.uploaded &&
        !toggleValue && (
          <MissingUploadBtn
            handleFileUpload={triggerFileUpload}
            item={item}
            accept={`${VALID_PROGRAM_FILE_TYPES.map(type => '.' + type).join(',')}`}
            disabled={isDisabled}
          />
        )
      ),
    ];
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setErrors(validationError);
      setSuccess(null);
    } else {
      setErrors(null);
      setSuccess(null);
    }
    setDatasetNames(value);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const validationError = commaSeparated(datasetNames.trim());
    console.log(validationError);

    if (validationError) {
      setErrors(validationError);
      setSuccess(null);
      return;
    }

    if (!datasetNames.trim()) {
      setErrors('Dataset name is required.');
      setSuccess(null);
      return;
    }
    const datasetArray = datasetNames.split(',').map(name => name.trim());

    const data: ExtractJclRequest = {
      dataset: {
        listing: datasetArray,
      },
      downloadFor: 'LISTING' as ExtractType,
    };
    console.log(data);

    const isDownloadSuccessful = await downloadExtractJcl(data);

    if (isDownloadSuccessful) {
      setSuccess('Data successfully posted and file downloaded!');
      setErrors(null);
      setDatasetNames('');
    } else {
      setErrors('Failed to post data or download the file. Please try again.');
      setSuccess(null);
    }
  };

  if (missingDynamicCallsQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (missingDynamicCallsQuery.isError && !alertShown.missingDynamicCalls) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to fetch Missing Dynamic Call details. Please try again later.',
      footer: 'Please contact support',
      showCancelButton: true,
      confirmButtonText: 'Retry',
      cancelButtonText: 'Go Back',
    }).then(result => {
      if (result.isConfirmed) {
        missingDynamicCallsQuery.refetch();
        setAlertShown({ missingDynamicCalls: false });
      } else {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      }
    });
    setAlertShown({ missingDynamicCalls: true });
    return null;
  }

  return (
    <div
      className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-[95%]`}
    >
      <div className="p-2 px-8 mb-4">
        <StepperComponent />
      </div>
      <div className="mb-2 border rounded-lg px-8 py-8 shadow max-w-full">
        <h1 className="text-3xl font-semibold mb-4">
          Missing Artifacts Workspace
        </h1>
        <hr />
        <div className="flex flex-col gap-4">
          <div className="text-left mt-4 gap-4 text-l font-semibold">
            Resolve Dynamic Calls
          </div>
          <div>
          Use this workspace to resolve missing dynamic calls in your application. You can select existing programs, upload the required programs, or extract them from the mainframe. The dropdown in the Upload section (Active, Ignore, Assembler) gives you control over how to handle each dynamic variable, ensuring flexibility in resolving the missing artifacts. Ensure all dynamic variables are correctly mapped to their corresponding programs before proceeding. 
          </div>
          <div className="flex items-center gap-2">
            <HelpTextCallout
              calloutText={pageHelpText.programTableData[4].description}
            />
            <span className="text-lg">Upload Programs</span>
            <Toggle className="mt-2" onChange={changeDisplay} />
            <span className="text-lg">Extract Programs from Mainframe</span>
          </div>
          <div>
          {!toggleValue && (
            <>
          <div className="font-semibold">Option 1: Map Dynamic Calls Using Existing Programs</div>
          <p className="mt-4">Use this section to resolve dynamic variables by selecting existing programs from the dropdown.</p>
          {/* <hr /> */}
          </>
          )}
          </div>
          {toggleValue && (
            <div className="flex flex-col">
              <h3>Provide dataset names below (comma-separated), and click "Generate Extraction JCL" for the system to generate the appropriate extraction JCL:</h3>
              <input
                type="text"
                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                value={datasetNames}
                onChange={handleChange}
                placeholder="Enter dataset names..."
              />
              {errors && (
                <TooltipHost content={errors} id="error-tooltip">
                  <div className="mt-2 text-red-500">{errors}</div>
                </TooltipHost>
              )}
              {success && (
                <TooltipHost content={success} id="success-tooltip">
                  <div className="mt-2 text-green-500">{success}</div>
                </TooltipHost>
              )}
              <div>
                {toggleValue &&
                  ((Array.isArray(data) &&
                    data.length > 0 &&
                    data.every(
                      item =>
                        item.selectedOption !== 'Active' ||
                        item.uploaded !== null,
                    )) ||
                  Object.values(files).length > 0 ||
                  toggleValue ||
                  true ? (
                    <div className="flex flex-row items-center mt-6">
                      <HelpTextCallout
                        calloutText={
                          pageHelpText.programTableData[6].description
                        }
                      />
                      <PrimaryButton
                        text={'Generate Extraction JCL'}
                        className="rounded-md"
                        styles={{ root: { backgroundColor: '#1363DF' } }}
                        onClick={handleSubmit}
                      />
                    </div>
                  ) : null)}
              </div>
            </div>
          )}

          {/* <div className="flex justify-end items-center gap-4 p-6">
                        <CSVLink
                            data={csvData}
                            filename={"resolve-dynamic.csv"}
                            className="mt-8 rounded-md text-white font-semibold py-2 px-4 shadow-lg transition duration-300"
                            style={{ backgroundColor: '#1363DF' }}
                        >
                            Download CSV Template
                        </CSVLink>

                        <label
                            htmlFor="csv-upload"
                            className="mt-8 rounded-md text-white font-semibold py-2 px-4 shadow-lg transition duration-300"
                            style={{ backgroundColor: '#1363DF' }}
                        >
                            Upload CSV
                        </label>
                        <input
                            id="csv-upload"
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="hidden"
                        />
                    </div> */}
          {!toggleValue ? (
            <>
              {tableValue.length > 0 ? (
                <ReusableTable
                  headers={headers}
                  items={tableValue}
                  rowFields={renderRowFields}
                  helpTexts={pageHelpText.dynamicResolveTable}
                />
              ) : (
                <>
                  <ReusableTable
                    headers={headers}
                    items={tableValue}
                    rowFields={renderRowFields}
                    helpTexts={pageHelpText.dynamicResolveTable}
                  />
                  <p className="text-gray-600">No data available</p>
                </>
              )}

              <div>
                <h2 className="flex items-center justify-center font-bold mb-4">
                  <span className="flex-grow border-t border-gray-400"></span>
                  <span className="px-4">OR</span>
                  <span className="flex-grow border-t border-gray-400"></span>
                </h2>
                <div className="font-semibold">Option 2: Upload Individual Programs to Resolve Dynamic Calls </div>
                <p className="mt-4 my-4">Use this table to upload the missing program files for each dynamic call individually. Select the appropriate action (Active, Ignore, Assembler) and upload as needed.</p>
                <ReusableTable
                  items={data}
                  headers={uploadHeaders}
                  rowFields={rowFields}
                  helpTexts={pageHelpText.programTableData}
                />
                {!toggleValue ? (
                  <MissingUploadList
                    ref={uploadMissingListRef}
                    setData={setData}
                    setMissingArtifacts={setMissingArtifacts as any}
                    type="Dynamic Call"
                    data={data}
                    onFilesUpdate={handleFilesUpdate}
                    pageHelpText={pageHelpText.programTableData}
                  />
                ) : null}
              </div>
              {!toggleValue ? (
                <div className="flex">
                  <PrimaryButton
                    onClick={handleSubmission}
                    text={'Save and Continue'}
                    className="mt-8 rounded-md"
                    styles={{ root: { backgroundColor: '#1363DF' } }}
                    disabled={loading}
                  />
                </div>
              ) : null}
              {loading && (
                <div className="flex gap-2 mt-4">
                  <FaSpinner className="animate-spin" />
                  <p>Updating Missing data ....</p>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ResolveDynamic;
