import { useLocation, useNavigate } from 'react-router-dom';
import UserOnly from 'src/components/user-only/user-only';
import { useGetProjectDetailQuery, useGetTotalLicenseQuery } from 'src/redux/api-slice';

const ProjectStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('projectId') || ''; // || 'bd8dfb56-e06e-47d0-b37a-49a536e3c271';
  const { data : projects } = useGetProjectDetailQuery({ projectId });
  const {
    data: totalLicense,
    isLoading,
    error,
  } = useGetTotalLicenseQuery({ projectId });

  if (error) {
    return (
      <p className="text-red-500">
        Failed to load data. Please try again later.
      </p>
    );
  }

  const currentUsage = totalLicense?.items
    ?.filter(item => item?.projectId !== null)
    .reduce((total, item) => {
      const linesUsed = projects?.projectType?.toLowerCase() === 'smart_doc' ? item?.smartDocsLinesUsed || 0 : item?.linesUsed || 0;
      return total + linesUsed;
    }, 0) || 0;

  const totalLimit = projects?.projectType?.toLowerCase() === 'smart_doc' ? totalLicense?.totalSmartDocLimit || 0 : totalLicense?.totalLimit || 0;


  const usagePercentage = Math.min((currentUsage / totalLimit) * 100);

  return (
    <div className="mt-8 flex justify-start items-center bg-blue-800 shadow-lg rounded-md p-6 border border-blue-700">
      {isLoading ? (
        <p className="text-white">Loading data...</p>
      ) : totalLicense?.status === 'ERROR' ? (
        <p className="text-white">{totalLicense?.message}</p>
      ) : (
        <>
          <div className="w-[40%]">
            <p className="text-l text-white mb-4">
              <strong>Lines Used</strong>
            </p>
            <div className="h-2 w-full md:w-[70%] bg-gray-300 rounded-full mt-6">
              <div
                className="h-2 bg-black rounded-full"
                style={{ width: `${usagePercentage}%` }}
              />
            </div>

            <p className="text-l text-white text-center mb-2 mt-4 ml-[35%]">
              <strong>{usagePercentage.toFixed(3)}%</strong>
            </p>
          </div>

          <div className="flex-1 text-left text-white space-y-4 mt-2">
            <p className="text-l text-white">
              <strong>Total Limit:</strong> {totalLimit} Lines
            </p>
            <p className="text-l text-white">
              <strong>Current Usage:</strong> {currentUsage} Lines
            </p>
          </div>

          <div className="justify-self-end ml-auto">
            {/* <UserOnly>
              <button className="bg-white text-blue-800 py-4 px-6 rounded-lg font-semibold text-md">
                Notify Admin
              </button>
            </UserOnly> */}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectStatus;
