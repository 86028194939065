import { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Badge } from 'src/components/ui/badge';
import { FiEdit } from 'react-icons/fi';
import { useGetUsersQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import { userStatusToColorMap } from './users.service';
import { ICreateNewUserFormData } from './create-new-user/create-new-user.types';
import CreateNewUserModal from './create-new-user';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { Search } from 'lucide-react';
import { Input } from 'src/components/ui/input';
import { useNavigate, useNavigation } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { useDispatch } from 'react-redux';

const UsersPage = () => {
  const [alertsShown, setAlertsShown] = useState({ users: false });
  const [showCreateNewUser, setShowCreateNewUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleFilter, setRoleFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const { isLoading, error, data: users, refetch } = useGetUsersQuery();

  const navigate = useNavigate();

  const filteredUsers = useMemo(() => {
    let filteredItems = users;

    if (!filteredItems?.length) {
      return [];
    }

    if (searchQuery) {
      filteredItems = filteredItems?.filter(user => {
        if (
          user.name
            .toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase()) ||
          user.email
            .toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        ) {
          return true;
        }
        return false;
      });
    }

    filteredItems = filteredItems?.filter(user => {
      if (roleFilter === 'All') return true;
      return user.role === roleFilter.toUpperCase();
    });

    return filteredItems;
  }, [users, roleFilter, searchQuery]);

  const stats = [
    { label: 'Total users', value: users?.length || 0 },
    {
      label: 'Active users',
      value: users?.filter(user => user.active)?.length || 0,
    },
    {
      label: 'Administrators',
      value: users?.filter(user => user.role === 'ADMIN')?.length || 0,
    },
    {
      label: 'Members',
      value: users?.filter(user => user.role === 'MEMBER')?.length || 0,
    },
  ];

  if (error && !alertsShown.users) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load users. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, users: true });
  }

  const handleCreateUser = (userDetails: ICreateNewUserFormData) => {
    setShowCreateNewUser(false);
  };

  const handleViewUser = (userId: string) => {
    navigate(`${ROUTES.USER_PROFILE}?id=${userId}`);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setShowCreateNewUser(false);
  };

  const handleFilterChange = filter => {
    setRoleFilter(filter);
  };

  return (
    <div className="flex flex-row bg-[#F3F4F4] min-h-[100vh]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'Users'} />
      </div>
      <div className="mt-8 ml-8 w-full">
        <div className="w-full">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="mt-[0rem] flex flex-col">
              <div
                className="p-8 rounded-md bg-white mr-6 mb-4 -mt-3"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-2xl font-bold">User Management</h1>
                    <p className="mt-2">
                      Easily view, manage, and edit user accounts.
                    </p>
                  </div>
                  <div className="ml-auto">
                    <button
                      onClick={() => {
                        setShowCreateNewUser(true);
                      }}
                      className="mb-4 bg-green-600 text-white hover:bg-green-700 px-4 py-2 rounded-md shadow-md transition duration-150 transform hover:scale-105"
                    >
                      Create New User
                    </button>
                  </div>
                </div>

                <div className="grid gap-4 md:grid-cols-4 my-4">
                  {stats.map(stat => (
                    <Card
                      key={stat.label}
                      className="transition-transform transform hover:scale-101 hover:shadow-xl rounded-lg bg-gradient-to-r from-blue-500 to-blue-300 p-3"
                    >
                      {' '}
                      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1">
                        {' '}
                        <CardTitle className="text-base font-medium text-white">
                          {' '}
                          {stat.label}
                        </CardTitle>
                      </CardHeader>
                      <CardContent className="p-3 ml-3">
                        {' '}
                        <div className="text-3xl font-bold text-white">
                          {' '}
                          {stat.value}
                        </div>
                        <div className="mt-1 text-xs text-gray-200"></div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>

              <div className="p-8 rounded-md bg-white mr-6 min-h-[30rem] shadow-lg">
                <div className="flex justify-between items-center my-4 mb-6">
                  <div className="flex items-center gap-4 -mt-4">
                    <div className="relative">
                      <Search className="absolute left-2.5 top-3.5 h-4 w-4 text-muted-foreground" />
                      <Input
                        value={searchQuery}
                        onChange={event => setSearchQuery(event.target.value)}
                        placeholder="Search by name or email"
                        className="pl-8 w-[20rem] h-11 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>

                    <div className="flex space-x-2 ml-4">
                      <button
                        className={`py-2 px-4 rounded-lg transition duration-200 ${
                          roleFilter === 'All'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        }`}
                        onClick={() => handleFilterChange('All')}
                      >
                        All
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg transition duration-200 ${
                          roleFilter === 'Member'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        }`}
                        onClick={() => handleFilterChange('Member')}
                      >
                        Member
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg transition duration-200 ${
                          roleFilter === 'Admin'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        }`}
                        onClick={() => handleFilterChange('Admin')}
                      >
                        Admin
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <Table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
                    <TableCaption>A list of users.</TableCaption>
                    <TableHeader>
                      <TableRow className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                        <TableHead className="py-3 px-6 text-left">
                          ID
                        </TableHead>
                        <TableHead className="py-3 px-6 text-left">
                          Name
                        </TableHead>
                        <TableHead className="py-3 px-6 text-left">
                          Email
                        </TableHead>
                        <TableHead className="py-3 px-6 text-left">
                          Role
                        </TableHead>
                        <TableHead className="py-3 px-6 text-left">
                          Status
                        </TableHead>
                        <TableHead className="py-3 px-6 text-center">
                          Actions
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredUsers?.length === 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className="text-center font-medium py-4"
                          >
                            No users found
                          </TableCell>
                        </TableRow>
                      )}
                      {filteredUsers?.map(user => (
                        <TableRow
                          key={user.id}
                          className="hover:bg-gray-50 transition duration-200"
                        >
                          <TableCell className="font-medium py-4 px-6 border-b border-gray-200">
                            {user.id}
                          </TableCell>
                          <TableCell className="py-4 px-6 border-b border-gray-200">
                            {user.name}
                          </TableCell>
                          <TableCell className="py-4 px-6 border-b border-gray-200">
                            {user.email}
                          </TableCell>
                          <TableCell className="py-4 px-6 border-b border-gray-200">
                            {user.role.charAt(0).toUpperCase() +
                              user.role.slice(1).toLowerCase()}
                          </TableCell>
                          <TableCell className="py-4 px-6 border-b border-gray-200">
                            <Badge
                              style={{
                                background: user.active
                                  ? userStatusToColorMap['ACTIVE']?.background
                                  : userStatusToColorMap['INACTIVE']
                                      ?.background,
                                color: user.active
                                  ? userStatusToColorMap['ACTIVE']?.color
                                  : userStatusToColorMap['INACTIVE']?.color,
                              }}
                              variant="default"
                            >
                              {user.active ? 'ACTIVE' : 'INACTIVE'}
                            </Badge>
                          </TableCell>
                          <TableCell className="text-center py-4 px-6 border-b border-gray-200">
                            <div className="flex justify-center space-x-6">
                              <button
                                onClick={() => handleViewUser(user.id + '')}
                                className={`flex items-center px-4 py-2 rounded-md shadow-md transition duration-150 transform hover:scale-105 bg-blue-600 text-white hover:bg-blue-700`}
                              >
                                <FiEdit size={20} className="mr-1" />{' '}
                                <span className="text-xs font-medium">
                                  View
                                </span>
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showCreateNewUser && (
        <CreateNewUserModal
          onSubmit={handleCreateUser}
          onClose={handleCloseModal}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default UsersPage;
