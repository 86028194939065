import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import MobileNotSupported from './components/mobile-not-supported';
import './index.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { fetchUiConfig } from './services/global-service';
import { externalConfig } from './utils/misc.utils';
import { initSentry } from './utils/sentry.util';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const determineBackendUrls = (data: any) => {
  const isHttps = window.location.protocol === 'https:';
  const baseUrl = `${window.location.protocol}//${window.location.hostname}`;

  if (isHttps) {
    const { hostname } = new URL(window.location.origin);
    const isLocalhostOrIp =
      /^[0-9.]+$/.test(hostname) || hostname === 'localhost';
    const gatewayPort =
      data.REACT_APP_GATEWAY_PORT || process.env.REACT_APP_GATEWAY_PORT;
    const aiPort = data.REACT_APP_AI_PORT || process.env.REACT_APP_AI_PORT;

    if (isLocalhostOrIp) {
      externalConfig.REACT_APP_GATEWAY_URL = `${baseUrl}:${gatewayPort}`;
      externalConfig.REACT_APP_WS_URL = `${baseUrl}:${gatewayPort}`;
    } else {
      externalConfig.REACT_APP_GATEWAY_URL = window.location.origin;
      externalConfig.REACT_APP_WS_URL = window.location.origin;
    }
  } else {
    const gatewayPort =
      data.REACT_APP_GATEWAY_PORT || process.env.REACT_APP_GATEWAY_PORT;

    externalConfig.REACT_APP_GATEWAY_URL = `${data.REACT_APP_GATEWAY_URL || process.env.REACT_APP_GATEWAY_URL || baseUrl}:${gatewayPort}`;
    externalConfig.REACT_APP_WS_URL = `${data.REACT_APP_GATEWAY_URL || process.env.REACT_APP_GATEWAY_URL || baseUrl}:${gatewayPort}`;
  }
};

fetchUiConfig()
  .then(data => {
    determineBackendUrls(data);

    initSentry(
      externalConfig.REACT_APP_API_URL,
      externalConfig.REACT_APP_WS_URL,
    );
  })
  .catch(error => {
    console.error('Failed to fetch UI config:', error);
  })
  .finally(() => {
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement,
    );
    root.render(
      <React.StrictMode>
        {isMobile && !isTablet ? (
          <MobileNotSupported />
        ) : (
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        )}
      </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
