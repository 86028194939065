import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const getProjectLead = (data: any, projectData?: any) => {
  return data?.projectMemberList?.find(
    (member: any) => member.memberType === 'LEAD',
  ) || projectData?.projectMemberList?.find(
    (member: any) => member.memberType === 'LEAD',
  );
};

export const fetchTransformationStatus = async (projectId: string) => {
  const response = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/report/transform-data/${projectId}`,
  );

  return response.data?.data || [];
};
