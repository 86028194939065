import React, { useState, useEffect } from 'react';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { Switch } from 'src/components/ui/switch';
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
    SelectValue,
} from 'src/components/ui/select';
import { helpTexts } from 'src/helpTexts';
import { useGetGlobalProjectAdminSettingsQuery, useGetTransformationGlobalProjectAdminSettingsQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';
import { useUser } from 'src/hooks/useUser';

const TransformationSettings = () => {
    const { data, error, isLoading, refetch } = useGetTransformationGlobalProjectAdminSettingsQuery();
    const transformationSettings = data?.find(item => item.type === "TRANSFORMATION")?.settings || {};
    const [settings, setSettings] = useState(transformationSettings || {});
    const transformationId = data?.find(item => item.type === "TRANSFORMATION")?.id || {};

    useEffect(() => {
        if (transformationSettings && Object.keys(transformationSettings)?.length > 0) {
            setSettings(transformationSettings);
        }
    }, [transformationSettings]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Unable to load global Admin Application Settings. Please try again later.',
            });
        }
    }, [error]);

    console.log(transformationSettings)

    const handleUpdateOverrideSettings = async () => {
        try {
            const filteredSettings = {
                abendCode: settings?.abendCode,
                packageNameSpace1: settings?.packageNameSpace1,
                addCobolLines: settings?.addCobolLines,
                compileJava: settings?.compileJava,
                statelessPojos: settings?.statelessPojos,
                generateControlAdvice: settings?.generateControlAdvice,
            };

            console.log(transformationSettings)

            const response = await axios.put(
                `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project-settings`,
                {
                    settings: filteredSettings,
                    id: transformationId,
                }
            );
            if (response?.data?.status === 'SUCCESS') {
                Swal.fire({
                    icon: 'success',
                    title: 'Global Application Settings Updated',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
                refetch();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Global Application Settings Error',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
            }
        } catch (error) {
            console.error("Error updating override settings", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again later.',
                confirmButtonText: 'OK',
            });
        }
    };

    const updateSetting = (updates) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            ...updates,
        }));
    };

    const handleInputChange = (field, value) => {
        updateSetting({ [field]: value });
    };

    const handleToggle = (fieldName) => {
        const currentValue = settings[fieldName];
        const newValue = currentValue === 'true' ? 'false' : 'true';
        updateSetting({ [fieldName]: newValue });
    };

    const FieldWrapper = ({ label, helpText, children }) => (
        <div className="flex flex-col">
            <label className="block text-md font-medium mb-2 flex items-center">
                {label}
                <HelpTextCallout calloutText={helpText} />
            </label>
            {children}
        </div>
    );

    const SelectField = ({ label, value, onChange, helpText }) => (
        <FieldWrapper label={label} helpText={helpText}>
            <Select onValueChange={onChange} value={value}>
                <SelectTrigger>
                    <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="true">Yes</SelectItem>
                    <SelectItem value="false">No</SelectItem>
                </SelectContent>
            </Select>
        </FieldWrapper>
    );

    const ToggleField = ({ label, isChecked, onChange, helpText }) => (
        <FieldWrapper label={label} helpText={helpText}>
            <div className="flex items-center">
                <span className={`mr-3 ${isChecked === 'true' ? "font-bold" : "font-medium"}`}>Yes</span>
                <Switch
                    checked={isChecked === 'true'}
                    onCheckedChange={() => onChange()}
                    className="mx-2"
                />
                <span className={`ml-3 ${isChecked === 'false' ? "font-bold" : "font-medium"}`}>No</span>
            </div>
        </FieldWrapper>
    );

    const pagHelpTexts = helpTexts[0].PROJECT_TRANSFORM_SETTINGS || {};
    const noProjectSettings = settings && Object.keys(settings).length === 0;

    const user = useUser();

    return (
        <div className="p-1 bg-gray-100 flex justify-left items-left">
            <div className="bg-white shadow-2xl rounded-lg p-8 w-full max-w-4xl">
                {isLoading ? (
                    <div>Loading...</div>
                ) : noProjectSettings ? (
                    <div className="text-center text-lg text-gray-500">
                        No Global Admin Application Settings
                    </div>
                ) : (
                    <div className='space-y-6'>
                    <div className="grid grid-cols-2 gap-6">
                        <div className="flex flex-col">
                            <label className="block text-md font-medium mb-2 flex items-center">
                                Abend Code
                                <HelpTextCallout calloutText={pagHelpTexts[0].description} />
                            </label>
                            <input
                                type="text"
                                value={settings.abendCode}
                                onChange={e => handleInputChange('abendCode', e.target.value)}
                                className="w-full p-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        <div className="flex flex-col col-span-w/2">
                            <label className="block text-md font-medium mb-2 flex items-center">
                                Package Namespace
                                <HelpTextCallout calloutText={pagHelpTexts[1].description} />
                            </label>
                            <div className="flex space-x-2">
                                <input
                                    type="text"
                                    value={settings.packageNameSpace1}
                                    onChange={e => handleInputChange('packageNameSpace1', e.target.value)}
                                    className="w-1/2 p-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                />
                                <input
                                    type="text"
                                    value={settings.packageNameSpace2}
                                    onChange={e => handleInputChange('packageNameSpace2', e.target.value)}
                                    className={`w-1/2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${user?.role.toLowerCase() === 'admin' ? 'bg-gray-200' : 'bg-white'}`}
                                    disabled={user?.role.toLowerCase() === 'admin'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                        <SelectField
                            label="Add COBOL Lines"
                            value={settings.addCobolLines}
                            onChange={value => handleInputChange('addCobolLines', value)}
                            helpText={pagHelpTexts[4].description}
                        />
                        <SelectField
                            label="Compile Java"
                            value={settings.compileJava}
                            onChange={value => handleInputChange('compileJava', value)}
                            helpText={pagHelpTexts[2].description}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                        <ToggleField
                            label="Stateless POJOs"
                            isChecked={settings.statelessPojos}
                            onChange={() => handleToggle('statelessPojos')}
                            helpText={pagHelpTexts[5].description}
                        />
                        <ToggleField
                            label="Generate Control Advice"
                            isChecked={settings.generateControlAdvice}
                            onChange={() => handleToggle('generateControlAdvice')}
                            helpText={pagHelpTexts[3].description}
                        />
                    </div>
                </div>
                )}
                {!isLoading && !noProjectSettings && (
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleUpdateOverrideSettings}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                        >
                            Save Transformation Settings
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransformationSettings;
