import { useState } from 'react';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import { useGetAppVersionQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { Badge } from '../../../components/ui/badge';
import { Activity, CheckCircle2 } from 'lucide-react';
import VersionTemplate from './version.template';

const VersionPage = () => {
  const [alertsShown, setAlertsShown] = useState({ version: false });
  const {
    isLoading,
    error,
    data: version,
  } = useGetAppVersionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  if (error && !alertsShown.version) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load app version. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, version: true });
  }

  function getStatusBadge(status: string) {
    switch (status) {
      case 'healthy':
        return (
          <Badge variant="default" className="bg-green-500 hover:bg-green-600">
            <CheckCircle2 className="w-3 h-3 mr-1" />
            Healthy
          </Badge>
        );
      case 'offline':
        return (
          <Badge variant="default" className="bg-red-500 hover:bg-red-600">
            <Activity className="w-3 h-3 mr-1" />
            Offline
          </Badge>
        );
      default:
        return (
          <Badge variant="default" className="bg-green-500 hover:bg-green-600">
            <CheckCircle2 className="w-3 h-3 mr-1" />
            Healthy
          </Badge>
        );
    }
  }

  return (
    <div className="flex flex-row bg-[#F3F4F4]">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'Version'} />
      </div>
      <div className="min-h-[calc(100vh-170px)] flex-grow w-full flex items-center justify-center bg-gray-100">
        <div className="bg-white max-w-[1300px] max-h-[500px] overflow-hidden">
          <VersionTemplate
            getStatusBadge={getStatusBadge}
            services={version?.filter(item => item.name && item.version) || []}
          />
        </div>
      </div>
    </div>
  );
};

export default VersionPage;
