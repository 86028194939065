import axios from 'axios';
import {
  downloadBase64FromResponse,
  externalConfig,
} from 'src/utils/misc.utils';
import Swal from 'sweetalert2';
import { getLicenseData } from '../admin/license/license.service';

export enum EProjectStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
}

export interface IProjectApiObject {
  projectId: number;
  projectName: string;
  projectType: string;
  projectTypeDisplayValue: string;
  projectStatus: EProjectStatus;
  startDate: string;
  endDate: string;
  filterId?: string;
  currentStage: string;
  teamMemberCount: number;
  completePercentage: number;
  projectMemberList: null | any[];
  stage: string[];
  description: string[];
  dependencyList: null | any[];
}

export const downloadProjectReport = async (
  projectId: string,
  projectName: string,
) => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/report/download/${projectId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (res.data?.data) {
      downloadBase64FromResponse(res, `${projectName}_report.zip`);
      return res.data;
    } else {
      Swal.fire({
        icon: 'info',
        title: 'No Report Available',
        text: res.data?.message,
      });
      return res.data?.message;
    }
  } catch (err) {
    console.log('report download', err);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: `Unable to download the application report for application ${projectName}! Please try again later.`,
    });
  }
};

export const getProjectDetails = async (): Promise<IProjectApiObject[]> => {
  const licenseResponse = await getLicenseData();
  try {
    // const res = await axios.get(`http://localhost:3001/projects`);
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project`,
    );
    return (
      res.data.data?.projectList?.map((project: IProjectApiObject) => {
        return {
          ...project,
          description: [
            `Stage Name - ${project.currentStage || 'DEFAULT'}`,
            `License Type - ${licenseResponse?.LicenseType || ''}`,
            `${project.teamMemberCount} Members`,
          ],
        };
      }) || []
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getProjectInvites = async () => {
  try {
    return [
      {
        id: 1,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Name - Current Stage Name', '3 Members'],
      },
      {
        id: 2,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Name - Current Stage Name', '3 Members'],
      },
      {
        id: 3,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Name - Current Stage Name', '3 Members'],
      },
      {
        id: 4,
        type: 'Smart Doc',
        dueDate: '2021-12-09',
        startDate: '2021-08-03',
        progress: 40,
        title: 'Project Name',
        subTitle: 'SMART DOC',
        status: 'ACTIVE',
        description: ['Stage Name - Current Stage Name', '3 Members'],
      },
    ];
  } catch (err) {
    console.log('Something went wrong', err);
  }
};

export const getProjectDependencies = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/dependent-project-list`,
    );
    if (Array.isArray(response.data?.data)) {
      return response.data.data.filter(
        project => project.dependencyList && project.dependencyList.length > 0,
      );
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
