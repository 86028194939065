import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { IProjectArtifactsProps } from './project-artifacts.types';

const ProjectArtifacts = ({ rows }: IProjectArtifactsProps) => {

  const formatToTitleCase = (text: string) =>
    text
      .split(/([_,\s]+)/)
      .map(word => 
        word && !/[_ ,]+/.test(word)
          ? word[0].toUpperCase() + word.slice(1).toLowerCase() 
          : word.replace('_', ' ')
      )
      .join('');
  

  return (
    <div>
      <h1 className="text-2xl font-bold mb-2">Associated Artifacts </h1>
      <p className="text-lg text-gray-600 max-w-[80%]">
        View and manage the files you&apos;ve uploaded for this application. Ensure
        all necessary artifacts are in place to smoothly transition your COBOL
        application to Java.
      </p>
      <div className="mt-8">
        <Table>
          <TableCaption>A list of your application artifacts.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>Artifact Name</TableHead>
              <TableHead>Artifact Type</TableHead>
              <TableHead>Artifact State</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Modified Date</TableHead>
              <TableHead>Created By</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map((project: any) => (
              <TableRow key={project.id}>
                <TableCell className="w-[15rem]">
                  {project?.artifactName ? formatToTitleCase(project?.artifactName) : 'NA'}
                </TableCell>
                <TableCell className="min-w-[5rem]">
                  {project?.artifactType ? formatToTitleCase(project?.artifactType) : 'NA'}
                </TableCell>
                <TableCell className="w-[15rem]">
                  {project?.artifactState ? formatToTitleCase(project?.artifactState) : 'NA'}
                </TableCell>
                 <TableCell className="w-[15rem]">
                  {project?.tokenProcessorStatus ? formatToTitleCase(project?.tokenProcessorStatus) : 'NA'}
                </TableCell>
                <TableCell>{project?.modifiedDate || 'NA'}</TableCell>
                <TableCell>{project?.createdBy ? formatToTitleCase(project?.createdBy) : 'NA'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ProjectArtifacts;
