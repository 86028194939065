import axios from "axios";
import { externalConfig } from "src/utils/misc.utils";

export const fetchDeployStatus = async (projectId: string) => {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-test-service/deploy/status/${projectId}`,
    );
  
    return response.data.data;
};

export const fetchRepositories = async () => {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-test-service/deploy/repositories`,
    );
  
    return response.data;
};