import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal, setUserDetails } from '../../../redux/app-global';
import axios from 'axios';
import SVGSuccess from '../../../assets/images/svg/success.icon';
import { RootState } from '../../../redux/store';
import { externalConfig, isValidInternationalPhoneNumber } from '../../../utils/misc.utils';
import { useChatBotUtils } from '../../../hooks/useChatBotUtils';
import { isCorporateEmailDomain } from '../../../utils/form-validator.utils';
import { AsYouType } from 'libphonenumber-js';
import useRecaptcha from '../../../hooks/useRecapcha';
import ReCAPTCHA from '../../recapcha/recapcha';
import { createPortal } from 'react-dom';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { triggerOtpForEmail } from '../../assisted-service/assisted-service.service';
import VerifyEmail from '../../verify-email/verify-email';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const LeadInformation = () => {
  const [name, setName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [contactType, setContactType] = useState<'email' | 'meeting'>('email');
  const scannerErrorMessage = useSelector(
    (state: RootState) => state.appGlobal.scannerErrorMessage,
  );
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const chatBotUtils = useChatBotUtils();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails) {
      setName(userDetails.name);
      setCompanyEmail(userDetails.companyEmail);
      setPhoneNumber(userDetails.phoneNumber);
    }
  }, [userDetails]);
  useEffect(() => {
    handleRecaptcha(null);
  }, [currentStep]);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: e => {
      setSubmitted(true);
      console.log(e.data.payload);
    },
  });

  const handleContinue = () => {
    if (currentStep === 2 && contactType === 'email') {
      if (!name) {
        alert('Please enter your name');
        return;
      }

      if (companyEmail.trim().length === 0) {
        alert("Company email can't be empty");
        return;
      }
      if (!EMAIL_REGEX.test(companyEmail)) {
        alert('Invalid email');
        return;
      }
      if (!isCorporateEmailDomain(companyEmail)) {
        alert('Invalid email, Please enter your corporate email');
        return;
      }

      if (phoneNumber) {
        if (!phoneNumber.startsWith('+')) {
          alert('Country code must be prefixed with "+"');
          return;
        }
        if (!isValidInternationalPhoneNumber(phoneNumber)) {
          alert('Invalid phone number');
          return;
        }
      }

      return handleSubmission();
    }

    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    if (currentStep === 1) return;
    setCurrentStep(currentStep - 1);
  };

  async function handleSubmission() {
    setLoading(true);

    try {
      const otpRes = await triggerOtpForEmail(companyEmail);

      if (
        otpRes.status === 'SUCCESS' &&
        otpRes.message === 'Email is already validated'
      ) {
        return await handleSubmit('');
      } else if (otpRes.status === 'SUCCESS') {
        setStep(2);
      } else {
        alert(otpRes.message || 'Something went wrong. Please try again.');
      }
    } catch (err: any) {
      console.log(err);
      alert(err?.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (otp: string, token?: string) => {
    const payload = {
      name,
      email: companyEmail,
      phone: phoneNumber === '+' ? '' : phoneNumber,
      gtoken: token || capchaToken,
      otp: otp ? parseInt(otp) : 0,
    };
    const result = await axios.post(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/user/registration`,
      payload,
    );

    if (
      result.data.recaptchaValid === false ||
      result?.data.message === 'Captcha is not valid'
    ) {
      alert('ReCAPTCHA validation failed. Please try again.');
      handleRecaptcha('');
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setLoading(false);

      if (token) {
        return 'Invalid Capcha';
      }
      return;
    }

    if (result?.data?.message === 'OTP is not valid') {
      alert('OTP is not valid. Please try again.');
      setLoading(false);
      return;
    }

    if (result.data.status === 'SUCCESS') {
      dispatch(
        setUserDetails({
          name,
          companyEmail,
          phoneNumber,
        }),
      );
      setName('');
      setCompanyEmail('');
      setPhoneNumber('');
      setSubmitted(true);
    }
  };

  function handleClose() {
    if (scannerErrorMessage) {
      return chatBotUtils.restart();
    }
    dispatch(setActiveModal(null));
  }

  async function handleResend() {
    setLoading(true);
    try {
      const otpRes = await triggerOtpForEmail(companyEmail);
      if (otpRes.status !== 'SUCCESS') {
        alert(otpRes.message || 'Something went wrong. Please try again.');
      }
      return true;
    } catch (err: any) {
      console.log(err);
      alert(err?.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  function handleChangeEmail() {
    setStep(1);
  }

  async function handleVerify(otp: string, token?: string) {
    setLoading(true);
    try {
      return await handleSubmit(otp, token);
    } catch (err: any) {
      console.log(err);
      alert(err?.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return createPortal(
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        inset: 0,
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: '10%',
      }}
    >
      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          maxWidth: '35rem',
          maxHeight: window.screen.height * 0.75,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          padding: '2rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {submitted ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem',
              paddingTop: '1.5rem',
            }}
          >
            <div>
              <SVGSuccess />
            </div>
            <div style={{ marginTop: '2rem' }}>
              <p style={{ fontSize: '1.5rem', fontWeight: '600' }}>
                {contactType === 'email'
                  ? 'Successfully submitted'
                  : 'Thank you'}
              </p>
            </div>
            <div style={{ marginTop: '.5rem' }}>
              <p style={{ fontSize: '1rem' }}>
                {contactType === 'email'
                  ? 'Thanks for providing the Information. Our support team will reach out to you soon.'
                  : 'Thanks for scheduling the meeting. Our support team will reach out to you soon.'}
              </p>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4',
                  marginTop: '8',
                }}
              >
                <button
                  style={{
                    borderRadius: 4,
                    border: '0',
                    backgroundColor: 'rgb(19,98,223)',
                    padding: '0.5rem 1rem',
                    fontSize: '1.2rem',
                    color: '#fff',
                    cursor: 'pointer',
                    borderWidth: 0,
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2
              style={{
                marginBottom: '.5rem',
                fontSize: '1.25rem',
                fontWeight: '500',
                color: '#374151',
              }}
            >
              Contact Us
            </h2>
            <button
              style={{
                border: '0',
                color: 'black',
                float: 'right',
                fontSize: '1.5rem',
                lineHeight: 'none',
                fontWeight: 'medium',
                outline: 'none',
                transition: 'all',
                cursor: 'pointer',
                position: 'absolute',
                right: 16,
                borderWidth: 0,
                top: 0,
                background: 'transparent',
              }}
              onClick={handleClose}
            >
              <span
                style={{
                  color: 'black',
                  height: '1.5rem',
                  width: '1.5rem',
                  fontSize: '2.5rem',
                  display: 'block',
                  outline: 'none',
                }}
              >
                ×
              </span>
            </button>
            <hr style={{ margin: '1rem 0 1rem' }} />
            {step === 1 && (
              <div>
                <div className="h-full relative">
                  <div
                    style={{
                      marginBottom: '2rem',
                      minHeight: '19rem',
                      maxHeight: '80%',
                    }}
                  >
                    {currentStep === 1 && (
                      <div style={{ marginBottom: '2rem' }}>
                        <div style={{ margin: '1rem 0 2rem' }}>
                          <h3
                            style={{
                              marginBottom: '1rem',
                              fontWeight: '600',
                              color: '#374151',
                            }}
                          >
                            How would you prefer to reach us?
                          </h3>
                          <ul
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              width: '100%',
                              fontSize: '0.875rem',
                              fontWeight: '500',
                              color: '#374151',
                              backgroundColor: '#fff',
                              border: '1px solid #e2e8f0',
                              borderRadius: '0.375rem',
                            }}
                          >
                            <li
                              style={{
                                width: '100%',
                                borderBottom: '1px solid #e2e8f0',
                                borderRight: '1px solid #e2e8f0',
                                minHeight: '7rem',
                              }}
                              onClick={() => setContactType('email')}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingLeft: '1rem',
                                }}
                              >
                                <input
                                  id="horizontal-list-radio-license"
                                  type="radio"
                                  value="email"
                                  name="list-radio"
                                  checked={contactType === 'email'}
                                  onChange={() => setContactType('email')}
                                  style={{
                                    width: '1rem',
                                    height: '1rem',
                                    color: '#3182ce',
                                    backgroundColor: '#f7fafc',
                                    border: '1px solid #d2d6dc',
                                    fontWeight: '400',
                                  }}
                                />
                                <label
                                  htmlFor="horizontal-list-radio-license"
                                  style={{
                                    width: '100%',
                                    padding: '0.75rem 0',
                                    marginLeft: '0.5rem',
                                    fontSize: '0.875rem',
                                    fontWeight: '500',
                                    color: '#374151',
                                    marginBottom: 0,
                                  }}
                                >
                                  Send email
                                </label>
                              </div>
                              <p
                                style={{
                                  paddingLeft: '2.4rem',
                                  paddingRight: '1rem',
                                  fontSize: '0.875rem',
                                  fontWeight: '500',
                                  color: 'grey',
                                  paddingBottom: '1rem',
                                }}
                              >
                                Drop your contact details, and we&apos;ll reach
                                out to you soon. We&apos;re excited to start the
                                conversation!
                              </p>
                            </li>
                            <li
                              style={{
                                minHeight: '7rem',
                                width: '100%',
                                borderBottom: '1px solid #e2e8f0',
                                borderRight: '1px solid #e2e8f0',
                              }}
                              onClick={() => setContactType('meeting')}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingLeft: '1rem',
                                }}
                              >
                                <input
                                  id="horizontal-list-radio-id"
                                  type="radio"
                                  value="meeting"
                                  name="list-radio"
                                  checked={contactType === 'meeting'}
                                  onChange={e => setContactType('meeting')}
                                  style={{
                                    width: '1rem',
                                    height: '1rem',
                                    color: '#3182ce',
                                    backgroundColor: '#f7fafc',
                                    border: '1px solid #d2d6dc',
                                    fontWeight: '400',
                                  }}
                                />
                                <label
                                  htmlFor="horizontal-list-radio-id"
                                  style={{
                                    width: '100%',
                                    padding: '0.75rem 0',
                                    marginLeft: '0.5rem',
                                    fontSize: '0.875rem',
                                    fontWeight: '500',
                                    color: '#374151',
                                    marginBottom: 0,
                                  }}
                                >
                                  Schedule a meeting
                                </label>
                              </div>
                              <p
                                style={{
                                  paddingLeft: '2.4rem',
                                  paddingRight: '1rem',
                                  fontSize: '0.875rem',
                                  fontWeight: '500',
                                  color: 'grey',
                                  paddingBottom: '1rem',
                                }}
                              >
                                Book a convenient time for a one-on-one
                                discussion. Let&apos;s collaborate and explore
                                opportunities together.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {currentStep === 2 && contactType === 'email' && (
                      <div style={{ paddingBottom: '2rem' }}>
                        <p style={{ marginBottom: '1rem' }}>
                          {' '}
                          Please share your contact information. Your privacy is
                          important to us, and your information is secure.
                        </p>

                        <div style={{ marginBottom: '.5rem' }}>
                          <label
                            htmlFor="email"
                            style={{
                              fontSize: '0.875rem',
                              lineHeight: '1.75',
                              color: '#4a5568',
                              marginBottom: 0,
                            }}
                          >
                            Name *
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            placeholder="Your Name"
                            onChange={e => setName(e.target.value)}
                            style={{
                              width: '100%',
                              borderRadius: '0',
                              border: '1px solid #ccc',
                              backgroundColor: '#fff',
                              padding: '0.5rem 0.75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#333',
                              outline: 'none',
                              transition: 'color 0.2s ease-in-out',
                              height: 'unset',
                              fontWeight: '400',
                            }}
                          />
                        </div>
                        <div style={{ marginBottom: '.5rem' }}>
                          <label
                            htmlFor="email"
                            style={{
                              fontSize: '0.875rem',
                              lineHeight: '1.75',
                              color: '#4a5568',
                              marginBottom: 0,
                            }}
                          >
                            Company Email *
                          </label>
                          <input
                            type="text"
                            id="companyEmail"
                            name="companyEmail"
                            value={companyEmail}
                            placeholder="Your Company Email"
                            onChange={e => setCompanyEmail(e.target.value)}
                            style={{
                              width: '100%',
                              borderRadius: '0',
                              border: '1px solid #ccc',
                              backgroundColor: '#fff',
                              padding: '0.5rem 0.75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#333',
                              outline: 'none',
                              transition: 'color 0.2s ease-in-out',
                              height: 'unset',
                              fontWeight: '400',
                            }}
                          />
                        </div>
                        <div style={{ marginBottom: '.5rem' }}>
                          <label
                            htmlFor="phoneNumber"
                            style={{
                              fontSize: '0.875rem',
                              lineHeight: '1.75',
                              color: '#4a5568',
                              marginBottom: 0,
                            }}
                          >
                            Phone Number
                          </label>
                          <input
                            type="phoneNumber"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Your Phone Number (e.g. +1 123 456 7890)"
                            value={phoneNumber}
                            onChange={e => {
                              setPhoneNumber(
                                new AsYouType().input(
                                  !e.target.value ||
                                    e.target.value.startsWith('+')
                                    ? e.target.value
                                    : `+${e.target.value}`,
                                ),
                              );
                            }}
                            style={{
                              width: '100%',
                              borderRadius: '0',
                              border: '1px solid #ccc',
                              backgroundColor: '#fff',
                              padding: '0.5rem 0.75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#333',
                              outline: 'none',
                              transition: 'color 0.2s ease-in-out',
                              height: 'unset',
                              fontWeight: '400',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            margin: '1rem 0',
                            minHeight: '78px',
                          }}
                        >
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            onChange={handleRecaptcha}
                          />
                        </div>
                      </div>
                    )}
                    {currentStep === 2 && contactType === 'meeting' && (
                      <div>
                        <div>
                          <div style={{ paddingBottom: '2rem' }}>
                            <InlineWidget
                              pageSettings={
                                {
                                  // hideEventTypeDetails: true,
                                  // hideLandingPageDetails: true,
                                }
                              }
                              prefill={{
                                name,
                                email: companyEmail,
                              }}
                              url="https://calendly.com/cloudframe-introduction-mac-boyle/cloudframe-code-navigator-introduction"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4',
                    position: 'sticky',
                    bottom: '-16px',
                    paddingBottom: '1rem',
                    paddingTop: '1rem',
                    background: 'white',
                    borderTopWidth: '1px',
                    width: '100%',
                    marginBottom: '-4rem',
                  }}
                >
                  {currentStep !== 1 && (
                    <button
                      onClick={handleBack}
                      style={{
                        background: 'transparent',
                        color: 'black',
                        borderWidth: 0,
                        marginRight: '1rem',
                      }}
                    >
                      Back
                    </button>
                  )}
                  {(currentStep === 1 || contactType === 'email') && (
                    <button
                      type="submit"
                      disabled={currentStep !== 1 && (loading || !capchaToken)}
                      style={{
                        borderRadius: 4,
                        backgroundColor: 'rgb(19,98,223)',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                        fontSize: '1.1rem',
                        color: '#fff',
                        borderWidth: 0,
                        opacity:
                          currentStep !== 1 && (loading || !capchaToken)
                            ? 0.5
                            : 1,
                      }}
                      onClick={handleContinue}
                    >
                      {currentStep > 1 ? 'Submit' : 'Continue'}
                    </button>
                  )}
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <VerifyEmail
                  email={companyEmail}
                  loading={loading}
                  handleResend={handleResend}
                  handleVerify={handleVerify}
                  handleChangeEmail={handleChangeEmail}
                  capchaToken={capchaToken}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};

export default LeadInformation;
