import { useEffect, useState } from 'react';
import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Badge } from 'src/components/ui/badge';
import { FiExternalLink } from 'react-icons/fi';
import {
  fetchSupportTickets,
  ticketStatusToColorMap,
} from './support-ticket.service';
import { getSearchParameter } from 'src/utils/url.utils';
import { useGetProjectSupportTicketsQuery } from 'src/redux/api-slice';
import AddNotesWidget from 'src/components/add-notes-widget/add-notes-widget';
import { ROUTES } from 'src/App.navigation';
import { useNavigate } from 'react-router-dom';

const SupportTicketsPage = () => {
  const [tickets, setTickets] = useState<any[]>([]);

  const projectId = getSearchParameter('projectId');

  const { data: supportTickets, isLoading } = useGetProjectSupportTicketsQuery(
    { projectId },
    { skip: !projectId },
  );

  console.log('tickets', supportTickets);

  useEffect(() => {
    async function fetchTickets() {
      const response = await fetchSupportTickets();
      setTickets(response);
    }

    fetchTickets();
  }, []);

  const navigate = useNavigate();

  const dashboard = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div className="p-4 flex flex-row bg-[#F3F4F4] min-h-[100vh]">
      <div className="fixed bottom-8 right-10">
        <div className="h-14 w-14 bg-grey20 rounded-full flex items-center justify-center">
          <AddNotesWidget />
        </div>
      </div>
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <ProjectSideBar activeScreen={'Jira'} />
      </div>
      <button
        className="absolute  right-8 bg-blue-600 text-white text-sm px-3 py-2 rounded-md shadow hover:bg-blue-600 transition"
        onClick={dashboard}
      >
      Back to Dashboard
      </button>
      <div className="mt-4 ml-8">
        <div>
          <h1 className="text-2xl font-bold">Application Support Tickets</h1>
          <p className="mt-2">
            Manage the JIRA tickets linked to this application. Stay on top of
            issues, tasks, and progress as you work towards completing your
            COBOL to Java transformation.
          </p>
          <div
            className="p-8 mt-[2rem] rounded-md bg-white"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
          >
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div>
                <Table>
                  <TableCaption>
                    A list of support tickets linked to this application.
                  </TableCaption>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="min-w-[7rem]">
                        Ticket Number
                      </TableHead>
                      <TableHead>Title</TableHead>
                      <TableHead className="min-w-[14rem]">Type</TableHead>
                      <TableHead className="min-w-[14rem]">Status</TableHead>
                      <TableHead className="text-right min-w-[16rem]" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {supportTickets?.map((ticket: any) => {
                      return (
                        <TableRow key={ticket.key}>
                          <TableCell className="font-medium">
                            {ticket.key}
                          </TableCell>
                          <TableCell>{ticket.fields.summary || ''}</TableCell>
                          <TableCell>
                            {ticket.fields?.issuetype?.name}
                          </TableCell>
                          <TableCell>
                            <Badge
                              style={{
                                background:
                                  ticketStatusToColorMap[
                                    ticket.fields?.status?.name
                                  ]?.background,
                                color:
                                  ticketStatusToColorMap[
                                    ticket.fields?.status?.name
                                  ]?.color,
                              }}
                              variant="default"
                            >
                              {ticket.fields?.status?.name}
                            </Badge>
                          </TableCell>
                          <TableCell className="text-right">
                            <button
                              onClick={() => {
                                window.open(
                                  `https://cloudframe.atlassian.net/servicedesk/customer/portal/3/${ticket.key}`,
                                  '_blank',
                                );
                              }}
                              className="flex items-center justify-end ml-auto text-md text-blue-500 bg-transparent border border-blue-500 rounded-md py-2 px-4 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white hover:shadow-lg"
                            >
                              <FiExternalLink className="mr-2" />
                              Open in CloudFrame Jira
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketsPage;
