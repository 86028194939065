import axios from 'axios';
import { externalConfig } from '../../utils/misc.utils';

export async function triggerOtpForEmail(email: string) {
  const result = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/user/send-otp`,
    {
      email,
    },
  );

  return result.data;
}
