import { INoteCardProps } from './notes-card.types';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const NotesCard = ({
  id,
  subject,
  createdBy,
  description,
  createdTime,
  projectId,
  onClick,
}: INoteCardProps & { onClick?: () => void }) => {

  const maxDescriptionLength = 100; 

  const handleReadMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    if(onClick){
      onClick();
    }
  };

  const formattedDescription =
    description.length > maxDescriptionLength
      ? `${description.slice(0, maxDescriptionLength)}...`
      : description;

  return (
    <div onClick={onClick} className="cursor-pointer bg-white h-full w-full">
      <div
        className="rounded-lg border bg-card text-card-foreground w-full max-w-md p-4 shadow-lg h-full flex flex-col"
        data-v0-t="card"
      >
        <div className="flex-col space-y-1 p-4 flex justify-between items-center pb-0">
          <h3 className="whitespace-nowrap tracking-tight text-lg font-semibold">
            {subject}
          </h3>
        </div>
        <div className="p-6 flex-grow space-y-2">
          <div className="text-sm text-muted-foreground">
            {dayjs(createdTime).format('MMM DD')}
          </div>
          <p className="text-base break-words leading-6 overflow-hidden">
            {formattedDescription}{' '}
            {description.length > maxDescriptionLength && (
              <span
                className="text-blue-500 cursor-pointer underline"
                onClick={handleReadMore}
              >
                Read More
              </span>
            )}
          </p>
        </div>
        <p className="text-right mr-2 text-sm text-muted-foreground pb-2">
          {createdBy}
        </p>
      </div>
    </div>
  );
};

export default NotesCard;
