import {
  Checkbox,
  initializeIcons,
  PrimaryButton,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MissingUploadList, {
  UploadMissingListRef,
} from '../../../components/missing-artifact-upload/missing-artifact-upload';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import {
  setActiveModal,
  setCurrentIntent,
  setIgnoredMissingArtifacts,
  setProcData,
  setUploadedMissingArtifacts,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  IUploadedArtifacts,
  TableData,
} from '../../../redux/app-global/app-global.types';
import { SharedService } from '../../../services/shared-service';
import {
  commaSeparated,
  downloadExtractJcl,
  externalConfig,
} from '../../../utils/misc.utils';
import { VALID_PROC_FILE_TYPES } from './missing-procs-types';
import { RootState } from '../../../redux/store';
import {
  ExtractJclRequest,
  ExtractType,
} from '../extract-artifact/extract-artifact.model';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { FaSpinner } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { MissingPageService } from '../../../services/missing-artifacts.service';
import { useGetMissingProcsQuery } from '../../../redux/api-slice';
import { startScan } from '../../../components/upload-list/upload-list.service';
import { getSearchParameter } from 'src/utils/url.utils';

initializeIcons();

const MissingProcs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [toggleValue, setToggleValue] = useState<boolean>(false);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const [data, setData] = useState<TableData[]>([]);
  const [missingArtifacts, setMissingArtifacts] =
    useState<IUploadedArtifacts>();
  const isMounted = useRef(false);
  const [datasetNames, setDatasetNames] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const ignoredMissingArtifacts = useSelector(
    (state: RootState) => state.appGlobal.ignoredMissingArtifacts,
  );
  const [alertShown, setAlertShown] = useState({ missingProcs: false });
  const missingProcsQuery = useGetMissingProcsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const procData = useSelector((state: RootState) => state.appGlobal.procData);
  const pagHelpTexts = helpTexts[0].MISSING_PROC;
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);
  const projectId = getSearchParameter('projectId');

  useEffect(() => {
    if (
      data?.length > 0 &&
      ignoredMissingArtifacts?.missingProc &&
      Array.isArray(ignoredMissingArtifacts.missingProc) &&
      ignoredMissingArtifacts.missingProc.length > 0
    ) {
      const selectedIndexes = {};
      ignoredMissingArtifacts.missingProc.forEach(item => {
        const index = data?.findIndex(dataItem => dataItem.name === item.name);

        if (index !== -1) {
          selectedIndexes[index] = true;
        }
      });

      setSelectedRows(prev => ({
        ...prev,
        ...selectedIndexes,
      }));
      setData(prevData =>
        prevData.map(item => ({
          ...item,
          status: !selectedIndexes[item.key] ? 'Ignore' : 'Active',
        })),
      );
    }
  }, [ignoredMissingArtifacts, data?.length]);

  const triggerFileUpload = (event, itemKey) => {
    if (uploadMissingListRef.current) {
      uploadMissingListRef.current.handleFileUpload(event, itemKey);
    }
  };
  const handleFilesUpdate = updatedFiles => {
    setFiles(updatedFiles);
  };
  const dispatch = useDispatch();

  const handleCheckboxChange = key => {
    setSelectedRows(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
    setData(prevData =>
      prevData.map(item =>
        item.key === key
          ? { ...item, status: !selectedRows[key] ? 'Ignore' : 'Active' }
          : item,
      ),
    );
  };
  const sendProcInfo = async () => {
    const formData = new FormData();
    const requestBody: any = [];
    data.forEach(row => {
      const item = {
        name: row.name,
        status: row.status || '',
        file: row.uploaded || '',
        referredIn: row.requiredBy || [],
      };
      requestBody.push(item);
    });

    formData.append('missingData', JSON.stringify(requestBody));

    Object.values(files).forEach(file => {
      console.log((file as any).originalFile);
      if (file) {
        formData.append('fileList', (file as any).originalFile);
      }
    });

    try {
      setLoading(true);
      const response = await axios.post(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/missing-artifact/${projectId}/PROC`,
        formData,
      );

      if (
        response.data?.status !== 'SUCCESS' ||
        response.data?.message === 'Some artifacts are missing '
      ) {
        Swal.fire({
          title: 'Resolve all missing artifacts',
          text:
            response.data?.message ||
            'Failed to upload missing artifacts. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });

        await missingProcsQuery.refetch();
        return;
      }

      dispatch(setIgnoredMissingArtifacts(response.data?.data?.ignoredData));
      dispatch(setUploadedMissingArtifacts(missingArtifacts));
      dispatch(setProcData(data));

      const nextIntent = MissingPageService.getRouteFromAction(
        response.data?.data?.nextAction,
      );

      if (nextIntent === 'REPORT') {
        dispatch(setIgnoredMissingArtifacts(response.data?.data?.ignoredData));
      }

      if (nextIntent === EAppIntent.UPLOADING_ARTIFACT) {
        const scanStarted = await startScan(dispatch);
        if (!scanStarted) {
          return;
        }
      }

      dispatch(setCurrentIntent(nextIntent));
    } catch (err) {
      setLoading(false);
      SharedService.genericAlertModal.title =
        'Upload of Missing Artfacts Failed!';
      SharedService.genericAlertModal.description =
        (err as any)?.message || 'Something went wrong !';
      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
      }, 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (procData && procData?.length > 0) {
      setData(procData);
      return;
    }

    const { missingData = [], ignoredData = [] } = missingProcsQuery.data || {};
    console.log(missingProcsQuery.data);

    const transformedData: TableData[] = missingData.map(
      (item: any, index: number) => ({
        key: index,
        status: 'Active',
        name: item.name,
        requiredBy: item.referredIn,
        uploaded: null,
      }),
    );
    const missingLen = transformedData.length;
    let selRows = {};
    ignoredData.forEach((item: any, index: number) => {
      selRows[index + missingLen + 1] = true;
      transformedData.push({
        key: index + missingLen + 1,
        status: 'Ignore',
        name: item.name,
        requiredBy: item.referredIn,
        uploaded: null,
      } as any);
    });

    setSelectedRows(selRows);
    setData(transformedData);
  }, [missingProcsQuery]);

  const handleSelectAllCheckboxChange = (event, checked) => {
    setSelectAll(checked);
    const newSelectedRows = {} as Record<number, boolean>;
    (data || []).forEach(item => {
      newSelectedRows[item.key] = checked;
    });
    const newData = (data || []).map(item => {
      newSelectedRows[item.key] = checked;
      return { ...item, status: checked ? 'Ignore' : 'Active' };
    });
    setSelectedRows(newSelectedRows);
    setData(newData);
  };

  const changeDisplay = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setToggleValue(checked ?? false);
  };

  const headers: React.ReactNode[] = [
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllCheckboxChange}
      title="Ignore All"
      label="Ignore All"
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
      key="1"
    />,
    'Proc',
    'Artifact',
    !toggleValue && !(data?.every(item => item.uploaded) || false) && 'Upload',
  ];

  const rowFields = item => [
    <Checkbox
      checked={selectedRows[item.key] || false}
      onChange={() => handleCheckboxChange(item.key)}
      className="mt-2"
      title="Ignore"
      label="Ignore"
      key={item.key}
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
    />,
    <div
      className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
      key={item.key}
    >
      {item.name}
    </div>,
    item.requiredBy?.jcl?.length <= 4 ? (
      <div
        className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
        key={item.key}
      >
        {item.requiredBy?.jcl?.join(', ')}
      </div>
    ) : (
      <div key={item.key}>
        <TooltipHost
          id={`tooltip-${item.key}`}
          content={item.requiredBy?.jcl
            ?.slice(4, item.requiredBy?.jcl.length - 1)
            .join(', ')}
        >
          <div
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            {item.requiredBy?.jcl?.slice(0, 3).join(', ')}
          </div>
          <span
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            ... and {item.requiredBy?.jcl?.length - 3} more
          </span>
        </TooltipHost>
      </div>
    ),
    selectedRows[item.key] ? (
      item.uploaded ? (
        <MissingUploadBtn
          handleFileUpload={triggerFileUpload}
          item={item}
          buttonStyle={{ cursor: 'not-allowed', backgroundColor: '#f0f0f0' }}
        />
      ) : (
        ''
      )
    ) : (
      !item.uploaded &&
      !toggleValue && (
        <MissingUploadBtn
          handleFileUpload={triggerFileUpload}
          item={item}
          accept={`${VALID_PROC_FILE_TYPES.map(type => '.' + type).join(',')}`}
        />
      )
    ),
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setError(validationError);
      setSuccess(null);
    } else {
      setError(null);
      setSuccess(null);
    }
    setDatasetNames(value);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const validationError = commaSeparated(datasetNames.trim());
    if (validationError) {
      setError(validationError);
      setSuccess(null);
      return;
    }
    if (!datasetNames.trim()) {
      setError('Dataset name is required.');
      setSuccess(null);
      return;
    }
    const datasetArray = datasetNames.split(',').map(name => name.trim());

    const data: ExtractJclRequest = {
      dataset: {
        proc: datasetArray,
      },
      downloadFor: 'PROC' as ExtractType,
    };

    const isDownloadSuccessful = await downloadExtractJcl(data);

    if (isDownloadSuccessful) {
      setSuccess('Data successfully posted and file downloaded!');
      setError(null);
      setDatasetNames('');
    } else {
      setError('Failed to post data or download the file. Please try again.');
      setSuccess(null);
    }
  };

  if (missingProcsQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (missingProcsQuery.isError && !alertShown.missingProcs) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to fetch Missing Program details. Please try again later.',
      footer: 'Please contact support',
      showCancelButton: true,
      confirmButtonText: 'Retry',
      cancelButtonText: 'Go Back',
    }).then(result => {
      if (result.isConfirmed) {
        missingProcsQuery.refetch();
        setAlertShown({ missingProcs: false });
      } else {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      }
    });
    setAlertShown({ missingProcs: true });
    return null;
  }

  return (
    <div
      className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}
    >
      <div className="p-2 px-8 mb-4">
        <StepperComponent />
      </div>
      <div className="mb-2 border rounded-lg px-8 py-8 shadow max-w-full">
        <h1 className="text-3xl font-semibold mb-4">
          Missing Artifacts Workspace
        </h1>
        <hr />
        <div className="flex flex-col gap-4">
          <div className="text-left mt-4 gap-4 text-l font-semibold">
            Missing PROC Information
          </div>
          <div>
          Use this workspace to address missing PROC artifacts required for your application. You can either upload the missing PROC files directly or provide details to extract them from the mainframe. Review the listed artifacts, select the appropriate action (e.g., upload or ignore), and save your updates. Ensure all necessary PROC data is available before proceeding to the next step.
          </div>
          <div className="flex items-center gap-2">
            <HelpTextCallout calloutText={pagHelpTexts[4].description} />
            <span className="text-lg">Upload PROC</span>
            <Toggle className="mt-2" onChange={changeDisplay} />
            <span className="text-lg">Extract PROCs from Mainframe</span>
          </div>
          {toggleValue && (
            <div>
              <h3> Provide dataset names below (comma-separated) for extracting missing PROC files. Then, click "Generate Extraction JCL" to create the necessary JCL for extracting the PROC files :</h3>
              <input
                type="text"
                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                value={datasetNames}
                onChange={handleChange}
                placeholder="Enter dataset names..."
              />
              {error && (
                <TooltipHost content={error} id="error-tooltip">
                  <div className="mt-2 text-red-500">{error}</div>
                </TooltipHost>
              )}
              {success && (
                <TooltipHost content={success} id="success-tooltip">
                  <div className="mt-2 text-green-500">{success}</div>
                </TooltipHost>
              )}
            </div>
          )}
          {toggleValue ? (
            <div>
              {(data.every(
                item => item.status !== 'Active' || item.uploaded !== undefined,
              ) ||
                Object.values(files).length > 0 ||
                toggleValue ||
                true) && (
                <div className="flex flex-row items-center mt-1">
                  <HelpTextCallout calloutText={pagHelpTexts[6].description} />
                  <PrimaryButton
                    text={'Generate Extraction JCL'}
                    className="rounded-md"
                    styles={{ root: { backgroundColor: '#1363DF' } }}
                    onClick={handleSubmit}
                  />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          {toggleValue? <div className="mt-2 text-l font-semibold">Review Missing PROC Information</div> : ''}
          {!toggleValue && (
            <>
              <div className="font-semibold mt-4">Option 1: Upload Missing PROC Files Individually</div>
              <p className='mt-2'>Use this table to upload the missing PROC files for each artifact individually. Select the appropriate action and upload as needed</p>
              <hr />
            </>
          )}
          <ReusableTable
            items={data}
            headers={headers}
            rowFields={rowFields}
            helpTexts={pagHelpTexts}
          />
          {!toggleValue ? (
            <MissingUploadList
              ref={uploadMissingListRef}
              setData={setData}
              setMissingArtifacts={setMissingArtifacts as any}
              type="PROC"
              data={data}
              onFilesUpdate={handleFilesUpdate}
              pageHelpText={pagHelpTexts}
            />
          ) : (
            ''
          )}
        </div>
        {!toggleValue && (
          <div>
            {(data.every(
              item => item.status !== 'Active' || item.uploaded !== undefined,
            ) ||
              Object.values(files).length > 0 ||
              toggleValue ||
              true) && (
              <div className="flex flex-row gap-4 items-center">
                <PrimaryButton
                  text="Save and Continue"
                  className="mt-8 rounded-md"
                  styles={{ root: { backgroundColor: '#1363DF' } }}
                  onClick={sendProcInfo}
                  disabled={loading}
                />
                {loading && (
                  <div className="flex gap-2 mt-4">
                    <FaSpinner className="animate-spin" />
                    <p>Updating Missing data ....</p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MissingProcs;
