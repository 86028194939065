import axios from 'axios';
import {
  EProjectStatus,
  IProjectApiObject,
} from 'src/pages/dashboard/dashboard.service';
import { appTheme } from 'src/styles/theme';
import { externalConfig } from 'src/utils/misc.utils';

export interface IUser {
  id: number;
  name: string;
  email: string;
  status: string;
  role: string;
  active: string;
}
export const userStatusToColorMap = {
  INACTIVE: {
    background: appTheme.colors.grey20,
    color: '#000000',
  },
  ACTIVE: {
    background: '#008000',
    color: '#FFFFFF',
  },
};

export interface IUserApiObject {
  active: boolean;
  email: string;
  id: number;
  name: string;
  role: string;
  userName: string;
}

export interface IUserProfile {
  user: IUserApiObject;
  projects: IProjectApiObject[];
}

export const fetchUsers = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-user-service/users`,
    );
    if (Array.isArray(response.data?.data)) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchUserProfile = async (
  userId: number | string,
): Promise<IProjectApiObject[]> => {
  const response = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/user/${userId}`,
  );

  return response.data?.data?.projectList || [];
};

export const editUserDetails = async (userId: string, userData: any) => {
  return await axios.put(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-user-service/user/${userId}`,
    userData,
  );
};

export const createNewUser = async (userData: any) => {
  return await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-user-service/user`,
    userData,
  );
};

export const toggleUserStatus = async (userId: string) => {
  return await axios.put(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-user-service/user/change-status/${userId}`,
  );
};
