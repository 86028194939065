import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const getDocumentationPageProtectedUrl = async () => {
  const response = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/kb/integration`,
  );

  return { 
    kbAccessToken: response.data.data.kbAccessToken,
    redirectUrl: response.data.data.redirectUrl ,
  };
};
