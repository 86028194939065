import { useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { FaNoteSticky } from 'react-icons/fa6';
import { FiFileText, FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { GrLicense } from 'react-icons/gr';
import { IoCreateSharp } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';
import { GoVersions } from 'react-icons/go';
import { useUser } from 'src/hooks/useUser';
import AdminOnly from '../admin-only/admin-only';

const AdminSideBar = ({ activeScreen }) => {
  const [activeIcon, setActiveIcon] = useState(activeScreen);
  const navigate = useNavigate();

  const user = useUser();

  const routesMap = {
    Home: ROUTES.DASHBOARD,
    License: user?.role === 'Member' ? ROUTES.MEMBER_LICENSE : ROUTES.LICENSE,
    Reports: ROUTES.ADMIN_REPORTS,
    Notes: ROUTES.ADMIN_NOTES,
    GlobalSettings: ROUTES.ADMIN_GLOBAL_PROJECT_SETTINGS,
    DraftProject: ROUTES.DRAFT_PROJECTS,
    Users: ROUTES.USERS,
  };

  const iconClass = iconName =>
    iconName === activeIcon ? 'text-cta' : 'text-gray-500';

  const onClickItem = (screen: string) => {
    setActiveIcon(screen);
    if (routesMap[screen]) navigate(routesMap[screen]);
  };

  return (
    <div className="absolute bg-white w-8 mt-4 left-2 px-6 py-6 flex flex-col items-center gap-8 shadow-lg border rounded-md">
      <FaHome
        title="Home"
        size={24}
        className={`cursor-pointer ${iconClass('Home')}`}
        onClick={() => onClickItem('Home')}
      />
      <GrLicense
        title="License"
        size={24}
        className={`cursor-pointer ${iconClass('License')}`}
        onClick={() => onClickItem('License')}
      />
      
      <AdminOnly>
      <div className="flex flex-col items-center gap-8 ">
      <FiFileText
        title="Reports"
        size={24}
        className={`cursor-pointer ${iconClass('Reports')}`}
        onClick={() => onClickItem('Reports')}
      />
      <FaNoteSticky
        title="Notes"
        size={24}
        className={`cursor-pointer ${iconClass('Notes')}`}
        onClick={() => onClickItem('Notes')}
      />
      <FiSettings
        title="Global Settings"
        size={24}
        className={`cursor-pointer ${iconClass('GlobalSettings')}`}
        onClick={() => onClickItem('GlobalSettings')}
      />
      <IoCreateSharp
        title="Draft Applications"
        size={24}
        className={`cursor-pointer ${iconClass('DraftProject')}`}
        onClick={() => onClickItem('DraftProject')}
      />
      <FiUsers
        title="Users"
        size={24}
        className={`cursor-pointer ${iconClass('Users')}`}
        onClick={() => onClickItem('Users')}
      />
        </div>
      </AdminOnly>
    </div>
  );
};

export default AdminSideBar;
