import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaGithub } from 'react-icons/fa';
import { useGetGlobalProjectDeploySettingsQuery } from 'src/redux/api-slice';
import { externalConfig } from 'src/utils/misc.utils';
import Swal from 'sweetalert2';
import { ToggleField } from './settings-form-component';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { helpTexts } from 'src/helpTexts';

const DeploySettings = () => {
  const { data, error, isLoading, refetch } = useGetGlobalProjectDeploySettingsQuery();
  const pageHelpTexts = helpTexts[0].DEPLOY_SETTINGS;

  const handleGlobalChoice = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      useSingleRepository: !prevSettings.useSingleRepository,
    }));
  };

  const handleStageChoice = (stage) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [stage]: !prevSettings[stage],
    }))
  };

  const [settings, setSettings] = useState({
    deployToken: data?.data?.deployToken || '',
    id: data?.data?.id || 0,
    useSingleRepository: data?.data?.useSingleRepository,
    transform: data?.data?.useSingleRepoForTransform,
    smartdoc: data?.data?.useSingleRepoForSmartDoc,
  });

  console.log(settings)

  useEffect(() => {
    if(data?.data) {
        setSettings({
          deployToken: data?.data?.deployToken || '',
          id: data?.data?.id || 0,
          useSingleRepository: data?.data?.useSingleRepository,
          transform: data?.data?.useSingleRepoForTransform ?? false,
          smartdoc: data?.data?.useSingleRepoForSmartDoc ?? false,
        })
    }
    if(!data?.data) {
      setSettings({
        deployToken: data?.data?.deployToken || '',
        id: data?.data?.id || 0,
        useSingleRepository: true,
        transform: false,
        smartdoc: false,
      })
    }
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Unable to load global Deplpy Settings. Please try again later.',
      });
    }
  }, [error, data]);


  const handleUpdateOverrideSettings = async () => {
    try {
      console.log(settings)
      const response = await axios.put(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-test-service/deploy/config`,
        {
          deployToken: settings.deployToken,
          id: data?.data?.id || 0,
          useSingleRepository: settings.useSingleRepository,
          useSingleRepoForTransform: settings.useSingleRepository ? null : settings.transform,
          useSingleRepoForSmartDoc: settings.useSingleRepository ? null : settings.smartdoc,
        }
      );

      if (response?.data?.status === 'SUCCESS') {
        Swal.fire({
          icon: 'success',
          title: 'Deploy Settings Updated',
          text: response?.data?.message,
          confirmButtonText: 'OK',
        });
        refetch();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Deploy Settings Error',
          text: response?.data?.message,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error updating override settings', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDeployTokenChange = (e) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      deployToken: e.target.value,
    }));
  };

  const noProjectSettings = settings && Object.keys(settings).length === 0;

  return (
    <div className="p-1 min-h-screen bg-gray-100">
      <div className="max-w-5xl bg-white shadow-2xl rounded-lg p-8">
        {isLoading ? (
          <div className="text-center text-lg text-gray-500">Loading...</div>
        ) : noProjectSettings ? (
          <div className="text-center text-lg text-gray-500">No Deploy Settings</div>
        ) : (
          <div className="flex gap-6">
            <div className="w-1/2 p-4">
              <div className="mb-6">
                  <label className="flex items-center gap-2 text-md font-medium mb-2">
                    <FaGithub /> GitHub Access Token 
                    <HelpTextCallout calloutText={pageHelpTexts[0].description}/>
                  </label>
                  <input
                    type="password"
                    value={settings.deployToken}
                    onChange={handleDeployTokenChange}
                    className="border rounded-md p-2 w-full shadow-sm mt-2"
                  />
              </div>
              <ToggleField
                label="Single Repository for All Stages"
                isChecked={settings.useSingleRepository ? 'true' : 'false'}
                onChange={handleGlobalChoice}
                helpText="Toggle between a single repository for all artifacts or separate repositories for each."
              />
            </div>
            {!settings.useSingleRepository && (
              <>
                <div className="w-px bg-gray-300 self-stretch"></div>
                <div className="w-1/2 p-4">
                  <h4 className="text-md font-bold mb-4 text-center">Repository Configuration for Each Stage</h4>
                  {['transform', 'smartdoc'].map((stage) => (
                    <div key={stage} className="mb-6">
                      <ToggleField
                        label={`Single Repository for ${stage.charAt(0).toUpperCase() + stage.slice(1)} Stage Artifacts`}
                        isChecked={settings[stage] ? 'true' : 'false'}
                        onChange={() => handleStageChoice(stage)}
                        helpText={`Choose whether to use a single repository or separate repositories for the ${stage} stage.`}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}

        {/* Save Button */}
        {!(isLoading || noProjectSettings) && (
          <div className="mt-8 flex justify-end">
            <button
              onClick={handleUpdateOverrideSettings}
              className="px-5 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 flex items-center gap-2 text-md font-medium"
            >
              <FaGithub /> Save Deploy Settings
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeploySettings;
