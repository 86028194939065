import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useGetProjectArtifactsQuery,
  useGetProjectDetailQuery,
} from 'src/redux/api-slice';
import { setInvalidFileData, setUploadedArtifacts } from 'src/redux/app-global';
import { IUploadedArtifacts } from 'src/redux/app-global/app-global.types';
import { getSearchParameter } from 'src/utils/url.utils';

const useUploadList = () => {
  const dispatch = useDispatch();

  const projectId = getSearchParameter('projectId');
  const { data, isLoading } = useGetProjectDetailQuery(
    { projectId },
    {
      skip: !projectId,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );
  const artifactValidationDataQuery = useGetProjectArtifactsQuery(
    { projectId },
    {
      skip: !projectId,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    },
  );
  useEffect(() => {
    const setupArtifactStatus = () => {
      const artifactList: IUploadedArtifacts = {
        artifacts: {},
        missingFiles: [],
        dependentFiles: [],
        zipFileStatus: {},
        fileStatus: {},
      };
      const invalidFiles = {};
      const validationObj = artifactValidationDataQuery.data?.data || {};

      data?.projectArtifactList?.forEach(artifact => {
        const artifactNameSplit = artifact.artifactName.split('.');

        artifactList.artifacts[artifact.artifactName] = {
          name: artifact.artifactName,
          type: artifact.artifactType
            ? artifact.artifactType
            : artifactNameSplit[artifactNameSplit.length - 1]?.toUpperCase(),
          originalFile: {
            name: artifact.artifactName,
          },
          uploadProgress: 0,
          error: '',
        };

        artifactList.fileStatus[artifact.artifactName] = {
          status:
            artifact.artifactState === 'UPLOADED'
              ? 'PENDING'
              : artifact.tokenProcessorStatus,
        };

        artifactList.zipFileStatus[artifact.artifactName] = {
          status:
            artifact.artifactState === 'UPLOADED' ||
            (artifact.artifactType !== 'ZIP' && artifact.artifactType !== 'XMI')
              ? 'PENDING'
              : artifact.tokenProcessorStatus,
        };

        const findInValidation = (name: string): any =>
          Object.values(validationObj).find((x: any) => x.name === name);
        console.log('Validation obj:', {
          name: artifact.artifactName,
          validationObj,
          selected: findInValidation(artifact.artifactName)?.totalSummary,
        });
        if (
          artifact.artifactType === 'ZIP' ||
          artifact.artifactType === 'XMI'
        ) {
          artifactList.zipFileStatus[artifact.artifactName].totalSummary =
            findInValidation(artifact.artifactName)?.totalSummary;
          artifactList.zipFileStatus[artifact.artifactName].child =
            findInValidation(artifact.artifactName)?.child;
        }

        if (
          artifact.tokenProcessorStatus === 'NOT_VALID' ||
          artifact.artifactState === 'IGNORED'
        ) {
          invalidFiles[artifact.artifactName] = {
            name: artifact.artifactName,
            type: artifact.artifactType,
          };
        }
      });

      dispatch(setInvalidFileData(invalidFiles));
      dispatch(setUploadedArtifacts(artifactList));
    };

    if (data && !isLoading && !artifactValidationDataQuery.isLoading) {
      setupArtifactStatus();
    }
  }, [data, isLoading, artifactValidationDataQuery]);

  return { isLoading: isLoading || artifactValidationDataQuery.isLoading };
};

export default useUploadList;
