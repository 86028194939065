import axios from 'axios';
import React, { useState } from 'react';
import { FaCheck, FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import SVGLoader from 'src/assets/images/svg/loader.icon';
import { downloadBase64FromResponse, externalConfig } from 'src/utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';
import UploadCompareModal from './fileUploadModal';
import SubTable from './sub-table';
import { JobData, Row } from './test-chevron.types';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { helpTexts } from 'src/helpTexts';
import Swal from 'sweetalert2';


interface TableProps {
    data: JobData[];
    onSwitchChange: (index: number) => void;
    onExpandToggle: (index: number) => void;
    setData: any;
    loading: boolean;
}


const Table: React.FC<TableProps> = ({ data, onSwitchChange, onExpandToggle, setData, loading }) => {
    const dispatch = useDispatch();
    const projectId = getSearchParameter('projectId');
    const pageHelpTexts = helpTexts[0].TEST_CHEVRON;
    const extractFile = async (index) => {
        const updatedData = [...data];
        const jobName = updatedData[index].jobName;
        try {
            const apiUrl = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/transform-job/export-jcl/${projectId}/${jobName}`;
            const response = await axios.get(apiUrl);
            if(!response.data.data) {
                Swal.fire({
                    icon: 'warning',
                    title: 'No file found to export',
                    text: `${response.data?.message} Please contact CloudFrame Support Team for further assistance`,
                    confirmButtonText: 'OK',
                });
                return;
            }
            if(response.data.status === 'ERROR') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Unable to connect to server to export JCL for this job. Please contact CloudFrame Support Team for further assistance`,
                    confirmButtonText: 'OK',
                });
                return;
            }
            downloadBase64FromResponse(response);
        } catch (error) {
            console.error("Error while downloading jcl: ", error)
        }
    }
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [jobData, setJobData] = useState<Row>();

    const openModal = (index: number, jobData: Row) => {
        setSelectedIndex(index);
        setIsModalVisible(true);
        setJobData(jobData)
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedIndex(null);
    };

    return (
        <div>
            <table className="w-full caption-bottom text-sm">
                <thead>
                    <tr className="border-b">
                        <th className="h-12 px-4 text-left font-medium text-muted-foreground w-[150px]"><div className='flex items-center gap-2 justify-start text-sm '>Expand/Collapse to view I/O Files<HelpTextCallout calloutText={pageHelpTexts[0].description}/></div></th>
                        <th className="h-12 px-4 text-left font-medium text-muted-foreground w-[100px]"><div className='flex items-center gap-2 justify-start text-sm '>Job Name<HelpTextCallout calloutText={pageHelpTexts[1].description}/></div></th>
                        {/* <th className="h-12 px-4 text-center font-medium text-muted-foreground w-[100px]">Execution Type</th> */}
                        <th className="h-12 px-4 text-center font-medium text-muted-foreground w-[100px]"><div className='flex items-center gap-2 justify-center ml-6 text-sm '>Validation<HelpTextCallout calloutText={pageHelpTexts[2].description}/></div></th>
                        <th className="h-12 px-4 text-center font-medium text-muted-foreground w-[150px]"><div className='flex items-center gap-2 justify-center ml-6 text-sm '>Export JCL <HelpTextCallout calloutText={pageHelpTexts[3].description}/></div></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((job, index) => (
                        <React.Fragment key={index}>
                            <tr className="hover:bg-muted/50 border-b">
                                <td className="p-4 align-middle">
                                    {job.expanded ? (
                                        <FaChevronUp
                                            className="cursor-pointer"
                                            onClick={() => onExpandToggle(index)}
                                            title='Click to hide input and output files'
                                        />
                                    ) : (
                                        <FaChevronDown
                                            className="cursor-pointer"
                                            onClick={() => onExpandToggle(index)}
                                            title='Click to view input and output files'
                                        />
                                    )}
                                </td>
                                <td className="p-4 align-middle font-medium">{job.jobName}</td>
                                {/* <td className="p-4 align-middle">
                                    <div className="flex items-center justify-center gap-3 w-full">
                                        <span className="text-sm text-muted-foreground">Local Run</span>
                                        <Switch
                                            checked={job.executionType}
                                            onChange={() => onSwitchChange(index)}
                                        />
                                        <span className="text-sm text-muted-foreground">Server Run</span>
                                    </div>
                                </td> */}
                                <td className="p-4 align-middle text-center">
                                    {job.validated === 'PASS' ? (
                                        <FaCheck className="text-green-500 inline-block" title='Success' />
                                    ) : (
                                        job.validated === 'FAIL' ? (
                                            <FaTimes className="text-red-500 inline-block" title='Failed' />
                                        ) : <div className='text-sm rounded-full bg-yellow-500 w-3.5 h-3.5 inline-block' title='Pending...'></div>
                                    )}
                                </td>
                                <td className="p-4 align-middle text-center">
                                    <button
                                        className='bg-cta text-white p-2 rounded-md inline-flex justify-center items-center gap-2 text-sm'
                                        onClick={() => extractFile(index)}
                                    >
                                        Export JCL
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={5}>
                                    <div
                                        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${job.expanded ? 'max-h-screen' : 'max-h-0'}`}
                                    >
                                        {job.expanded && (
                                            job.rows.length > 0 ?
                                                <SubTable
                                                    rows={job.rows}
                                                    downloadStatus={job.downloadStatus}
                                                    openModal={openModal}
                                                    index={index}
                                                />
                                                :
                                                <div className='bg-gray-100'>
                                                    <div className='p-6'>
                                                        <div className='bg-white shadow-md rounded-md p-4 text-center text-orange-500'>
                                                            {loading ? <SVGLoader /> : 'No file data available for this job'}
                                                        </div>
                                                    </div>
                                                </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table >
            {selectedIndex !== null && (
                <UploadCompareModal
                    index={selectedIndex}
                    data={data}
                    setData={setData}
                    isVisible={isModalVisible}
                    onClose={closeModal}
                    jobData={jobData as Row}
                />
            )}
        </div>
    );
};

export default Table;