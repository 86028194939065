import React, { useEffect, useState } from 'react';
import { ISubTable } from '../table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { FaDownload } from 'react-icons/fa';
import {
  setArtifactDownloadAsService,
  setArtifactDownloadAsServiceObject,
  setArtifactStatus,
  setArtifactStatusObject,
  setArtifactTransformProfile,
  updateTransformationProgress,
} from '../../../../../redux/app-global';
import { EArtifactStatus } from '../../../../../redux/app-global/app-global.types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import useSubTable from './useSubTable';
import {
  downloadCode,
  downloadErrorLog,
  startTransformation,
  stopTransformation,
} from '../../transformation.service';
import { Checkbox, Tooltip } from 'antd';
import Swal from 'sweetalert2';
import HelpTextCallout from '../../../../../components/help-text-callout/help-text-callout';
import { useIsProjectLead } from 'src/pages/project-overview/card/stages-component/useIsProjectLead';

const SubTable = ({
  columnHelpTexts,
  ButtonHelpTexts,
  ...table
}: ISubTable) => {
  const dispatch = useDispatch();
  const artifactStatus = useSelector(
    (state: RootState) => state.appGlobal.artifactStatus || {},
  );
  const transformationProgress = useSelector(
    (state: RootState) => state.appGlobal.transformationProgress || {},
  );
  const transformationStatus = useSelector(
    (state: RootState) => state.appGlobal.transformationStatus,
  );
  const licenseStatusDetails = useSelector(
    (state: RootState) => state.appGlobal.licenseStatusDetails,
  );
  const artifactDownloadAsService = useSelector(
    (state: RootState) => state.appGlobal.artifactDownloadAsService || {},
  );
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<
    string | null
  >(null);
  const [showBulkCancelConfirmation, setShowBulkCancelConfirmation] =
    useState<boolean>(false);
  const { showCallChainModal } = useSubTable();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterOption, setFilterOption] = useState<string>('All');

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});
  const [selectAllOption, setSelectAllOption] = useState(false);

  const isProjectLead = useIsProjectLead();

  useEffect(() => {
    if (
      licenseStatusDetails &&
      licenseStatusDetails.licenseStatus === 'FAILED'
    ) {
      Swal.fire({
        icon: 'warning',
        title: 'LOC limit exceeded',
        html: `${licenseStatusDetails.message}<br/>Please contact CloudFrame Support team for further assistance.`,
        confirmButtonColor: '#FFC107',
      });
    }
  }, [licenseStatusDetails]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const confirmCancel = (name: string) => {
    setShowCancelConfirmation(name);
  };

  const handleCancel = async (name: string) => {
    setShowCancelConfirmation(null);
    setLoading(prev => ({ ...prev, [name]: true }));
    try {
      const response = await stopTransformation(
        [{ name, service: artifactDownloadAsService[name] === 'yes' }],
        table.title === 'Jobs'
          ? 'JCL'
          : table.title === 'Driver Programs'
            ? 'NON_CICS'
            : 'CICS',
      );
      if (response?.status === 'SUCCESS') {
        setShowCancelConfirmation(null);
        dispatch(
          setArtifactStatus({
            key: name,
            status: EArtifactStatus.DEFAULT,
          }),
        );
        setTimeout(() => {
          dispatch(
            updateTransformationProgress({
              ...transformationProgress,
              [name]: 0,
            }),
          );
        });
      } else {
        console.log('Failed to start the transform process');
        throw new Error('Failed to stop the transform process');
      }
    } catch (err: any) {
      console.log('Error:', err);
      Swal.fire({
        title: 'Error',
        text:
          err?.response?.data?.message ||
          err?.message ||
          'Failed to stop the transform process',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    } finally {
      setLoading(prev => ({ ...prev, [name]: false }));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterOption(event.target.value);
  };

  const filterRows = (name: string) => {
    if (
      searchQuery &&
      !name.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return false;
    }
    if (
      filterOption === 'Transformation in progress' &&
      artifactStatus[name] !== EArtifactStatus.INPROGRESS
    ) {
      return false;
    }
    if (
      filterOption === 'Transformation finished' &&
      artifactStatus[name] !== EArtifactStatus.SUCCESS
    ) {
      return false;
    }
    if (
      filterOption === 'Transformation failed' &&
      artifactStatus[name] !== EArtifactStatus.ERROR
    ) {
      return false;
    }
    return true;
  };

  const handleBulkTransform = async () => {
    try {
      const object: Record<string, EArtifactStatus> = {};
      const payload: { name: string; service: boolean }[] = [];
      table.rows.forEach(row => {
        if (
          !artifactStatus[row.name] ||
          artifactStatus[row.name] === EArtifactStatus.DEFAULT
        ) {
          object[row.name] = EArtifactStatus.INPROGRESS;
          const isService = artifactDownloadAsService[row.name] === 'yes';
          payload.push({ name: row.name, service: isService });
        }
      });

      if (payload.length <= 0) {
        return;
      }
      const response = await startTransformation(
        payload,
        table.title === 'Jobs'
          ? 'JCL'
          : table.title === 'Driver Programs'
            ? 'NON_CICS'
            : 'CICS',
      );

      if (response.status === 'SUCCESS') {
        console.log('Successfully started the transform process');
        dispatch(setArtifactStatusObject(object));
      } else {
        console.log('Failed to start the transform process');
        throw new Error('Failed to start the transform process');
      }
    } catch (err: any) {
      console.log('Error:', err);
      Swal.fire({
        title: 'Error',
        text:
          err?.response?.data?.message ||
          err?.message ||
          'Failed to start the transform process',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
  };

  const handleBulkCancel = () => {
    setShowBulkCancelConfirmation(true);
  };

  const confirmBulkCancel = async () => {
    try {
      setShowBulkCancelConfirmation(false);
      const payload: { name: string; service: boolean }[] = [];
      const object: Record<string, EArtifactStatus> = {};
      table.rows.forEach(row => {
        if (artifactStatus[row.name] === EArtifactStatus.INPROGRESS) {
          object[row.name] = EArtifactStatus.DEFAULT;
          payload.push({
            name: row.name,
            service: artifactDownloadAsService[row.name] === 'yes',
          });
        }
      });

      if (payload.length <= 0) return;
      const response = await stopTransformation(
        payload,
        table.title === 'Jobs'
          ? 'JCL'
          : table.title === 'Driver Programs'
            ? 'NON_CICS'
            : 'CICS',
      );

      if (response.status === 'SUCCESS') {
        dispatch(setArtifactStatusObject(object));
        setShowBulkCancelConfirmation(false);
        const newProgress: Record<string, number> = {};
        Object.keys(object).forEach(obj => {
          newProgress[obj] = 0;
        });
        dispatch(updateTransformationProgress(newProgress));
      } else {
        console.log('Failed to start the transform process');
        throw new Error('Failed to stop the transform process');
      }
    } catch (err: any) {
      console.log('Error:', err);
      Swal.fire({
        title: 'Error',
        text:
          err?.response?.data?.message ||
          err?.message ||
          'Failed to stop the transform process',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
  };

  const handleTransformProfileChange = (name: string, profile: string) => {
    dispatch(setArtifactTransformProfile({ key: name, value: profile }));
  };

  const handleDownloadOptionChange = (name: string, profile: string) => {
    dispatch(setArtifactDownloadAsService({ key: name, value: profile }));
  };

  const handleDownLoadOptionSelectAll = (checked: boolean) => {
    const status = { ...artifactDownloadAsService };
    table.rows
      .filter(x =>
        [EArtifactStatus.DEFAULT, '', undefined].includes(
          artifactStatus[x.name],
        ),
      )
      .forEach(x => {
        status[x.name] = checked ? 'yes' : 'no';
      });
    dispatch(setArtifactDownloadAsServiceObject(status));
  };

  const handleToggleChange = async (name: string) => {
    setLoading(prev => ({ ...prev, [name]: true }));
    try {
      const isService = artifactDownloadAsService[name] === 'yes';
      const response = await startTransformation(
        [{ name, service: isService }],
        table.title === 'Jobs'
          ? 'JCL'
          : table.title === 'Driver Programs'
            ? 'NON_CICS'
            : 'CICS',
      );

      if (response?.status === 'SUCCESS') {
        console.log('Successfully started the transform process');
        dispatch(
          setArtifactStatus({
            key: name,
            status: EArtifactStatus.INPROGRESS,
          }),
        );
      } else {
        console.log('Failed to start the transform process');
        throw new Error('Failed to start the transform process');
      }
    } catch (err: any) {
      console.log('Error:', err);
      Swal.fire({
        title: 'Error',
        text:
          err?.response?.data?.message ||
          err?.message ||
          'Failed to start the transform process',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    } finally {
      setLoading(prev => ({ ...prev, [name]: false }));
    }
  };

  const onDownloadCode = async (name: string) => {
    try {
      setDownloading(prev => ({ ...prev, [name]: true }));
      await downloadCode(name);
    } finally {
      setDownloading(prev => ({ ...prev, [name]: false }));
    }
  };

  async function handleDownloadLogs(name: string) {
    await downloadErrorLog(name);
  }

  return (
    <div key={table.title}>
      <div
        className="pt-4 ml-0 flex items-center justify-between bg-gray-100 p-4 rounded-md shadow-md"
        onClick={toggleCollapse}
      >
        <h2 className="text-2xl font-semibold text-gray-800">{table.title}</h2>
        <button className="ml-2 text-gray-600 hover:text-gray-800 transition-colors duration-200">
          {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      {showCancelConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h2 className="text-xl font-semibold mb-4">Confirm Cancellation</h2>
            <p className="mb-4">
              Are you sure you want to cancel the transformation for{' '}
              {showCancelConfirmation}?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCancelConfirmation(null)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                No
              </button>
              <button
                onClick={() => handleCancel(showCancelConfirmation)}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {showBulkCancelConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Confirm Bulk Cancellation
            </h2>
            <p className="mb-4">
              Are you sure you want to cancel all artifact transformation which
              are in progress?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowBulkCancelConfirmation(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                No
              </button>
              <button
                onClick={confirmBulkCancel}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {!isCollapsed && (
        <>
          <div className="flex items-center justify-between mb-4 mt-4 ml-6">
            <div className="flex flex-row gap-2">
              <input
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="flex-1 rounded-md border border-input px-3 py-2 text-sm max-w-[20rem]"
              />
              <select
                value={filterOption}
                onChange={handleFilterChange}
                className="flex-none rounded-md border border-input px-3 py-2 text-sm"
                style={{ minWidth: '200px' }}
              >
                <option value="All">All</option>
                <option value="Transformation in progress">
                  Transformation in progress
                </option>
                <option value="Transformation finished">
                  Transformation finished
                </option>
                <option value="Transformation failed">
                  Transformation failed
                </option>
              </select>
            </div>
            <div className="flex flex-row gap-2 ml-auto mr-4">
              {ButtonHelpTexts.map(({ title, helpText }) => {
                const isTransformation = title === 'Transform All Artifacts';
                const handleClick = isTransformation
                  ? handleBulkTransform
                  : handleBulkCancel;
                const buttonClass = isTransformation
                  ? 'px-3 py-2 bg-blue-500 text-white rounded-md text-sm'
                  : 'px-3 py-2 bg-red-500 text-white rounded-md text-sm';

                return (
                  <div key={title} className="mb-4 flex items-center space-x-2">
                    <HelpTextCallout calloutText={helpText} />
                    <button
                      disabled={!isProjectLead}
                      style={{ opacity: isProjectLead ? 1 : 0.5 }}
                      onClick={handleClick}
                      className={buttonClass}
                    >
                      {title}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <hr />
          <div className="p-6">
            <div className="relative w-full overflow-auto">
              <table className="w-full caption-bottom text-sm">
                <thead className="[&amp;_tr]:border-b ">
                  <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                    {columnHelpTexts.map(({ title, helpText }, index) => (
                      <th
                        key={title}
                        className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]"
                      >
                        <div className="flex items-center">
                          {table.id === 'cics' && index === 1 ? (
                            <>
                              <Checkbox
                                style={{
                                  transform: 'scale(1.2)',
                                  marginRight: '.5rem',
                                }}
                                checked={selectAllOption}
                                onChange={e => {
                                  setSelectAllOption(e.target.checked);
                                  handleDownLoadOptionSelectAll(
                                    e.target.checked,
                                  );
                                }}
                              />
                              <span>Download as a Service</span>
                              <HelpTextCallout calloutText={helpText} />
                            </>
                          ) : (
                            <>
                              {title}
                              <HelpTextCallout calloutText={helpText} />
                            </>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="[&amp;_tr:last-child]:border-0">
                  {table.rows
                    .filter(row => filterRows(row.name))
                    .map(row => {
                      const isTransformationFailed =
                        transformationStatus?.[row.name]?.PACKAGING ===
                          'FAILED' ||
                        transformationStatus?.[row.name]
                          ?.CODE_TRANSFORMATION === 'FAILED' ||
                        transformationStatus?.[row.name]?.COMPILATION ===
                          'FAILED' ||
                        transformationStatus?.[row.name]?.CODE_GENERATION ===
                          'FAILED';
                      return (
                        <React.Fragment key={row.name}>
                          <tr
                            className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted ${!['', EArtifactStatus.DEFAULT, undefined].includes(artifactStatus[row.name]) ? 'bg-[rgba(128,128,128,.1)]' : ''}`}
                          >
                            <td className="p-4 align-middle font-medium">
                              {row.name}
                            </td>
                            {table.id === 'cics' && (
                              <td className="p-4 align-middle w-[150px]">
                                <div className="ml-[3.8rem]">
                                  <Checkbox
                                    style={{ transform: 'scale(1.2)' }}
                                    checked={
                                      artifactDownloadAsService[row.name] ===
                                      'yes'
                                    }
                                    onChange={e =>
                                      handleDownloadOptionChange(
                                        row.name,
                                        e.target.checked ? 'yes' : 'no',
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            )}
                            <td className="p-4 align-middle w-[250px]">
                              <button
                                onClick={() =>
                                  showCallChainModal(row.name, table.title)
                                }
                                className="px-3 py-1 bg-blue-500 text-white rounded-md text-sm"
                              >
                                View Callchain
                              </button>
                            </td>
                            <td className="p-4 align-middle w-[200px]">
                              {!loading[row.name] && (
                                <label className="switch ml-[2rem]">
                                  <input
                                    type="checkbox"
                                    style={{
                                      opacity:
                                        !isProjectLead ||
                                        ![
                                          '',
                                          EArtifactStatus.DEFAULT,
                                          undefined,
                                        ].includes(artifactStatus[row.name])
                                          ? 0.5
                                          : 0.1,
                                    }}
                                    disabled={
                                      !isProjectLead ||
                                      ![
                                        '',
                                        EArtifactStatus.DEFAULT,
                                        undefined,
                                      ].includes(artifactStatus[row.name])
                                    }
                                    checked={
                                      ![
                                        '',
                                        EArtifactStatus.DEFAULT,
                                        undefined,
                                      ].includes(artifactStatus[row.name])
                                    }
                                    onChange={() =>
                                      handleToggleChange(row.name)
                                    }
                                  />
                                  <span
                                    style={{
                                      opacity:
                                        ![
                                          '',
                                          EArtifactStatus.DEFAULT,
                                          undefined,
                                        ].includes(artifactStatus[row.name]) ||
                                        licenseStatusDetails?.licenseStatus ===
                                          'FAILED'
                                          ? 0.5
                                          : 1,
                                    }}
                                    className="slider round"
                                  ></span>
                                </label>
                              )}
                              {loading[row.name] && (
                                <span className="ml-2">Loading...</span>
                              )}
                            </td>
                          </tr>
                          {!['', EArtifactStatus.DEFAULT, undefined].includes(
                            artifactStatus[row.name],
                          ) && (
                            <tr className="bg-gray-100">
                              <td colSpan={4} className="p-2">
                                <div className="progress-widget">
                                  <div className="progress-bar">
                                    <div className="step">
                                      <div className="step-label">
                                        Code Generation
                                      </div>
                                      <div
                                        className="progress"
                                        style={{
                                          background:
                                            transformationStatus?.[row.name]
                                              ?.CODE_GENERATION === 'FAILED'
                                              ? 'red'
                                              : '#4caf50',
                                          width: transformationProgress[
                                            row.name
                                          ]
                                            ? `${Math.min(transformationProgress[row.name] * 4, 100)}%`
                                            : 0,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="step">
                                      <div className="step-label">
                                        Code Compilation
                                      </div>
                                      <div
                                        className="progress"
                                        style={{
                                          background:
                                            transformationStatus?.[row.name]
                                              ?.COMPILATION === 'FAILED'
                                              ? 'red'
                                              : '#4caf50',
                                          width: transformationProgress[
                                            row.name
                                          ]
                                            ? `${Math.min(Math.max(transformationProgress[row.name] - 25, 0) * 4, 100)}%`
                                            : 0,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="step">
                                      <div className="step-label">
                                        Code Aggregation
                                      </div>
                                      <div
                                        className="progress"
                                        style={{
                                          background:
                                            transformationStatus?.[row.name]
                                              ?.CODE_TRANSFORMATION === 'FAILED'
                                              ? 'red'
                                              : '#4caf50',
                                          width: transformationProgress[
                                            row.name
                                          ]
                                            ? `${Math.min(Math.max(transformationProgress[row.name] - 50, 0) * 4, 100)}%`
                                            : 0,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="step">
                                      <div className="step-label">
                                        Code Bundling
                                      </div>
                                      <div
                                        className="progress"
                                        style={{
                                          background:
                                            transformationStatus?.[row.name]
                                              ?.PACKAGING === 'FAILED'
                                              ? 'red'
                                              : '#4caf50',
                                          width: transformationProgress[
                                            row.name
                                          ]
                                            ? `${Math.min(Math.max(transformationProgress[row.name] - 75, 0) * 4, 100)}%`
                                            : 0,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="progress-info pt-2">
                                    {isTransformationFailed ? (
                                      <span>Transformation Failed.</span>
                                    ) : (
                                      <span>
                                        {transformationProgress[row.name] || 0}%
                                        Complete
                                      </span>
                                    )}

                                    <div className="flex flex-row items-center gap-2">
                                      {artifactStatus[row.name] !==
                                        EArtifactStatus.SUCCESS && (
                                        <div>
                                          {isTransformationFailed ? (
                                            <button
                                              className="flex items-center px-3 py-1 bg-red-500 text-white rounded-md text-sm hover:bg-red-600 transition-colors duration-200"
                                              onClick={() =>
                                                handleDownloadLogs(row.name)
                                              }
                                            >
                                              {loading[row.name]
                                                ? 'Loading...'
                                                : 'Download Log'}
                                            </button>
                                          ) : (
                                            <button
                                              className="flex items-center px-3 py-1 bg-red-500 text-white rounded-md text-sm hover:bg-red-600 transition-colors duration-200"
                                              onClick={() =>
                                                confirmCancel(row.name)
                                              }
                                            >
                                              {loading[row.name]
                                                ? 'Loading...'
                                                : 'Cancel'}
                                            </button>
                                          )}
                                        </div>
                                      )}
                                      {/* {loading[row.name] && (
                                      <span>Loading...</span>
                                    )} */}
                                      {artifactStatus[row.name] ===
                                        EArtifactStatus.SUCCESS && (
                                        <button
                                          disabled={downloading[row.name]}
                                          className="flex items-center px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors duration-200"
                                          onClick={() =>
                                            onDownloadCode(row.name)
                                          }
                                        >
                                          <FaDownload className="mr-2" />
                                          {downloading[row.name]
                                            ? 'Downloading...'
                                            : 'Download Code'}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubTable;
