import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export async function updateProjectDetails(
  projectId: number,
  project: any,
  dependencies: any[],
) {
  const payload = {
    projectName: project.projectName,
    startDate: project.startDate,
    endDate: project.endDate,
    projectType: project.projectType,
    projectMembers:
      project.teamMembers.map(member => ({
        memberType: member.id === project.projectLead?.id ? 'LEAD' : 'MEMBER',
        userId: member.id,
      })) || [],
    dependentProjects: dependencies || [],
  };

  const response = await axios.put(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/${projectId}`,
    payload,
  );
  return response.data;
}

export async function getProjectDetails(projectId: number) {
  const response = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project/${projectId}`,
  );
  return response.data.data;
}
