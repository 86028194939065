import { ITransformApplicationTypeProps } from './transform-app-type-only.types';
import { getSearchParameter } from 'src/utils/url.utils';
import { useGetProjectDetailQuery } from 'src/redux/api-slice';
import { FaSpinner } from 'react-icons/fa';

const TransformApplicationTypeOnly = ({ children }: ITransformApplicationTypeProps) => {
  const projectId = getSearchParameter('projectId');
  const { data, isLoading } = useGetProjectDetailQuery(
    { projectId },
    { skip: !projectId },
  );

  if (data?.projectType?.toLowerCase() === 'smart_doc') {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center space-x-2">
        <FaSpinner className="animate-spin text-gray-600" size={24} />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default TransformApplicationTypeOnly;
