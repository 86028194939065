import React, { LegacyRef, useId } from 'react';
import GoogleReCAPTCHA from 'react-google-recaptcha';

const ReCAPTCHA = React.forwardRef(
  (
    { onChange }: { onChange: (token: string | null) => void },
    ref?: LegacyRef<GoogleReCAPTCHA>,
  ) => {
    const id = useId();
    return (
      <div id={id}>
        <GoogleReCAPTCHA
          ref={ref}
          sitekey={process.env.REACT_APP_SITE_KEY as string}
          onChange={onChange}
        />
      </div>
    );
  },
);

export default ReCAPTCHA;
