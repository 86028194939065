import axios from 'axios';
import { EAppIntent } from '../../redux/app-global/app-global.types';
import { externalConfig } from '../../utils/misc.utils';

const mockResp = {
  status: 'SUCCESS',
  data: [
    {
      id: '014',
      message: 'We convert Cobol Application to Java ....',
      type: 'prompt-response',
      intent: null,
    },
    {
      id: '003',
      message: 'Is CloudFrame a Cloud Native Solution?',
      type: 'prompt-request',
      intent: null,
    },
    {
      id: '004',
      message:
        'Can you explain the process for uploading artifacts for analysis?',
      type: 'prompt-request',
      intent: null,
    },
    {
      id: '005',
      message: "Let's get to know each other",
      type: 'prompt-request',
      intent: null,
    },
    {
      id: '010',
      message: 'Go Back',
      type: 'prompt-request',
      intent: null,
    },
  ],
};

export const fetchBotResponse = async (
  message: string,
  projectId: string,
): Promise<any> => {
  const response = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-openai-service/cf/ai/chat/${projectId}`,
    {
      message: message,
    },
  );
  if (response.status !== 200) {
    throw new Error('Request failed');
  }
  return response.data;
};
