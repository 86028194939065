import axios from 'axios';
import {
  downloadBase64FromResponse,
  externalConfig,
} from '../../../utils/misc.utils';
import { SESSION_HEADER } from '../../../constants/app.constant';
import { getSearchParameter } from 'src/utils/url.utils';
import Swal from 'sweetalert2';

export const fetchProjectTransformationDetails = async (projectId: string) => {
  const response = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/transform/status/${projectId}`,
  );

  return response.data.data;
};

export const downloadLog = async () => {
  try {
    const res = await axios.get(
      externalConfig.REACT_APP_GATEWAY_URL +
        '/cf-scanner-api/download/scan-log',
    );
    downloadBase64FromResponse(res);
  } catch (err) {
    console.log(err);
  }
};

export const downloadCode = async (name: string) => {
  try {
    const projectId = getSearchParameter('projectId');
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/download/generated-code/${projectId}/${name}`,
      {
        responseType: 'arraybuffer',
      },
    );

    const blob = new Blob([response.data]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name + '.zip';
    link.click();
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
  }
};

export const startTransformation = async (
  names: { name: string; service: boolean }[],
  type: string,
) => {
  const projectId = getSearchParameter('projectId');
  const res = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/transform/start/${projectId}`,
    {
      artifactsInfo: names,
      type,
    },
  );
  return res.data;
};

export const stopTransformation = async (
  names: { name: string; service: boolean }[],
  type: string,
) => {
  const projectId = getSearchParameter('projectId');
  const res = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/transform/stop/${projectId}`,
    {
      artifactsInfo: names,
      type,
    },
  );
  return res.data;
};

export const downloadErrorLog = async (name: string) => {
  try {
    const projectId = getSearchParameter('projectId');
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/download/scan-log/${projectId}/${name}`,
      { responseType: 'blob' },
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err);
    Swal.fire({
      title: 'Logs',
      text: 'There are no logs available for this transformation',
      icon: 'info',
      showConfirmButton: true,
      confirmButtonText: 'Okay',
    });
  }
};
