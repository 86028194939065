import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ICreateNewUserProps } from './create-new-user.types';
import Swal from 'sweetalert2';
import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';
import {
  useCreateNewProjectMutation,
  useCreateNewUserMutation,
  useEditUserDetailsMutation,
  useGetUsersQuery,
} from 'src/redux/api-slice';

const CreateNewUserModal = ({
  onClose,
  onSubmit,
  user,
}: ICreateNewUserProps) => {
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { data, isLoading, refetch } = useGetUsersQuery();
  const [editUserDetails] = useEditUserDetailsMutation();
  const [createNewUser] = useCreateNewUserMutation();

  useEffect(() => {
    if (user) {
      setName(user.name || '');
      setUserName(user.userName || '');
      setPassword(user.password || '');
      setRole(user.role || '');
      setEmail(user.email || '');
    }
  }, [user]);

  function validateForm() {
    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PASSWORD_REGEX =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[A-Za-z\d\W]{8,}$/;
    if (name?.trim().length === 0) {
      return "Name can't be empty";
    }
    if (userName?.trim().length === 0) {
      return "Username can't be empty";
    }
    if (/\s/.test(userName)) {
      return "Username can't contain spaces";
    }
    if (data?.some((u: any) => u.userName === userName && u.id !== user?.id)) {
      return 'Username already exists';
    }
    if (email?.trim().length === 0) {
      return "Email can't be empty";
    }
    if (!EMAIL_REGEX.test(email)) {
      return 'Invalid email';
    }
    if (!user && password?.trim().length === 0) {
      return "Password can't be empty";
    }
    if ((!user || password) && !PASSWORD_REGEX.test(password)) {
      return 'Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.';
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const error = validateForm();
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Form is invalid ',
        footer: error,
      });
      return;
    }
    const userData = {
      name,
      userName,
      role,
      email,
      ...(password && { password }),
    };

    try {
      if (user?.id) {
        const result = await editUserDetails({
          userId: user.id,
          userData,
        }).unwrap();

        if (result?.status === 'SUCCESS') {
          Swal.fire({
            icon: 'success',
            title: 'User updated successfully',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result?.message || 'Something went wrong!',
          });
          return;
        }
      } else {
        const result = await createNewUser({ userData }).unwrap();

        if (result?.status !== 'SUCCESS') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result?.message || 'Something went wrong!',
          });
          return;
        }

        Swal.fire({
          icon: 'success',
          title: 'User created successfully',
        });
      }

      onSubmit({
        ...userData,
        id: user?.id,
        password: userData.password ?? '',
      });
      refetch();
    } catch (error: any) {
      console.log('ERROR:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: error.message,
      });
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return createPortal(
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        inset: 0,
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: '10%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          overflow: 'auto',
          minWidth: '35rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: window.innerHeight * 0.95,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          padding: '2rem',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        <div>
          <h2
            style={{
              fontSize: '1.25rem',
              fontWeight: '500',
              color: '#374151',
            }}
          >
            {user?.id ? 'Edit User' : 'Create New User'}
          </h2>
          <button
            style={{
              border: '0',
              color: 'black',
              float: 'right',
              fontSize: '5rem',
              lineHeight: 'none',
              fontWeight: 'medium',
              outline: 'none',
              transition: 'all',
              cursor: 'pointer',
              position: 'absolute',
              top: 0,
              right: '1rem',
              background: 'transparent',
              borderWidth: 0,
            }}
            onClick={onClose}
          >
            <span
              style={{
                color: 'black',
                height: '1.5rem',
                width: '1.5rem',
                fontSize: '2.5rem',
                display: 'block',
                outline: 'none',
              }}
            >
              ×
            </span>
          </button>
          <hr style={{ margin: '.5rem 0 1rem' }} />
          <p
            style={{
              marginBottom: '1rem',
              lineHeight: '1.5',
              color: '#374151',
            }}
          >
            {user?.id
              ? 'Fill out the form to edit a user account.'
              : 'Fill out the form to create a new user account.'}
          </p>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                lineHeight: '1.5',
                color: '#374151',
              }}
            >
              <div
                style={{
                  marginBottom: '.5rem',
                  lineHeight: '1.5',
                  color: '#374151',
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Name"
                  onChange={e => setName(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    height: 'unset',
                    fontWeight: '400',
                  }}
                />
              </div>
              <div
                style={{
                  marginBottom: '.5rem',
                }}
              >
                <label
                  htmlFor="userName"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  User Name *
                </label>
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  value={userName}
                  placeholder="User Name"
                  onChange={e => setUserName(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    height: 'unset',
                    fontWeight: '400',
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '.5rem',
                }}
              >
                <label
                  htmlFor="role"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  value={role}
                  onChange={e => setRole(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    height: 'unset',
                    fontWeight: '400',
                  }}
                >
                  <option value="">Select Role</option>
                  <option value="ADMIN">Admin</option>
                  <option value="MEMBER">Member</option>
                </select>
              </div>
              <div
                style={{
                  marginTop: '.5rem',
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                    color: '#4a5568',
                    marginBottom: 0,
                  }}
                >
                  Email *
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="User Email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '0',
                    border: '1px solid #ccc',
                    backgroundColor: '#fff',
                    padding: '0.5rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    outline: 'none',
                    transition: 'color 0.2s ease-in-out',
                    height: 'unset',
                    fontWeight: '400',
                  }}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: '.5rem',
              }}
            >
              <label
                htmlFor="password"
                style={{
                  fontSize: '0.875rem',
                  lineHeight: '1.75',
                  color: '#4a5568',
                  marginBottom: 0,
                }}
              >
                Password *
              </label>
              <input
                type="password"
                id="password"
                placeholder={user ? 'Update Password' : 'Password'}
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                  width: '100%',
                  borderRadius: '0',
                  border: '1px solid #ccc',
                  backgroundColor: '#fff',
                  padding: '0.5rem 0.75rem',
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  color: '#333',
                  outline: 'none',
                  transition: 'color 0.2s ease-in-out',
                  height: 'unset',
                  fontWeight: '400',
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                marginTop: '2rem',
              }}
            >
              <button
                type="submit"
                style={{
                  borderRadius: 4,
                  backgroundColor: 'rgb(19,98,223)',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                  fontSize: '1.1rem',
                  color: '#fff',
                  borderWidth: 0,
                }}
              >
                Submit
              </button>
              <button
                style={{
                  background: 'transparent',
                  color: 'black',
                  borderWidth: 0,
                }}
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default CreateNewUserModal;
