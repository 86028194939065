import { useEffect, useState } from 'react';
import { FiArrowLeft, FiLogOut, FiPlus } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setActiveModal, setUserLoginDetails } from 'src/redux/app-global';
import { useUser } from 'src/hooks/useUser';
import { ROUTES } from 'src/App.navigation';
import { SharedService } from 'src/services/shared-service';
import Swal from 'sweetalert2';
import AdminOnly from 'src/components/admin-only/admin-only';
import ProjectCreation from '../project-creation/create-project';
import { EAppModal } from 'src/redux/app-global/app-global.types';
import UserOnly from 'src/components/user-only/user-only';
import { getSearchParameter } from 'src/utils/url.utils';
import { useChatBotUtils } from 'src/hooks/useChatBotUtils';
import { FiHelpCircle } from 'react-icons/fi';
import dayjs from 'dayjs';
import {
  useGetKnowledgeBaseProtectedUrlQuery,
  useLazyGetProjectDetailQuery,
} from 'src/redux/api-slice';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button';
import { Badge } from 'src/components/ui/badge';
import { ChevronDown, Layers, Settings } from 'lucide-react';

const Header = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const isOnPrem = license?.Environment === 'ONPREM';
  const [showBackButton, setShowBackButton] = useState(false);
  const user = useUser();
  const dispatch = useDispatch();
  const activeModal = useSelector(
    (state: RootState) => state.appGlobal.activeModal,
  );
  const { logout } = useChatBotUtils();
  const [projectName, setProjectName] = useState<string>('');
  const [projectType, setProjectType] = useState<string>('');
  const [projectId, setProjectId] = useState('');
  const { data, isFetching, refetch } = useGetKnowledgeBaseProtectedUrlQuery(
    undefined,
    {
      pollingInterval: 2700000,
      skip: !user?.userId,
    },
  );
  const [projectOverViewQuery] = useLazyGetProjectDetailQuery();

  const isLicenseExpired = license?.licensedTill
    ? dayjs(license?.licensedTill, 'MM/DD/YYYY').isBefore(dayjs(), 'day')
    : false;

  const handleOpenCreateProjectModal = () => {
    dispatch(setActiveModal(EAppModal.CREATE_PROJECT));
  };

  const handleCloseModal = () => {
    dispatch(setActiveModal(null));
  };

  useEffect(() => {
    (async () => {
      if (showBackButton) {
        const projectId = getSearchParameter('projectId');
        if (projectId) {
          const project = await projectOverViewQuery({ projectId });
          setProjectName(project.data.projectName);
          setProjectType(project.data.projectType);
          setProjectId(projectId);
        }
      } else {
        setProjectName('');
      }
    })();
  }, [showBackButton]);

  useEffect(() => {
    const handleHomeEvent = () => {
      setShowBackButton(true);
    };
    const handleAppEvent = () => {
      setShowBackButton(false);
    };
    window.addEventListener('Home', handleHomeEvent);
    window.addEventListener('App', handleAppEvent);

    return () => {
      window.removeEventListener('Home', handleHomeEvent);
      window.removeEventListener('App', handleAppEvent);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tooltip = document.getElementById('user-tooltip');
      if (tooltip && !tooltip.contains(event.target as Node)) {
        setTooltipVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (!isOnPrem) {
    return null;
  }
  const handleLogOut = () => {
    logout();
    dispatch(setUserLoginDetails(null));
    sessionStorage.removeItem('accessToken');
    SharedService.router?.navigate(ROUTES.LOGIN);
  };

  const handleOpenDocumentationPage = async () => {
    try {
      const response = await refetch();

      if (!response.data?.redirectUrl) {
        throw new Error('invalid url');
      }

      window.open(response.data?.redirectUrl, '_blank');
    } catch (err) {
      console.log('err', err);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Unable to open documentation page! Please try again later.',
      });
    }
  };

  const checkServiceStatusCta = () => (
    <div className="flex flex-row items-center mr-4 mt-4">
      <button
        onClick={() => {
          dispatch(setActiveModal(EAppModal.APP_VERSION));
        }}
        className="flex items-center text-sm font-semibold text-gray-700 bg-transparent border border-blue-500 rounded-full py-3 px-4 transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100 active:bg-gray-200 active:scale-95 shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 text-white hover:text-black"
      >
        Service Health Status
      </button>
    </div>
  );

  return (
    <div className="fixed top-0 left-0 w-full px-4 py-3 rounded-md z-50">
      <div className="flex flex-row justify-between items-center">
        <div className="text-darkGrey font-semibold text-xl flex flex-row item-center relative">
          <div
            className="cursor-pointer flex flex-row items-center"
            onClick={() => {
              if (SharedService.router) {
                SharedService.router?.navigate(ROUTES.DASHBOARD);
              } else {
                window.location.href = './dashboard';
              }
              window.history.pushState({}, '', './dashboard');
              window.dispatchEvent(new PopStateEvent('popstate'));
            }}
          >
            <img
              src={require('../../assets/images/png/Logo-2-100.jpg')}
              alt="CloudFrame"
              style={{
                width: '180px',
                height: 'auto',
                cursor: 'pointer',
              }}
            />
          </div>

          {showBackButton && projectName && (
            <>
              <div className="mx-4 h-8 w-px bg-gray-400 mt-6" />
              <div className="mt-0 ml-4">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="h-auto py-1.5 px-3">
                      <div className="flex items-center gap-2">
                        <Layers className="h-4 w-4 text-primary" />
                        <div className="flex flex-col items-start">
                          <p className="font-medium text-xs mr-2 text-black mb-1">
                            Current Application:
                          </p>
                          <div className="h-[1px] w-full bg-gray-400 mb-1" />
                          <div className="flex items-center gap-2 pb-1">
                            <span className="font-semibold">
                              Name:{projectName}
                            </span>
                            <Badge
                              variant="secondary"
                              className="text-xs font-normal"
                            >
                              ID: {projectId.substring(0, 8)}
                            </Badge>
                          </div>
                          <span className="text-xs text-muted-foreground">
                            {projectType} Application
                          </span>
                        </div>
                        <ChevronDown className="h-4 w-4 opacity-50 ml-2" />
                      </div>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-64">
                    <DropdownMenuLabel className="font-normal">
                      <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">
                          {projectName}
                        </p>
                        <p className="text-xs leading-none text-muted-foreground">
                          {projectType}
                        </p>
                      </div>
                    </DropdownMenuLabel>

                    <DropdownMenuSeparator />
                    <div className="p-2">
                      <div className="text-sm font-medium">
                        Application Details
                      </div>
                      <div className="mt-2 grid gap-2 text-sm">
                        <div className="flex justify-between">
                          <span className="text-muted-foreground text-sm">
                            ID:
                          </span>
                          <span className="font-medium">
                            {projectId.substring(0, 8)}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-muted-foreground text-sm">
                            Name:
                          </span>
                          <span className="font-medium">{projectName}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-muted-foreground text-sm">
                            Type:
                          </span>
                          <span className="font-medium">{projectType}</span>
                        </div>
                      </div>
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </>
          )}
        </div>
        {!user?.userId && checkServiceStatusCta()}
        {user?.userId && (
          <div className="flex flex-row items-center">
            {license.isLicenseValid === 'true' &&
              license.isLicenseActivate === 'true' &&
              !isLicenseExpired && (
                <AdminOnly>
                  <div className="flex flex-row items-center mr-4 mt-4">
                    <button
                      onClick={handleOpenCreateProjectModal}
                      className="flex items-center text-sm font-semibold text-blue-700 bg-white border border-blue-300 rounded-full py-3 px-10 transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100 active:bg-gray-200 active:scale-95 shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-400 focus:ring-opacity-50"
                    >
                      <FiPlus className="mr-2 text-lg" />
                      Create New Application
                    </button>

                    <div className="mx-4 ml-6 h-8 w-px bg-gray-400" />
                  </div>
                </AdminOnly>
              )}
            <div className="flex flex-row items-center mr-4 mt-4">
              <button
                onClick={handleOpenDocumentationPage}
                className="flex items-center text-sm font-semibold text-white bg-transparent border border-blue-500 rounded-full py-3 px-4 transition-all duration-300 ease-in-out transform hover:scale-105 active:bg-blue-200 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 -ml-2 hover:bg-gray-100 hover:text-black"
              >
                <FiHelpCircle className="mr-2 text-lg" />
                {isFetching ? 'Loading...' : ' Help & Documentation'}
              </button>

              {/* <div className="mx-4 h-8 w-px bg-gray-400" /> */}
            </div>
            {showBackButton && (
              <UserOnly>
                <div className="flex flex-row items-center mr-4 mt-4">
                  <button
                    onClick={() => {
                      if (SharedService.router) {
                        SharedService.router?.navigate(
                          ROUTES.PROJECT_OVERVIEW +
                            `?projectId=${getSearchParameter('projectId')}`,
                        );
                      } else {
                        window.location.href = './project-overview';
                      }
                      window.history.pushState(
                        {},
                        '',
                        ROUTES.PROJECT_OVERVIEW +
                          `?projectId=${getSearchParameter('projectId')}`,
                      );
                      window.dispatchEvent(new PopStateEvent('popstate'));
                    }}
                    className="flex items-center text-sm font-semibold text-gray-700 bg-transparent border border-blue-500 rounded-full py-3 px-4 transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100 active:bg-gray-200 active:scale-95 shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 text-white hover:text-black"
                  >
                    <FiArrowLeft className="mr-2 text-lg" /> Back to Application
                    Overview
                  </button>
                  <div className="mx-4 h-8 w-px bg-gray-400" />
                </div>
              </UserOnly>
            )}

            {checkServiceStatusCta()}
            <div className="flex flex-row items-center mr-4 mt-4">
              {/* <div className="hover:scale-110">
                <NotificationWidget />
              </div> */}
              <div
                className="relative flex items-center cursor-pointer"
                onClick={() => setTooltipVisible(!isTooltipVisible)}
                // onMouseEnter={() => setTooltipVisible(true)}
                // onMouseLeave={() => setTooltipVisible(false)}
              >
                <FaUserCircle
                  id="user-tooltip"
                  size={35}
                  className="hover:scale-110"
                  style={{ borderRadius: '50%', color: 'white' }}
                />

                {isTooltipVisible && (
                  <div className="absolute top-full right-0 sm:right-1 sm ml-1 mt-2 w-72 max-h-auto bg-white border border-gray-300 shadow-lg p-6 rounded-lg z-50">
                    <div className="flex flex-col items-center mb-4">
                      <FaUserCircle size={60} className="text-blue-600 mb-2" />

                      <p className="font-semibold text-md text-gray-800">
                        {user?.userId}
                      </p>
                      <p className="text-md text-gray-600">{user.role}</p>
                    </div>
                    <div className="flex flex-col">
                      {/* <div className="flex items-center cursor-pointer hover:bg-gray-100 rounded p-3 transition-colors duration-300 ease-in-out">
                        <FiSettings size={24} className="mr-4 text-gray-600" />
                        <span className="text-md text-gray-800">Settings</span>
                      </div> */}
                      <div
                        className="flex items-center cursor-pointer hover:bg-gray-100 rounded p-3 transition-colors duration-300 ease-in-out"
                        onClick={handleLogOut}
                      >
                        <FiLogOut size={24} className="mr-4 text-gray-600" />
                        <span className="text-md text-gray-800">Logout</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ProjectCreation
        isOpen={activeModal === EAppModal.CREATE_PROJECT}
        onClose={handleCloseModal}
        selectedProject={null}
        isEditing={false}
      />
    </div>
  );
};

export default Header;
