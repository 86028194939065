import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContainerCard from '../../../components/card/container-card';
import {
  setActiveModal,
  setCurrentIntent,
  setScannerErrorMessage,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
} from '../../../redux/app-global/app-global.types';
import { RootState } from '../../../redux/store';
import {
  downloadExtractJcl,
  externalConfig,
  isValidDataset,
} from '../../../utils/misc.utils';
import {
  ExtractDataSet,
  ExtractJclRequest,
  ExtractType,
} from './extract-artifact.model';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { FileDownloadIcon } from '../../../components/icons/icons';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { helpTexts } from '../../../helpTexts';
import { SharedService } from '../../../services/shared-service';
import { useGetKnowledgeBaseProtectedUrlQuery } from 'src/redux/api-slice';

export const EXTRACT_DATA_KEY = 'extract-datasets';

interface IDatasets {
  text: string;
  valid: boolean;
}

interface IDatasetElement {
  type: string;
  dataset: IDatasets;
  index: number;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onClickActionBtn: () => void;
}

const ExtractArtifactPage = () => {
  const dispatch = useDispatch();
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );
  const scanCompleted = useSelector(
    (state: RootState) => state.appGlobal.scanCompleted,
  );
  const [jclDatasets, setJclDatasets] = useState<IDatasets[]>([
    { text: '', valid: true },
  ]);
  const [procDatasets, setProcDatasets] = useState<IDatasets[]>([
    { text: '', valid: true },
  ]);
  const [cardDatasets, setCardDatasets] = useState<IDatasets[]>([
    { text: '', valid: true },
  ]);
  const [listDatasets, setListDatasets] = useState<IDatasets[]>([
    { text: '', valid: true },
  ]);
  const [metadataDatasets, setMetadataDatasets] = useState<IDatasets[]>([
    { text: '', valid: true },
  ]);
  const [ispfDatasets, setIspfDatasets] = useState<IDatasets[]>([
    { text: '', valid: true },
  ]);
  const [errorMsg, setErrorMsg] = useState('');
  const basePath = process.env.REACT_APP_BASE_PATH;
  const pageHelpTexts = helpTexts[0].EXTRACT_ARTIFACT;
  const isOnPrem = SharedService.isOnPrem;

  const { data: tokenUrl } = useGetKnowledgeBaseProtectedUrlQuery();
  const token = tokenUrl?.kbAccessToken;

  const getDatasetElement = (props: IDatasetElement) => {
    return (
      <div key={`jclDataset${props.index}`} className="flex gap-2">
        <input
          type="text"
          id={`${props.type}Dataset${props.index}`}
          name={`${props.type}Dataset${props.index}`}
          value={props.dataset.text}
          placeholder={`Enter dataset`}
          onChange={props.onChange}
          style={{
            width: '90%',
            borderRadius: '0',
            border: `1px solid ${props.dataset.valid ? '#ccc' : 'red'}`,
            backgroundColor: '#fff',
            padding: '0.5rem 0.75rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            color: `${props.dataset.valid ? '#333' : 'red'}`,
            outline: 'none',
            transition: 'color 0.2s ease-in-out',
            height: 'unset',
            fontWeight: '400',
          }}
        />
        <span
          style={{
            width: '10%',
            fontSize: '20px',
            cursor: 'pointer',
          }}
          onClick={props.onClickActionBtn}
        >
          {props.index === 0 ? '+' : '-'}
        </span>
      </div>
    );
  };

  const getDatasetCard = (
    heading: string,
    artifactType: string,
    datasetState: IDatasets[],
    datasetSetter: React.Dispatch<React.SetStateAction<IDatasets[]>>,
    callOutText: React.ReactNode,
  ) => {
    return (
      <ContainerCard
        title={heading}
        callOutText={callOutText}
        body={datasetState.map((dataset, index) =>
          getDatasetElement({
            type: artifactType,
            dataset,
            index,
            onChange: e => {
              const value = e.target.value.toUpperCase();
              const invalidMsg = isValidDataset(value, artifactType);
              setErrorMsg(invalidMsg);
              datasetSetter(
                datasetState.map((d, i) =>
                  i === index ? { text: value, valid: !invalidMsg } : d,
                ),
              );
            },
            onClickActionBtn: () => {
              const invalidMsg = isValidDataset(datasetState[0].text);
              if (index === 0) {
                if (!invalidMsg) {
                  datasetSetter(
                    [{ text: '', valid: true }].concat(datasetState),
                  );
                } else {
                  datasetSetter(
                    datasetState.map((d, i) =>
                      i === 0
                        ? { text: datasetState[0].text, valid: false }
                        : d,
                    ),
                  );
                }
              } else {
                datasetSetter(datasetState.filter((d, i) => i !== index));
              }
            },
          }),
        )}
      />
    );
  };

  const handleDownloadExtractJcl = () => {
    let downloadFor: ExtractType = ExtractType.JCL;
    const dataset: ExtractDataSet = {};
    const jcl = jclDatasets.filter(item => item.text && item.valid);
    const proc = procDatasets.filter(item => item.text && item.valid);
    const card = cardDatasets.filter(item => item.text && item.valid);
    const listing = listDatasets.filter(item => item.text && item.valid);
    const ispf = ispfDatasets.filter(item => item.text && item.valid);
    const metadata = metadataDatasets.filter(item => item.text && item.valid);

    if (jcl.length) {
      dataset.jcl = jcl.map(item => item.text);
      downloadFor = ExtractType.JCL;
    }
    if (proc.length) {
      dataset.proc = proc.map(item => item.text);
      downloadFor = ExtractType.PROC;
    }
    if (card.length) {
      dataset.cc = card.map(item => item.text);
      downloadFor = ExtractType.CC;
    }
    if (listing.length) {
      dataset.listing = listing.map(item => item.text);
      downloadFor = ExtractType.LISTING;
    }
    if (ispf.length) {
      dataset.ispf = ispf.map(item => item.text);
      downloadFor = ExtractType.ISPF;
    }
    if (metadata.length) {
      dataset.metadata = metadata.map(item => item.text);
      downloadFor = ExtractType.METADATA;
    }

    setErrorMsg('');

    const datasetCount = Object.keys(dataset).length;
    if (datasetCount === 2 && dataset.metadata && dataset.ispf) {
      downloadExtractJcl({ dataset, downloadFor });
    } else if (datasetCount > 1) {
      sessionStorage.setItem(EXTRACT_DATA_KEY, JSON.stringify(dataset));
      dispatch(setActiveModal(EAppModal.DATASET_DOWNLOAD));
    } else if (datasetCount === 1) {
      downloadExtractJcl({ dataset, downloadFor });
    } else {
      setErrorMsg('Please provide at least one dataset');
    }
  };

  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4">
        <div className="text-justify flex flex-col justify-center items-start gap-4 max-w-[75%] pb-4">
          <p className="mt-2">{currentContent?.caption}</p>
        </div>
        <h1 className="text-2xl font-semibold">{currentContent?.heading}</h1>
        <p className="max-w-[75%] text-justify">
          {currentContent?.description1}&nbsp;
          <a
            href="https://ask.cloudframe.com/instructions-to-extract-mainframe-artifacts"
            download
            className="text-cta text-justify"
            target="_blank"
            rel="noreferrer"
          >
            Artifact Extraction&nbsp;
          </a>
          {currentContent?.descriptionEnd}
        </p>
        <p className="max-w-[75%] text-justify">
          {currentContent?.description}
        </p>
        <div className="w-[75%] flex justify-between p-1">
          <div className="text-red-500 text-center">{errorMsg}</div>
          <div className="flex flex-row justfy-end gap-2">
            <HelpTextCallout calloutText={pageHelpTexts[5]?.description} />
            <div
              className="flex gap-1 text-md text-cta text-right cursor-pointer items-center"
              onClick={handleDownloadExtractJcl}
            >
              Download Extract JCL
              <FileDownloadIcon
                width="1rem"
                height="1rem"
                color="var(--cta-color)"
              />
            </div>
          </div>
        </div>

        <div
          className={`grid sm:grid-cols-2 xl:grid-cols-3 gap-2 mt-2 mb-2 max-w-[75%]`}
        >
          {getDatasetCard(
            'JCL',
            'JCL',
            jclDatasets,
            setJclDatasets,
            pageHelpTexts[0]?.description,
          )}
          {getDatasetCard(
            'JCL Procedures (PROC)',
            'procedure',
            procDatasets,
            setProcDatasets,
            pageHelpTexts[1]?.description,
          )}
          {getDatasetCard(
            'JCL Control Cards',
            'card',
            cardDatasets,
            setCardDatasets,
            pageHelpTexts[2]?.description,
          )}
          {getDatasetCard(
            'Compiler Listings',
            'listing',
            listDatasets,
            setListDatasets,
            pageHelpTexts[3]?.description,
          )}
          {(scanCompleted || isOnPrem) &&
            getDatasetCard(
              'Metadata',
              'metadata',
              metadataDatasets,
              setMetadataDatasets,
              pageHelpTexts[4]?.description,
            )}
          {(scanCompleted || isOnPrem) &&
            getDatasetCard(
              'ISPF Datasets',
              'ispf',
              ispfDatasets,
              setIspfDatasets,
              <p>
                {pageHelpTexts[8]?.description}
                <a href={`https://ask.cloudframe.com/knowledge-base/finding-ispf-libraries?token=${token}`} target='_blank'>
                  <p style={{ color: 'blue' }}>Finding ISPF Libraries.</p>
                </a>
                {pageHelpTexts[9]?.description}
              </p>,
            )}
        </div>
        <div className="flex flex-row items-center gap-2">
          <HelpTextCallout calloutText={pageHelpTexts[7].description} />
          <button
            className="mt-4 btn bg-cta hover:shadow-md text-white font-semibold py-2 px-4 rounded-full mb-7"
            onClick={() => {
              dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
            }}
          >
            Proceed to Artifact Upload &rarr;
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExtractArtifactPage;
