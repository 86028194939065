import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import SmartDocTable from './table';
import { RootState } from '../../../redux/store';
import { helpTexts } from '../../../helpTexts';
import { useGetProjectReportDataQuery } from 'src/redux/api-slice';
import { getSearchParameter } from 'src/utils/url.utils';
import { externalConfig } from 'src/utils/misc.utils';
import axios from 'axios';
import Swal from 'sweetalert2';
import SVGLoader from 'src/assets/images/svg/loader.icon';


interface SmartDocStatusArtifact {
  programData : [];
}

const SmartDoc = () => {
  const projectId = getSearchParameter('projectId');
  const {
    isLoading,
    data: reportData,
    isError,
  } = useGetProjectReportDataQuery({ projectId }, { skip: !projectId });
  
  const [data, setData] = useState<SmartDocStatusArtifact>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchChatbotStatus = async () => {
      const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/smartdoc/${projectId}`;
      setLoading(true);
      try {
        const response = await axios.get(url);
        setData(prevData => ({
          ...prevData,
          programData: response?.data.data
        }));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Server Error',
          text: 'Unable to fetch artifacts status for SmartDoc. Please contact CloudFrame Support Team for further assistance.',
          confirmButtonColor: '#f27474',
        });
      }
    }    
    fetchChatbotStatus();
  },[])

  const helpText = helpTexts[0].SMART_DOC;
  
  const programData = data?.programData || [];

  if (loading) {
    return (
      <div className='h-full flex items-center justify-center'>
        <div className="flex flex-col items-center justify-center">
          <SVGLoader width={60} height={60} />
          <p className="pb-2">Fetching SmartDoc details...</p>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <p>
        Unable to fetch SmartDoc details at the moment. Please try again later
      </p>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mx-auto min-h-[20rem] w-full mt-0">
      <div className="flex flex-col gap-4">
        <SmartDocTable
          title="SmartDoc"
          description="Generate documentation for your Cobol Programs"
          helpText={helpText}
          tables={[
            {
              title: 'Programs',
              rows: programData,
              availableProfiles: [data?.programData],
              helpTexts: helpText,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default SmartDoc;
