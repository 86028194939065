import axios from 'axios';
import localforage from 'localforage';
import {
  setCurrentIntent,
  setEmailSent,
  setIsCallChainLoaded,
  setReportData,
  setScanCompleted,
  setScanProgress,
  setTransformationProgress,
  setTransformationStatus,
} from '../../redux/app-global';
import { EAppIntent } from '../../redux/app-global/app-global.types';
import { externalConfig } from '../../utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';

export const startScan = async (dispatch: any) => {
  const projectId = getSearchParameter('projectId');
  const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/scan/execution/${projectId}`;
  return await axios
    .post(url)
    .then(async (res: any) => {
      if (res.data.status !== 'ERROR') {
        dispatch(setScanProgress(0));
        dispatch(setCurrentIntent(EAppIntent.UPLOADING_ARTIFACT));
        dispatch(setEmailSent(false));
        dispatch(setReportData(null));
        dispatch(setScanCompleted(false));
        dispatch(setIsCallChainLoaded(false));
        dispatch(setTransformationProgress({}));
        dispatch(setTransformationStatus({}));
        await localforage.removeItem('callChainArtifacts');
        return true;
        // dispatch(setUploadedArtifacts(undefined))
      } else {
        if (
          res.data.message &&
          res.data.message.startsWith('Report already generated')
        ) {
          dispatch(setCurrentIntent(EAppIntent.REPORT));
        }
        return false;
      }
    })
    .catch((err: any) => {
      console.log(err);
      if (err?.message && err?.message.startsWith('Report already generated')) {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      } else {
        alert(err?.message || 'Something went wrong. Please try again.');
      }
      return false;
    });
};

export const fetchProjectArtifactList = async (projectId: string) => {
  const response = await axios.get(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/file/validate/${projectId}`,
  );

  return response.data;
};
