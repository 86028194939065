import { useEffect, useState } from 'react';
import Table from './table';
import { useDispatch } from 'react-redux';
import { setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';
import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';
import { JobData } from './test-chevron.types';
import SVGLoader from 'src/assets/images/svg/loader.icon';
import Swal from 'sweetalert2';

const TestChevron = () => {
  const dispatch = useDispatch();
  const projectId = getSearchParameter('projectId');
  const [data, setData] = useState<JobData[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  useEffect(() => {
    const getTransformedJobData = async () => {
      const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/transform-job/${projectId}`;
      setLoading(true);
      try {
        const response = await axios.get(url);
        const jobNames = response.data?.data;
        if (!jobNames) {
          setLoading(false);
          return;
        }
        const transformedData: JobData[] = jobNames.map(jobName => ({
          jobName: jobName?.artifactName,
          executionType: false,
          validated: jobName.status || 'PENDING',
          expanded: false,
          downloadStatus: false,
          rows: [],
        }));

        setData(transformedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again later.',
          confirmButtonText: 'OK',
        });
      }
    };
    getTransformedJobData();
  }, []);
  const handleSwitchChange = index => {
    const updatedData = [...data];
    updatedData[index].executionType = !updatedData[index].executionType;
    setData(updatedData);
  };
  const handleExpandToggle = async index => {
    const updatedData = [...data];
    setTableLoading(true);
    updatedData[index].expanded = !updatedData[index].expanded;
    const jobName = updatedData[index].jobName;
    if (updatedData[index].rows.length === 0) {
      const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/transform-job/io-files/${projectId}/${jobName}`;
      try {
        const response = await axios.get(url);
        const ioData = response.data.data;
        if (response.data.status === 'ERROR') {
          setTableLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Unable to connect to server to fetch I/O data for this job. Please contact CloudFrame Support Team for further assistance`,
            confirmButtonText: 'OK',
          });
          return;
        }
        const inputRows = ioData?.inputFiles.map(file => ({
          datasetName: file.datasetName,
          recordLength: file.maxRecordLength,
          recordFormat: file.recordFormat || 'Unknown',
          fileType: 'INPUT',
          colorClass: 'text-blue-400',
          source: 'Mainframe',
        }));

        const outputRows = ioData?.outputFiles.map(file => ({
          datasetName: file.datasetName,
          recordLength: file.maxRecordLength,
          recordFormat: file.recordFormat || 'Unknown',
          fileType: 'OUTPUT',
          colorClass: 'text-green-500',
          source: 'Mainframe and Java',
        }));
        const outputCount = ioData?.outputFiles.length;
        if(outputCount === 0)
          updatedData[index].validated = 'PASS';
        updatedData[index].rows = [...inputRows, ...outputRows];
        setTableLoading(false);
      } catch (error: any) {
        setTableLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${error.message}.`,
          confirmButtonText: 'OK',
        });
      }
    }
    setData(updatedData);
  };
  const redirectPage = () => {
    dispatch(setCurrentIntent(EAppIntent.MARKETPLACE_PAGE));
  };
  return (
    <div>
      <div
        className="relative rounded-lg border bg-card text-card-foreground shadow-sm"
        data-v0-t="card"
      >
        {/* <div className="absolute top-5 right-0 bg-[#fca311] text-white font-bold text-xs py-1 px-3 transform rotate-[30deg] shadow-md">
                    Beta Version
                </div> */}
        <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
          <div className="flex flex-row">
            <h2 className="text-2xl font-bold text-white">
              Validation Dashboard
            </h2>
          </div>
          <p className="text-sm text-white">
            You can use the Validation Dashboard to export the necessary
            mainframe input and output files for running the code. After
            execution of the code, upload both your Java and mainframe output
            files if ran locally. Our comparison tool will then check the two
            files and confirm whether functional equivalence has been met.
          </p>
        </div>
        <hr />
        <div className="p-6">
          <div className="relative w-full overflow-auto">
            {loading ? (
              <SVGLoader />
            ) : data.length > 0 ? (
              <Table
                data={data}
                onSwitchChange={handleSwitchChange}
                onExpandToggle={handleExpandToggle}
                setData={setData}
                loading={tableLoading}
              />
            ) : (
              <div className="text-center p-4 text-red-600">
                Validation is applicable only for JCL artifacts. Please transform the JCL artifacts to proceed.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-4 flex gap-2 items-center justify-center">
        <p>
          Do you want try out other files? You can use our Marketplace to go
          through our tools
        </p>
        <button
          className="text-blue-500 bg-white cursor-pointer"
          onClick={redirectPage}
        >
          CloudFrame Marketplace
        </button>
      </div>
    </div>
  );
};
export default TestChevron;
