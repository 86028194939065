import { useDispatch } from 'react-redux';
import { setActiveModal } from '../../../redux/app-global';
import { useGetAppVersionQuery } from 'src/redux/api-slice';
import VersionTemplate from 'src/pages/admin/version/version.template';
import { Badge } from '../../../components/ui/badge';
import { Activity, CheckCircle2 } from 'lucide-react';

const AppVersion = () => {
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setActiveModal(null));
  }

  const { isLoading, data: version } = useGetAppVersionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  function getStatusBadge(status: boolean) {
    if (status) {
      return (
        <Badge variant="default" className="bg-green-500 hover:bg-green-600">
          <CheckCircle2 className="w-3 h-3 mr-1" />
          Running
        </Badge>
      );
    }

    return (
      <Badge variant="default" className="bg-red-500 hover:bg-red-600">
        <Activity className="w-3 h-3 mr-1" />
        Offline
      </Badge>
    );
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: window.innerHeight * 0.95,
          overflow: 'hidden',
        }}
        className="fixed overflow-auto max-w-[70rem] flex w-full flex-col border rounded-lg bg-white p-8 py-4"
      >
        <div>
          <h2 className="title-font mb-1 text-2xl font-bold text-gray-900 p-4 pl-1">
            Service Health Status
          </h2>
          <button
            className="border-0 text-black float-right text-5xl leading-none font-medium outline-none focus:outline-none transition-all hover:scale-110 absolute top-2 right-6 cursor-pointer"
            onClick={handleClose}
          >
            <span className=" text-black h-8 w-8 text-5xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
          <hr className="my-2" />
          <div
            className="overflow-auto"
            style={{ maxHeight: window.innerHeight * 0.8 }}
          >
            <VersionTemplate
              getStatusBadge={getStatusBadge}
              services={version || []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppVersion;
