import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLoginDetails } from 'src/redux/app-global';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';
import SVGLoader from 'src/assets/images/svg/loader.icon';
import setAuthHeaders from 'src/interceptors/auth.interceptor';
import { useChatBotUtils } from 'src/hooks/useChatBotUtils';
import { RootState } from 'src/redux/store';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<string>(''); //admin
  const dispatch = useDispatch();
  const [password, setPassword] = useState(''); //cloudframeadmin
  const [error, setError] = useState('');
  const license = useSelector((state: RootState) => state.appGlobal.license);

  const [rememberMe, setRememberMe] = useState(false);

  const { logout } = useChatBotUtils();

  useEffect(() => {
    const handleLogOut = () => {
      logout();
      dispatch(setUserLoginDetails(null));
      sessionStorage.removeItem('accessToken');
    };

    const rememberUserId = localStorage.getItem('userId');
    setUserId(rememberUserId || userId);

    handleLogOut();
  }, []);

  const handleUserIdChange = event => {
    setError('');
    const value = event.target.value;
    setUserId(value);
  };

  const handlePasswordChange = event => {
    setError('');
    const value = event.target.value;
    setPassword(value);
  };
  const handleLogin = () => {
    if (userId === '' || password === '') {
      setError('Please fill in all fields');
      return;
    }
    const payload = {
      userName: userId,
      password: password,
    };
    const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-user-service/auth/access/token`;
    setLoading(true);
    axios
      .post(url, payload)
      .then(res => {
        if (res.data.status === 'SUCCESS') {
          const userRole = res.data.data?.userRole?.toLowerCase();

          if (userRole !== 'admin' && userRole !== 'member') {
            setError('Incorrect Credentials');
            setLoading(false);
            return;
          }

          sessionStorage.setItem('accessToken', res.data?.data?.accessToken);
          sessionStorage.setItem('refreshToken', res.data?.data?.refreshToken);
          setAuthHeaders();

          const isValidLicense =
            license?.isLicenseValid === 'true' &&
            license?.isLicenseActivate === 'true';
          dispatch(
            setUserLoginDetails({
              userId: res.data?.data?.name,
              role: userRole === 'admin' ? 'Admin' : 'Member',
              isValidLicense,
            }),
          );

          if (rememberMe) {
            localStorage.setItem('userId', userId);
          } else {
            localStorage.removeItem('userId');
          }

          navigate(isValidLicense ? ROUTES.DASHBOARD : ROUTES.UPLOAD_LICENSE);
          setLoading(false);
        } else {
          setError('Incorrect Credentials');
          setLoading(false);
        }
      })
      .catch(err => {
        console.error(err);
        setError('Unable to connect to server');
        setLoading(false);
      });
  };

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLogin();
  };
  return (
    <div
      className="bg-whiteSmoothLight w-full flex items-center justify-center py-4"
      style={{ minHeight: 'calc(100vh - 100px)' }}
    >
      {loading ? (
        <div>
          <SVGLoader />
          <p>Logging in...</p>
        </div>
      ) : (
        <div className="w-full max-w-md mx-auto -mt-12 shadow-lg rounded-lg bg-white p-8 transform transition duration-500 hover:shadow-xl">
          <h2 className="font-bold text-3xl text-center text-gray-800 mb-6">
            Welcome Back
          </h2>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              <span className="block sm:inline">{error}</span>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Username
              </h3>
              <input
                type="text"
                placeholder="Enter your username"
                className="w-full px-4 py-2 rounded-md border-2 border-gray-300 focus:border-blue-500 focus:outline-none transition duration-300"
                value={userId}
                onChange={handleUserIdChange}
              />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Password
              </h3>
              <input
                type="password"
                placeholder="Enter your password"
                className="w-full px-4 py-2 rounded-md border-2 border-gray-300 focus:border-blue-500 focus:outline-none transition duration-300"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                className="mr-2"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <label htmlFor="rememberMe" className="text-gray-700">
                Remember Me
              </label>
            </div>
            <div>
              <button
                className="w-full py-3 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-300"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default LoginScreen;
