import {
  useGetProjectDetailQuery,
  useGetTransformationDetailsQuery,
} from 'src/redux/api-slice';
import { getSearchParameter } from 'src/utils/url.utils';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  EAppIntent,
  EArtifactStatus,
  ITransformationStatus,
  IUploadedArtifacts,
} from 'src/redux/app-global/app-global.types';
import {
  clearArtifactDownloadAsServiceObject,
  clearArtifactTransformProfile,
  clearSmartDocStatus,
  resetArtifactStatusObject,
  setArtifactStatusObject,
  setControlCardData,
  setCurrentIntent,
  setDynamicCallData,
  setIgnoredMissingArtifacts,
  setInvalidFileData,
  setIsCallChainLoaded,
  setJCLCallData,
  setMissingPrograms,
  setMissingUtilities,
  setProcData,
  setScanCompleted,
  setScanJclFailed,
  setScannerErrorMessage,
  setScanProgress,
  setSmartDocStatus,
  setTransformationProgress,
  setTransformationStatus,
  setUploadedArtifacts,
  setUploadedMissingArtifacts,
} from 'src/redux/app-global';

export const useSetProjectContext = () => {
  const projectId = getSearchParameter('projectId');
  const { data, isLoading } = useGetProjectDetailQuery(
    { projectId },
    { skip: !projectId },
  );

  const transformationDetailsQuery = useGetTransformationDetailsQuery(
    {
      projectId: projectId,
    },
    { skip: !projectId, refetchOnFocus: true, refetchOnMountOrArgChange: true },
  );

  const getCurrentStage = data => {
    if (data?.currentStage?.toLowerCase() === 'default') {
      return 'Extract';
    }

    return data?.currentStage || 'NA';
  };

  const currentStage = getCurrentStage(data);

  const dispatch = useDispatch();

  useEffect(() => {
    const setProjectStatus = () => {
      let projectIntent: EAppIntent = EAppIntent.EXTRACT_ARTIFACT;
      let isScanCompleted = false;
      if (currentStage.toLowerCase() === 'extract') {
        projectIntent = EAppIntent.EXTRACT_ARTIFACT;
      }
      if (currentStage.toLowerCase() === 'upload') {
        projectIntent = EAppIntent.ARTIFACT_UPLOAD;
      }
      if (currentStage.toLowerCase() === 'scanning') {
        projectIntent = EAppIntent.UPLOADING_ARTIFACT;
      }
      if (currentStage.toLowerCase() === 'readiness') {
        projectIntent = EAppIntent.REPORT;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'smartdoc') {
        projectIntent = EAppIntent.SMART_DOC;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'transform') {
        projectIntent = EAppIntent.TRANSFORMATION_PAGE;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'test') {
        projectIntent = EAppIntent.TEST_CHEVRON;
        isScanCompleted = true;
      }
      if (currentStage.toLowerCase() === 'deploy') {
        projectIntent = EAppIntent.DEPLOY_PAGE;
        isScanCompleted = true;
      }
      dispatch(setScanCompleted(isScanCompleted));

      dispatch(setScanJclFailed(false));
      dispatch(setIsCallChainLoaded(false));
      dispatch(setScanProgress(100));
      dispatch(setMissingPrograms([]));
      dispatch(setMissingUtilities([]));
      // dispatch(setSmartDocStatus({}));
      dispatch(clearSmartDocStatus());
      dispatch(setProcData([]));
      dispatch(setControlCardData([]));
      dispatch(setDynamicCallData([]));
      dispatch(setJCLCallData([]));
      dispatch(setScannerErrorMessage(''));
      dispatch(setCurrentIntent(projectIntent));
      dispatch(clearArtifactTransformProfile());
      dispatch(clearArtifactDownloadAsServiceObject());
      dispatch(setIgnoredMissingArtifacts(undefined));
      dispatch(setInvalidFileData({}));
      dispatch(setUploadedArtifacts(undefined));
      dispatch(setUploadedMissingArtifacts(undefined));
    };
    if (currentStage) {
      setProjectStatus();
    }
  }, [dispatch, currentStage]);

  useEffect(() => {
    const newArtifactStatus: Record<string, EArtifactStatus> = {};
    const transformationProgress: Record<string, number> = {};
    let transformationStage: Record<string, ITransformationStatus> = {};

    if (transformationDetailsQuery.data) {
      transformationStage = transformationDetailsQuery.data.statusDetails || {};
    }

    Object.entries(transformationStage).forEach(([key, value]) => {
      let statusOfKey = 0;

      if (
        value?.PACKAGING === 'PASS' &&
        value?.CODE_TRANSFORMATION === 'PASS' &&
        value?.COMPILATION === 'PASS' &&
        value?.CODE_GENERATION === 'PASS'
      ) {
        newArtifactStatus[key] = EArtifactStatus.SUCCESS;
      }

      if (
        value?.PACKAGING === 'FAILED' ||
        value?.CODE_TRANSFORMATION === 'FAILED' ||
        value?.COMPILATION === 'FAILED' ||
        value?.CODE_GENERATION === 'FAILED'
      ) {
        newArtifactStatus[key] = EArtifactStatus.ERROR;
      }

      if (['PASS', 'FAILED'].includes(value?.PACKAGING)) {
        statusOfKey = 100;
      } else if (['PASS', 'FAILED'].includes(value?.CODE_TRANSFORMATION)) {
        statusOfKey = 75;
      } else if (['PASS', 'FAILED'].includes(value?.COMPILATION)) {
        statusOfKey = 50;
      } else if (['PASS', 'FAILED'].includes(value?.CODE_GENERATION)) {
        statusOfKey = 25;
      } else {
        statusOfKey = 0;
      }

      transformationProgress[key] = statusOfKey;
    });

    dispatch(resetArtifactStatusObject(newArtifactStatus));
    dispatch(setTransformationProgress(transformationProgress));
    dispatch(setTransformationStatus(transformationStage));
  }, [transformationDetailsQuery.data, dispatch]);

  useEffect(() => {
    const setupArtifactStatus = () => {
      const artifactList: IUploadedArtifacts = {
        artifacts: {},
        missingFiles: [],
        dependentFiles: [],
        zipFileStatus: {},
        fileStatus: {},
      };
      const invalidFiles = {};

      data?.projectArtifactList?.forEach(artifact => {
        artifactList.artifacts[artifact.artifactName] = {
          name: artifact.artifactName,
          type: artifact.artifactType,
          originalFile: {
            name: artifact.artifactName,
          },
          uploadProgress: 0,
          error: '',
        };

        artifactList.fileStatus[artifact.artifactName] = {
          status:
            artifact.artifactState === 'UPLOADED'
              ? 'PENDING'
              : artifact.tokenProcessorStatus,
        };

        artifactList.zipFileStatus[artifact.artifactName] = {
          status:
            artifact.artifactState === 'UPLOADED' ||
            (artifact.artifactType !== 'ZIP' && artifact.artifactType !== 'XMI')
              ? 'PENDING'
              : artifact.tokenProcessorStatus,
        };

        if (
          artifact.artifactType === 'ZIP' ||
          artifact.artifactType === 'XMI'
        ) {
          let totalSummary = {
            jcl: 0,
            listings: 0,
            card: 0,
            proc: 0,
            dbrm: 0,
            others: 0,
          };

          //TODO- Need to update this after Backend is ready [Ganavi working on BE]

          artifactList.zipFileStatus[artifact.artifactName].totalSummary =
            totalSummary;
        }

        if (
          artifact.tokenProcessorStatus === 'NOT_VALID' ||
          artifact.artifactState === 'IGNORED'
        ) {
          invalidFiles[artifact.artifactName] = {
            name: artifact.artifactName,
            type: artifact.artifactType,
          };
        }
      });

      dispatch(setInvalidFileData(invalidFiles));
      dispatch(setUploadedArtifacts(artifactList));
    };

    if (data && !isLoading) {
      setupArtifactStatus();
    }
  }, [data, isLoading]);

  return undefined;
};
