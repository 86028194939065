import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { Home } from './pages/home/home';
import { SharedService } from './services/shared-service';
import Dashboard from './pages/dashboard/dashboard';
import ProjectOverview from './pages/project-overview/project-overview';
import NotesPage from './pages/notes/notes';
import ProjectTransformationSettings from './pages/project-transformationSettings/project-transformationSettings';
import LoginScreen from './pages/login-screen/login-screen';
import { useEffect, useId, useLayoutEffect, useRef } from 'react';
import ProjectReportPage from './pages/project-report-page/project-report-page';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import SupportTicketsPage from './pages/support-tickets/support-tickets';
import ReportsPage from './pages/admin/reports/reports';
import License from './pages/admin/license/license';
import AdminNotesPage from './pages/admin/notes/notes';
import GlobalSettingsPage from './pages/admin/global-project-settings/global-project-settings';
import UsersPage from './pages/admin/users/users';
import DraftsProjectPage from './pages/admin/drafts/drafts';
import Stages from './pages/stages/stages';
import UploadLicense from './pages/admin/license/upload-license';
import VersionPage from './pages/admin/version/version';
import Footer from './pages/footer/footer';
import dayjs from 'dayjs';
import UserProfile from './pages/user-profile/user-profile';

const Wrapper = ({ children }) => {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      ref.current?.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div id={id} ref={ref} className="overflow-y-auto h-full">
      {children}
      <Footer />
    </div>
  );
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const userLoginDetails = useSelector(
    (state: RootState) => state.appGlobal.userLoginDetails,
  );
  const license = useSelector((state: RootState) => state.appGlobal.license);

  if (!userLoginDetails) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  const isLicenseExpired = license?.licensedTill
    ? dayjs(license?.licensedTill, 'MM/DD/YYYY').isBefore(dayjs(), 'day')
    : false;

  if (
    license?.isLicenseValid !== 'true' ||
    license?.isLicenseActivate !== 'true' ||
    isLicenseExpired
  ) {
    console.log('Invalid license so go to upload license page:', {
      isLicenseExpired,
    });
    return <Navigate to={ROUTES.UPLOAD_LICENSE} />;
  }

  return children;
};

export enum ROUTES {
  HOME = '/project-transform',
  DASHBOARD = '/dashboard',
  NOTES = '/notes',
  PROJECT_OVERVIEW = '/project-overview',
  PROJECT_TRANSFROMATION_SETTINGS = '/project-settings',
  PROJECT_REPORT = '/project-report',
  LOGIN = '/',
  SUPPORT_TICKETS = '/support-tickets',
  ADMIN_REPORTS = '/admin-reports',
  LICENSE = '/admin-license',
  UPLOAD_LICENSE = '/admin-license/upload-license',
  ADMIN_NOTES = '/admin-notes',
  ADMIN_GLOBAL_PROJECT_SETTINGS = '/admin-global-project-settings',
  VERSION = '/admin-version',
  USERS = '/admin-users',
  DRAFT_PROJECTS = '/admin-draft-projects',
  STAGES = '/stages',
  USER_PROFILE = '/user-profile',
  MEMBER_LICENSE = '/license',
}

const onPremRoutes = [
  {
    path: ROUTES.HOME,
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.DASHBOARD,
    element: (
      <Wrapper>
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.MEMBER_LICENSE,
    element: (
      <PrivateRoute>
        <Wrapper>
          <License />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PROJECT_OVERVIEW,
    element: (
      <Wrapper>
        <PrivateRoute>
          <ProjectOverview />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.NOTES,
    element: (
      <Wrapper>
        <PrivateRoute>
          <NotesPage />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.PROJECT_TRANSFROMATION_SETTINGS,
    element: (
      <Wrapper>
        <PrivateRoute>
          <ProjectTransformationSettings />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.PROJECT_REPORT,
    element: (
      <Wrapper>
        <PrivateRoute>
          <ProjectReportPage />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.SUPPORT_TICKETS,
    element: (
      <Wrapper>
        <PrivateRoute>
          <SupportTicketsPage />
        </PrivateRoute>
      </Wrapper>
    ),
  },
  {
    path: ROUTES.LOGIN,
    element: (
      <Wrapper>
        <LoginScreen />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.ADMIN_REPORTS,
    element: (
      <PrivateRoute>
        <Wrapper>
          <ReportsPage />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LICENSE,
    element: (
      <PrivateRoute>
        <Wrapper>
          <License />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.UPLOAD_LICENSE,
    element: (
      <Wrapper>
        <UploadLicense />
      </Wrapper>
    ),
  },
  {
    path: ROUTES.ADMIN_NOTES,
    element: (
      <PrivateRoute>
        <Wrapper>
          <AdminNotesPage />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ADMIN_GLOBAL_PROJECT_SETTINGS,
    element: (
      <PrivateRoute>
        <Wrapper>
          <GlobalSettingsPage />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.VERSION,
    element: (
      <PrivateRoute>
        <Wrapper>
          <VersionPage />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.USERS,
    element: (
      <PrivateRoute>
        <Wrapper>
          <UsersPage />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.DRAFT_PROJECTS,
    element: (
      <PrivateRoute>
        <Wrapper>
          <DraftsProjectPage />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.STAGES,
    element: (
      <PrivateRoute>
        <Wrapper>
          <Stages />
        </Wrapper>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.USER_PROFILE,
    element: (
      <PrivateRoute>
        <Wrapper>
          <UserProfile />
        </Wrapper>
      </PrivateRoute>
    ),
  },
];

export default function AppNavigation() {
  const router = createBrowserRouter(
    SharedService.isOnPrem
      ? onPremRoutes
      : [
          {
            path: '*',
            element: (
              <Wrapper>
                <Home />
              </Wrapper>
            ),
          },
        ],
  );

  SharedService.router = router;

  return <RouterProvider router={router} />;
}
