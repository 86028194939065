import { Modal } from "antd";
import { useEffect, useState } from "react";
import { FaGithub } from "react-icons/fa";
import SVGLoader from "src/assets/images/svg/loader.icon";
import { helpTexts } from "src/helpTexts";
import { useGetDeployStatusQuery, useGetRepositoryDataQuery } from "src/redux/api-slice";
import { getSearchParameter } from "src/utils/url.utils";
import Swal from "sweetalert2";
import "./deploy-chevron.css";
import DeployTable from "./deploy-table";

interface JobData {
    jobName: string;
    validated: string;
    progress: number;
    success: boolean;
    expanded: boolean;
    completionDate: string;
    deployStatus?: string;
    deployedDate?: string;
    isProgress?: boolean;
    deployUrl?: string;
    errorMessage?: string;
}

interface DeploySections {
    title: string;
    rows: JobData[];
}

interface RepoConfig {
    useSingleRepository: boolean;
    useSingleRepoForTransform: boolean | null;
    useSingleRepoForSmartDoc: boolean | null;
}

const DeployChevron = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const projectId = getSearchParameter('projectId');
    const {data, isLoading} = useGetDeployStatusQuery(
        {
            projectId: projectId,
        },
        { skip: !projectId, refetchOnMountOrArgChange: true },
    );
    const [deployData, setDeployData] = useState<DeploySections[]>([]);
    const [repoConfig, setRepoConfig] = useState<RepoConfig | null>();
    const [loading, setLoading] = useState(false);
    const pageHelpText = helpTexts[0].DEPLOY_PAGE;
    useEffect(() => {
        const fetchDeployStatus = async () => {
            setLoading(true)
            try {
                const titleMapping = {
                    "SMART_DOC" : "smartDoc",
                    "TRANSFORMATION" : "transformation",
                    "READINESS": "readiness"
                }
                const deploySections: DeploySections[] = data?.artifactsDetails?.reduce((acc: DeploySections[], item) => {
                    const deployType = item.deployType;
                    const jobData: JobData = {
                        jobName: item.artifactName,
                        validated: item.deployStatus,
                        progress: 0,
                        success: item.deployStatus === 'PASS',
                        expanded: false,
                        completionDate: item.deployedDate,
                        deployStatus: item.deployStatus,
                        deployUrl: item.deployUrl || '',
                        errorMessage: item.errorMessage || '',
                    };
                    const sectionIndex = acc.findIndex(section => section.title === titleMapping[deployType]);

                    if (sectionIndex >= 0) {
                        acc[sectionIndex].rows.push(jobData);
                    } else {
                        acc.push({
                            title: titleMapping[deployType],
                            rows: [jobData],
                        });
                    }

                    return acc;
                }, []);
                const repoConfig = {
                    useSingleRepository: data?.useSingleRepository,
                    useSingleRepoForTransform: data?.useSingleRepoForTransform,
                    useSingleRepoForSmartDoc: data?.useSingleRepoForSmartDoc,
                };
                console.log(deploySections)
                setRepoConfig(repoConfig);
                setDeployData(deploySections);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Server Error',
                    text: 'Unable to fetch artifacts status for Deploy. Please contact CloudFrame Support Team for further assistance.',
                    confirmButtonColor: '#f27474',
                });
            }
        }
        if (data)
            fetchDeployStatus();
    }, [data])

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // const handleExpandToggle = (index) => {
    //     const updatedData = [...data];
    //     updatedData[index].expanded = !updatedData[index].expanded;
    //     setData(updatedData);
    // };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <div>
            <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm"
                data-v0-t="card"
            >
                <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
                    <div className='flex flex-row'>
                        <h2 className="text-2xl font-bold text-white">Jobs Ready for Deployment</h2>
                    </div>
                    <p className="text-sm text-white">The Deploy stage allows you to push the transformed code to GitHub. Once deployment is initiated, CodeNavigator establishes a connection with GitHub, commits the changes, and deploys the code.</p>
                </div>
                <hr />
                <div>
                    <div className="relative w-full overflow-auto">
                        {(isLoading) ?
                            <div className="flex flex-col items-center justify-center">
                                <SVGLoader width={60} height={60} />
                                <p className="pb-2">Fetching Artifact details...</p>
                            </div>
                            : (deployData?.length === 0 ?
                                <div className="text-center p-4 text-red-600">
                                    Deploy configurations not found. Please contact your admin for further assistance.
                                </div>
                                : (
                                    <DeployTable
                                        deployData={deployData}
                                        setData={setDeployData}
                                        repoConfig={repoConfig}
                                    />
                                )
                            )}

                    </div>
                </div>
            </div>
            <Modal
                title={
                    <div className="text-center font-semibold text-lg">
                        Deploy Job
                    </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <hr className="my-4 border-gray-300" />
                <div className="space-y-6">
                    {/* GitHub */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaGithub className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800 text-[22px]">Deploy to GitHub</span>
                        </div>
                        <button className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                    {/* <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaBitbucket className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800 text-[22px]">Deploy to Bitbucket</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('bitbucket')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaGitlab className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800 text-[22px]">Deploy to GitLab</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('gitlab')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div> */}
                </div>
            </Modal>
        </div >
    )
}
export default DeployChevron;