import HelpTextCallout from "src/components/help-text-callout/help-text-callout";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";

export const FieldWrapper = ({ label, helpText, children }) => (
    <div className="flex flex-col">
        <label className="block text-md font-medium mb-2 flex items-center">
            {label}
            <HelpTextCallout calloutText={helpText} />
        </label>
        {children}
    </div>
);

export const ToggleField = ({ label, isChecked, onChange, helpText }) => (
    <FieldWrapper label={label} helpText={helpText}>
        <div className="flex items-center">
            <span className={`mr-3 ${isChecked === 'true' ? "font-bold" : "font-medium"}`}>Yes</span>
            <Switch
                checked={isChecked === 'true'}
                onCheckedChange={() => onChange()}
                className="mx-2"
            />
            <span className={`ml-3 ${isChecked === 'false' ? "font-bold" : "font-medium"}`}>No</span>
        </div>
    </FieldWrapper>
);

export const InputField = ({ label, value, onChange, helpText }) => (
    <FieldWrapper label={label} helpText={helpText}>
        <input
            type="text"
            value={value}
            onChange={e => onChange(e.target.value)}
            className="w-full p-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
        />
    </FieldWrapper>
);

export const SelectField = ({ label, value, onChange, helpText }) => (
    <FieldWrapper label={label} helpText={helpText}>
        <Select onValueChange={onChange} value={value}>
            <SelectTrigger>
                <SelectValue placeholder="Select an option" />
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="true">Yes</SelectItem>
                <SelectItem value="false">No</SelectItem>
            </SelectContent>
        </Select>
    </FieldWrapper>
);