import Table from './table/table';
import Summary from './summary/summary';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetArtifactStatusObject,
  resetTransformationStatus,
  setCurrentIntent,
  setTransformationProgress,
  setTransformationStatus,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EArtifactStatus,
  ITransformationStatus,
} from '../../../redux/app-global/app-global.types';
import useTransformation from './useTransformation';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { RootState } from '../../../redux/store';
import { useCallChainUtils } from '../../../hooks/useCallChainUtils';
import { useEffect, useMemo } from 'react';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import Swal from 'sweetalert2';
import {
  useGetMissingArtifactsQuery,
  useGetTransformationDetailsQuery,
} from '../../../redux/api-slice';
import { getSearchParameter } from 'src/utils/url.utils';

const pageHelpText = helpTexts[0].TRANSFORMATION_PAGE;

const TransformationPage = () => {
  const dispatch = useDispatch();
  const { availableProfiles } = useTransformation();
  const missingPages = useSelector(
    (state: RootState) => state.appGlobal.missingPages,
  );

  const { jclArtifactIds, programIds, programs, jclArtifacts } =
    useCallChainUtils();

  const projectId = getSearchParameter('projectId');

  const transformationDetailsQuery = useGetTransformationDetailsQuery(
    {
      projectId: projectId,
    },
    { skip: !projectId, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    const newArtifactStatus: Record<string, EArtifactStatus> = {};
    const transformationProgress: Record<string, number> = {};
    let transformationStage: Record<string, ITransformationStatus> = {};

    if (transformationDetailsQuery.data) {
      transformationStage = transformationDetailsQuery.data.statusDetails || {};
    }

    Object.entries(transformationStage).forEach(([key, value]) => {
      let statusOfKey = 0;
      newArtifactStatus[key] = EArtifactStatus.INPROGRESS;

      if (
        value?.PACKAGING === 'PASS' &&
        value?.CODE_TRANSFORMATION === 'PASS' &&
        value?.COMPILATION === 'PASS' &&
        value?.CODE_GENERATION === 'PASS'
      ) {
        newArtifactStatus[key] = EArtifactStatus.SUCCESS;
      }

      if (
        value?.PACKAGING === 'FAILED' ||
        value?.CODE_TRANSFORMATION === 'FAILED' ||
        value?.COMPILATION === 'FAILED' ||
        value?.CODE_GENERATION === 'FAILED'
      ) {
        newArtifactStatus[key] = EArtifactStatus.ERROR;
      }

      if (['PASS', 'FAILED'].includes(value?.PACKAGING)) {
        statusOfKey = 100;
      } else if (['PASS', 'FAILED'].includes(value?.CODE_TRANSFORMATION)) {
        statusOfKey = 75;
      } else if (['PASS', 'FAILED'].includes(value?.COMPILATION)) {
        statusOfKey = 50;
      } else if (['PASS', 'FAILED'].includes(value?.CODE_GENERATION)) {
        statusOfKey = 25;
      } else {
        statusOfKey = 0;
      }
      transformationProgress[key] = statusOfKey;
    });

    dispatch(resetArtifactStatusObject(newArtifactStatus));
    dispatch(setTransformationProgress(transformationProgress));
    dispatch(resetTransformationStatus(transformationStage));
  }, [transformationDetailsQuery, dispatch]);

  const missingArtifactQuery = useGetMissingArtifactsQuery();

  const ignoredMissingArtifacts = useMemo(() => {
    if (missingArtifactQuery.data?.ignoredData) {
      return missingArtifactQuery.data.ignoredData;
    }
    return {};
  }, [missingArtifactQuery]);

  const data = useMemo(() => {
    const ignoredPrograms = ignoredMissingArtifacts?.missingProgram || [];
    const artiToExclude = new Set<string>();
    Object.values(missingPages[0]?.data || {}).forEach((data: any) => {
      data.forEach((item: any) => {
        if (item.name && !ignoredPrograms.some(x => x.name === item.name)) {
          item.referredIn?.callerName?.forEach(pgName => {
            artiToExclude.add(pgName);
          });
        }
      });
    });

    const drivers = new Set<string>();
    const cicsDrivers = new Set<string>();
    programs.map(({ nodes }) => {
      nodes?.forEach(node => {
        if (node.isRootNode && !artiToExclude.has(node.id)) {
          if (node.isCicsDriver) {
            cicsDrivers.add(node.id);
          } else {
            drivers.add(node.id);
          }
        }
      });
    });

    console.log('Drivers:', artiToExclude);

    const includedInJcls = new Set<string>();
    jclArtifacts.map(jcl => {
      jcl[0]?.links?.forEach(stepLink => {
        if (stepLink.links?.id) {
          includedInJcls.add(stepLink.links?.id);
        }
      });
    });
    const jcls = jclArtifactIds
      .filter(id => !artiToExclude.has(id))
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const childLibraries = programIds
      .filter(
        id =>
          !artiToExclude.has(id) && !drivers.has(id) && !includedInJcls.has(id),
      )
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const driverPrograms = Array.from(drivers)
      .filter(id => !includedInJcls.has(id))
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const ciscsPrograms = Array.from(cicsDrivers)
      .filter(id => !includedInJcls.has(id))
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return {
      jcls,
      childLibraries,
      driverPrograms,
      ciscsPrograms,
    };
  }, [
    missingPages,
    jclArtifactIds,
    programIds,
    programs,
    jclArtifacts,
    ignoredMissingArtifacts?.missingProgram,
  ]);

  const jcls = data.jcls || [];
  const driverPrograms = data.driverPrograms || [];
  const otherPrograms = data.childLibraries || [];
  const ciscsPrograms = data.ciscsPrograms || [];

  const onClickReport = () => {
    dispatch(setCurrentIntent(EAppIntent.TRANSFORMATION_REPORT));
  };

  if (transformationDetailsQuery.isLoading) {
    return (
      <div className="flex justify-center items-center h-5/6">Loading...</div>
    );
  }
  return (
    <div>
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm mx-6 mt-4">
        <div className="bg-white rounded-lg shadow-sm p-6 mx-auto">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold"></h2>
            <div className="flex gap-2">
              <HelpTextCallout calloutText={pageHelpText[1].description} />
              <button
                onClick={onClickReport}
                style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-green-500 text-white hover:bg-green-600"
              >
                <span className="mr-1">
                  <HiOutlineDocumentReport size={20} />
                </span>
                Transformation Summary Report
              </button>
            </div>
          </div>
          <div className="h-[1px] bg-greyCardOutline my-2 mb-4 -mx-6" />
          <Summary />
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6 mx-auto min-h-[20rem] w-full mt-0">
        <div className="flex flex-col gap-4">
          <Table
            title="Transformation Queue"
            description="Manage your Transformations"
            helpText={pageHelpText[2].description}
            tables={[
              {
                title: 'Jobs',
                rows: jcls,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.jcl,
                ],
                columnHelpTexts: [
                  { title: 'Name', helpText: pageHelpText[6].description },
                  // { title: 'Transform Rules', helpText: pageHelpText[7].description },
                  { title: 'Action', helpText: pageHelpText[8].description },
                  {
                    title: 'Transform Page',
                    helpText: pageHelpText[9].description,
                  },
                ],
                ButtonHelpTexts: [
                  {
                    title: 'Cancel All Transformations',
                    helpText: pageHelpText[5].description,
                  },
                ],
              },
              {
                title: 'Driver Programs',
                rows: driverPrograms,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.driverPrograms,
                ],
                columnHelpTexts: [
                  { title: 'Name', helpText: pageHelpText[10].description },
                  // { title: 'Transform Rules', helpText: pageHelpText[11].description },
                  { title: 'Action', helpText: pageHelpText[12].description },
                  {
                    title: 'Transform Page',
                    helpText: pageHelpText[13].description,
                  },
                ],
                ButtonHelpTexts: [
                  {
                    title: 'Cancel All Transformations',
                    helpText: pageHelpText[19].description,
                  },
                ],
              },
              {
                id: 'cics',
                title: 'CICS Driver Programs',
                rows: ciscsPrograms,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.driverPrograms,
                ],
                columnHelpTexts: [
                  { title: 'Name', helpText: pageHelpText[22].description },
                  { title: '', helpText: pageHelpText[11].description },
                  { title: 'Action', helpText: pageHelpText[23].description },
                  {
                    title: 'Transform Page',
                    helpText: pageHelpText[13].description,
                  },
                ],
                ButtonHelpTexts: [
                  {
                    title: 'Cancel All Transformations',
                    helpText: pageHelpText[19].description,
                  },
                ],
              },
            ]}
          />
          <Table
            title="Child Libraries"
            description="Manage your program Transformations"
            helpText={pageHelpText[3].description}
            tables={[
              {
                title: 'Child Libraries',
                rows: otherPrograms,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.otherPrograms,
                ],
                columnHelpTexts: [
                  { title: 'Name', helpText: pageHelpText[14].description },
                  // { title: 'Transform Rules', helpText: pageHelpText[15].description },
                  { title: 'Action', helpText: pageHelpText[16].description },
                  {
                    title: 'Transform Page',
                    helpText: pageHelpText[17].description,
                  },
                ],
                ButtonHelpTexts: [
                  {
                    title: 'Cancel All Transformations',
                    helpText: pageHelpText[21].description,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default TransformationPage;
