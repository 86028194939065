import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import {
  FaCode,
  FaCalendarAlt,
  FaFileCode,
  FaCogs,
  FaBox,
  FaKey,
  FaClock,
  FaTag,
  FaLaptopCode,
  FaUserShield,
  FaFileAlt,
  FaTools,
} from 'react-icons/fa';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  useGetLicenseGraphAPIQuery,
  useGetLicenseQuery,
  useGetProjectsQuery,
} from 'src/redux/api-slice';
import { ROUTES } from 'src/App.navigation';
import { useNavigate } from 'react-router-dom';
import AdminOnly from 'src/components/admin-only/admin-only';
import { AiOutlineCloudSync, AiOutlineFileSearch } from 'react-icons/ai';

const License = () => {
  const { data, isLoading: isLincenseInfoLoading } = useGetLicenseQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: licenseGraphData,
    isLoading: isGraphLoading,
  } = useGetLicenseGraphAPIQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const totalNoOfLines = licenseGraphData?.reduce((acc, item) => acc + item.noOfLines, 0);
  const totalNoOfSmartDocsLines = licenseGraphData?.reduce((acc, item) => acc + item.noOfSmartDocsLines, 0);

  const navigate = useNavigate();

  const icons: { [key: string]: JSX.Element } = {
    LicenseType: <FaUserShield className="text-blue-500" />,
    Environment: <FaLaptopCode className="text-green-500" />,
    ClientName: <FaTag className="text-orange-500" />,
    LicensedProduct: <FaBox className="text-purple-500" />,
    LinesOfCode: <FaCode className="text-red-500" />,
    LicenseId: <FaKey className="text-yellow-500" />,
    PlatformExpiryDate: <FaClock className="text-gray-500" />,
    PlatformEffectiveDate: <FaCalendarAlt className="text-cyan-500" />,
    SmartDocLOC: <FaFileAlt className="text-indigo-500" />,
    ServicedeskID: <FaTools className="text-blue-500" />,
  };

  const { data: projects, isLoading: isProjectsLoading } = useGetProjectsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const filteredProjects =
    projects?.map(({ projectId, projectName, projectType }) => ({
      projectId,
      projectName,
      projectType,
    })) || [];

  const aggregateLines = (data: typeof licenseGraphData, lineKey: string) =>
    data?.reduce(
      (acc, item) => {
        acc[item.projectId] = (acc[item.projectId] || 0) + item[lineKey];
        return acc;
      },
      {} as { [key: string]: number },
    ) || {};

  const linesAggregate = aggregateLines(licenseGraphData, 'noOfLines');
  const linesAggregateSmartDoc = aggregateLines(
    licenseGraphData,
    'noOfSmartDocsLines',
  );

  const graphData = filteredProjects.map(({ projectId, projectName }) => ({
    name: projectName,
    TransformLoc: linesAggregate[projectId] || 0,
    SmartDocLoc: linesAggregateSmartDoc[projectId] || 0,
  }));

  const sortedGraphData = graphData.sort((a, b) => {
    if (a.TransformLoc === b.TransformLoc) {
      return a.SmartDocLoc - b.SmartDocLoc;
    }
    return a.TransformLoc - b.TransformLoc;
  });

  const goToUpload = () => {
    navigate(ROUTES.UPLOAD_LICENSE);
  };

  const formatKey = (key: string) => {
    if (key === 'ServicedeskID') {
      return 'Service Desk ID';
    } else if (key === 'LicenseId') {
      return 'License ID';
    } else if (key === 'SmartDocLOC') {
      return 'SmartDoc LOC';
    } else if (key === 'LinesOfCode') {
      return 'Transform LOC';
    } else {
      return key
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .replace(/^\w/, c => c.toUpperCase());
    }
  };

  return (
    <div className="p-0 flex flex-row bg-[#F3F4F4] min-h-[100vh] pb-8">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'License'} />
      </div>
      <div className="mt-8 ml-8 w-full">
      <AdminOnly>
        <button
          className="bg-blue-600 text-white text-sm px-3 py-2 rounded-md float-right mr-6"
          onClick={goToUpload}
        >
          Upload License
        </button>
        </AdminOnly>
        <div className="w-full">
          <h1 className="text-2xl font-bold">License</h1>
          <p className="mt-2">
            Manage and transform your COBOL application effortlessly. Get started
            by selecting a application to convert into Java.
          </p>
          <br />
          {isLincenseInfoLoading ? (
            <div>Loading License Info...</div>
          ) : (
            <div className="mr-6">
              <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-6 mb-8">
                {data
                  ? Object.entries(data).map(([key, value], idx) => {
                    const formattedKey = formatKey(key);
                    if (
                      key === 'LinesOfCode' &&
                      data?.LicensedProduct?.toLowerCase() === 'smartdoc'
                    ) {
                      return null;
                    }
                    return (
                      <div
                        key={idx}
                        className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative"
                      >
                        <div className="absolute top-4 right-4 text-xl">
                          {icons[key] || <FaCogs />}
                        </div>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">
                          {formattedKey}
                        </h2>
                        <p className="text-md text-gray-600">
                          {value !== null && value !== undefined
                            ? value.toString()
                            : 'N/A'}
                        </p>
                      </div>
                    );
                  })
                  : ''}


                {isGraphLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    {data?.LicensedProduct?.toLowerCase() !== 'smartdoc' &&
                      <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative">
                        <div className="flex items-center mb-2">
                          <h2 className="text-xl font-semibold text-gray-700">Current Transform LOC Usage</h2>
                        </div>
                        <AiOutlineCloudSync className="text-blue-500 text-2xl absolute top-4 right-4" />
                        <p className="text-md text-gray-600">{totalNoOfLines || 0}</p>
                      </div>
                    }
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative">
                      <div className="flex items-center mb-2">
                        <h2 className="text-xl font-semibold text-gray-700">Current SmartDoc LOC Usage</h2>
                      </div>
                      <AiOutlineFileSearch className="text-red-500 text-2xl absolute top-4 right-4" />
                      <p className="text-md text-gray-600">{totalNoOfSmartDocsLines || 0}</p>
                    </div>
                  </>
                )}

              </div>
            </div>
          )}

          {!isLincenseInfoLoading ? (
            isProjectsLoading || isGraphLoading ? (
              <div>Loading Graph...</div>
            ) : (
              <>
                {sortedGraphData.length > 0 && filteredProjects.length > 0 ? (
                  <div className="mr-8 mt-10 bg-white p-10 rounded-xl shadow-lg">
                    <h2 className="text-2xl font-semibold mb-6 text-gray-900">
                      License Statistics
                    </h2>
                    <ResponsiveContainer width="100%" height={450}>
                      <BarChart
                        data={sortedGraphData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 130 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#d1d5db" />
                        <XAxis
                          dataKey="name"
                          tick={{ fill: '#374151', fontSize: 13 }}
                          label={{
                            value: 'Applications',
                            position: 'insideBottom',
                            offset: -110,
                            fill: '#374151',
                            fontSize: 20,
                            fontWeight: 'Bold',
                          }}
                          interval={0}
                          angle={-45}
                          textAnchor="end"
                        />
                        <YAxis
                          tick={{ fill: '#374151', fontSize: 13 }}
                          label={{
                            value: 'Lines Of Code',
                            angle: -90,
                            offset: -5,
                            dy: -65,
                            position: 'insideBottomLeft',
                            fill: '#374151',
                            fontSize: 20,
                            fontWeight: 'Bold',
                          }}
                        />
                        <Tooltip
                          contentStyle={{
                            backgroundColor: '#ffffff',
                            border: '1px solid #e5e7eb',
                            borderRadius: '8px',
                            padding: '10px',
                          }}
                          itemStyle={{ color: '#1D4ED8' }}
                        />
                        <Legend
                          verticalAlign="top"
                          align="center"
                          iconType="rect"
                          wrapperStyle={{ paddingBottom: 10 }}
                        />
                        {data?.LicensedProduct?.toLowerCase() !== 'smartdoc' && (
                          <Bar
                            dataKey="TransformLoc"
                            name="TransformLoc"
                            fill="url(#colorUv)"
                            barSize={40}
                          />
                        )}
                        <Bar
                          dataKey="SmartDocLoc"
                          name="SmartDocLoc"
                          fill="#20B2AA"
                          barSize={40}
                        />
                        <defs>
                          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#1D4ED8" stopOpacity={0.9} />
                            <stop offset="95%" stopColor="#60A5FA" stopOpacity={0.9} />
                          </linearGradient>
                        </defs>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                ) : projects && projects.length === 0 ? (
                  <div className="text-center my-8">
                    <p className="text-lg font-medium text-gray-500">
                      No Applications Graph Available
                    </p>
                  </div>
                ) : null}
              </>
            )
          ) : null}

          <div className="mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default License;
