import React, { useEffect, useMemo, useState } from 'react';
import { AvatarStack } from 'src/components/ui/avatar-stack';
import {
  useGetProjectDependenciesQuery,
  useGetUsersQuery,
  useUpdateProjectTeamMembersMutation,
} from 'src/redux/api-slice';
import { appTheme } from 'src/styles/theme';
import { IAddTeamMembersProps } from './add-team-members.interface';
import { IUser } from 'src/pages/admin/users/users.service';
import { FaTimes } from 'react-icons/fa';
import { IoCreate } from 'react-icons/io5';
import Swal from 'sweetalert2';
import { useToast } from 'src/hooks/use-toast';

export const AddTeamMembers: React.FC<IAddTeamMembersProps> = ({
  onClose,
  refetchProjects,
  project,
}) => {
  const [searchText, setSearchText] = useState('');
  const [teamMembers, setTeamMembers] = useState<IUser[]>([]);
  const [projectLead, setProjectLead] = useState<IUser | null>(null);
  const [updateProjectTeamMembersMutation, { isLoading: isUpdateLoading }] =
    useUpdateProjectTeamMembersMutation();
  const projectDependenciesQuery = useGetProjectDependenciesQuery();

  const isEditMemberDetails = project.teamMemberCount > 0;

  const { toast } = useToast();

  const users = useGetUsersQuery();

  const filteredProjectDependencies = useMemo(() => {
    if (projectDependenciesQuery.isLoading) return [];

    const projectDependencies = projectDependenciesQuery.data || [];

    const deps = projectDependencies.find((p: any) => {
      return project.projectId === p.projectId;
    });

    if (!deps) return [];

    return deps.dependencyList || [];
  }, [projectDependenciesQuery]);

  const isLoading =
    users.isLoading ||
    !projectLead ||
    !teamMembers.length ||
    isUpdateLoading ||
    projectDependenciesQuery.isLoading;

  useEffect(() => {
    if (
      !users.isLoading &&
      users.data?.length &&
      project.projectMemberList &&
      project.projectMemberList.length
    ) {
      const projectMemberUserObjs: IUser[] = [];
      for (const teamMember of project.projectMemberList) {
        const teamMemberUserObj = users.data.find(
          user => user.id === teamMember.userId,
        );

        if (!teamMemberUserObj) continue;

        if (teamMember.memberType === 'LEAD') {
          setProjectLead(teamMemberUserObj);
        }

        projectMemberUserObjs.push(teamMemberUserObj);
      }

      setTeamMembers(projectMemberUserObjs);
    }
  }, [project, users]);

  const filteredUsers = useMemo(() => {
    const memberUsers = users.data?.filter(user => user.role === 'MEMBER' && user.active);
    if (!searchText) {
      return memberUsers || [];
    }
    return (
      memberUsers?.filter(user =>
        user.name.toLowerCase().includes(searchText.trim().toLowerCase()),
      ) || []
    );
  }, [searchText, users.data]);

  const handleSubmission = async () => {
    const result = await updateProjectTeamMembersMutation({
      projectId: project.projectId,
      project: {
        ...project,
        teamMembers: teamMembers,
        projectLead: projectLead as IUser,
      },
      dependencies: filteredProjectDependencies,
    });
    if (result.data?.status === 'SUCCESS') {
      onClose();
      toast({
        title: 'Team members updated successfully',
        className: 'bg-green-50 text-green-800',
        duration: 3000,
      });
      await refetchProjects();
    } else {
      onClose();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Unable to update members. Please try again later.',
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-5 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-2xl max-w-2xl w-full h-[80%] overflow-hidden flex flex-col relative">
        <div className="flex justify-between items-center pb-2 sticky top-0 bg-white z-10 py-2 border-b">
          <h2 className="text-xl font-bold">{project.projectName}</h2>

          <button
            onClick={onClose}
            className="flex items-center justify-center w-9 h-9 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
            aria-label="Close modal"
          >
            <FaTimes size={15} />
          </button>
        </div>
        <div className=" relative mt-4 overflow-auto overflow-x-hidden flex-grow">
          <div className="w-full">
            <div>
              <div className="mb-6 space-y-2">
                <label className="block text-gray-600 text-sm">
                  {isEditMemberDetails
                    ? 'Application Team Members'
                    : 'Add Team Members'}
                </label>
                <input
                  type="text"
                  className="mx-1 w-[calc(100%-.4rem)] py-2 px-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  placeholder="Search Team Members"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>

              {users.isLoading ? (
                'Loading'
              ) : users.isError ? (
                'Unable to fetch users!'
              ) : users.data && users.data.length > 0 ? (
                <div className="flex justify-start w-full overflow-auto gap-4 pb-6">
                  {filteredUsers.map(user => {
                    const isSelectedUser = teamMembers.some(
                      u => u.id === user.id,
                    );
                    return (
                      <div
                        key={user.name}
                        className="flex flex-col items-center w-40 cursor-pointer p-2 rounded-md"
                        style={{
                          borderWidth: 1,
                          borderColor: isSelectedUser
                            ? appTheme.colors.darkGrey
                            : appTheme.colors.greyCardOutline,
                          background: isSelectedUser
                            ? appTheme.colors.whiteSmoothLight
                            : appTheme.colors.whiteSmooth,
                        }}
                        onClick={() => {
                          if (isSelectedUser) {
                            setTeamMembers(
                              teamMembers.filter(u => u.id !== user.id),
                            );
                          } else {
                            setTeamMembers([...teamMembers, user]);
                          }
                        }}
                      >
                        <AvatarStack
                          maxAvatarsAmount={1}
                          avatars={[user.name]}
                        />
                        <div
                          className="text-gray-700 mt-2 text-sm overflow-hidden whitespace-nowrap text-overflow-ellipsis"
                          title={user.name}
                        >
                          {user.name}
                        </div>
                        <div className="text-sm text-gray-500">{user.role}</div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                'No active users found'
              )}
            </div>
            <hr className="mb-4" />
            <div>
              <div className="mb-2 space-y-2">
                <label className="block text-gray-600 text-sm">
                  {isEditMemberDetails ? 'Application Lead' : 'Select application lead'}
                </label>
              </div>

              <div className="min-h-[140px]">
                {teamMembers.length > 0 ? (
                  <div className="flex justify-start w-full overflow-auto gap-4 pb-6">
                    {teamMembers?.map(user => {
                      const isLeadUser = projectLead?.id === user.id;
                      return (
                        <div
                          key={user.name}
                          className="flex flex-col items-center w-max cursor-pointer p-2 rounded-md"
                          onClick={() => {
                            if (projectLead?.id === user.id) {
                              setProjectLead(null);
                            } else {
                              setProjectLead(user);
                            }
                          }}
                          style={{
                            borderWidth: 1,
                            borderColor: isLeadUser
                              ? appTheme.colors.darkGrey
                              : appTheme.colors.greyCardOutline,
                            background: isLeadUser
                              ? appTheme.colors.whiteSmoothLight
                              : appTheme.colors.whiteSmooth,
                          }}
                        >
                          <AvatarStack
                            maxAvatarsAmount={1}
                            avatars={[user.name]}
                          />
                          <div className="text-gray-700 mt-2 text-sm w-max">
                            {user.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {user.role}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center  bg-white z-10 py-2 border-t pt-4 justify-end">
            <div className="h-3 mb-4" />

            <div className="flex justify-between w-full">
              <div className="flex justify-end w-full mt-auto">
                <button
                  onClick={handleSubmission}
                  disabled={isLoading}
                  style={{ opacity: isLoading ? 0.5 : 1 }}
                  className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition flex items-center space-x-2"
                >
                  <span>{isUpdateLoading ? 'Submitting' : 'Submit'}</span>
                  <span>
                    <IoCreate />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
