import { AvatarStack } from 'src/components/ui/avatar-stack';
import AdminSideBar from 'src/components/admin-sidebar/admin-sidebar';
import ProjectTile from 'src/components/project-tile/project-tile';
import ProgressSpinner from 'src/components/progress-spinner/progress-spinner';
import { getProgressStateColor } from 'src/pages/dashboard/navigation-tab/navigation-tab';
import { useGetProjectsQuery } from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { useState } from 'react';
import {
  EProjectStatus,
  IProjectApiObject,
} from 'src/pages/dashboard/dashboard.service';
import { AddTeamMembers } from './components/add-memebers/add-members';

const DraftsProjectPage = () => {
  const {
    isLoading,
    data: projects,
    error,
    refetch,
  } = useGetProjectsQuery({ status: EProjectStatus.DRAFT });
  const [alertsShown, setAlertsShown] = useState({ projects: false });
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);

  if (error && !alertsShown.projects) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load applications. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, projects: true });
  }

  const handleProjectClick = (project: IProjectApiObject) => {
    setSelectedProject(project);
    setOpenEditProjectModal(true);
  };

  const renderProjectCards = () => {
    return projects?.map(item => (
      <ProjectTile
        key={item.projectId}
        onClick={() => handleProjectClick(item)}
        topLeftIcon={
          <ProgressSpinner
            progress={Number(item.completePercentage.toFixed(2))}
            progressColor={getProgressStateColor(item.completePercentage)}
          />
        }
        topRightContent={<div />}
        actionCta={
          <AvatarStack
            maxAvatarsAmount={3}
            avatars={item.projectMemberList?.map(member => member.name) || []}
          />
        }
        title={item.projectName}
        subTitle={item.projectTypeDisplayValue}
        {...item}
      />
    ));
  };

  return (
    <div className="p-0 flex flex-row bg-[#F3F4F4] min-h-screen pb-8">
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <AdminSideBar activeScreen={'DraftProject'} />
      </div>
      <div className="mt-8 ml-8 w-full">
        <div className="w-full">
          <h1 className="text-2xl font-bold">Draft Application</h1>
          <p className="mt-2">
            Draft application are those that have not yet been assigned
            resources.
          </p>
          <div className="mr-6">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mt-8">
              {isLoading ? 'Loading...' : renderProjectCards()}
              {!isLoading && projects?.length === 0 && (
                <p className="text-gray-500">No draft applications found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {openEditProjectModal && (
        <AddTeamMembers
          onClose={() => setOpenEditProjectModal(false)}
          project={selectedProject}
          refetchProjects={refetch as any}
        />
      )}
    </div>
  );
};

export default DraftsProjectPage;
