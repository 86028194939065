import { IUser } from '../admin/users/users.service';
import { IProjectApiObject } from '../dashboard/dashboard.service';

export interface CreateProjectProps {
  isOpen: boolean;
  onClose: () => void;
  selectedProject: IProjectApiObject | null;
  isEditing: boolean;
  onSubmitEdit?: () => void;
}

export interface ProjectDetailsProps {
  projectFormData: IProjectFormData;
  handleProjectFormDataChange: (
    key: string,
    value: string | IUser | IUser[] | null,
  ) => void;
  isEditing: boolean;
}

export interface IDependentProject {
  projectId: number;
  projectName?: string;
  stage: string;
  reason: string;
}

export interface IProjectFormData {
  projectId: number;
  projectName: string;
  projectType: string;
  startDate: string;
  endDate: string;
  organizationName: string;
  organizationId: string;
  teamMembers: IUser[];
  projectLead: IUser | null;
  dependentProjects: IDependentProject[];
}

export interface IProjectDependencyProps {
  projectFormData: IProjectFormData;
  handleProjectFormDataChange: (
    key: string,
    value: string | IUser | IUser[] | IDependentProject[] | null,
  ) => void;
}

export enum EProjectStages {
  EXTRACT = 'EXTRACT',
  UPLOAD = 'UPLOAD',
  READINESS = 'READINESS',
  SMART_DOC = 'SMARTDOC',
  TRANSFORM = 'TRANSFORM',
  DEPLOY = 'DEPLOY',
  TEST = 'TEST',
  ENHANCE = 'ENHANCE',
}

export const ProjectStageToLabelMap = {
  [EProjectStages.EXTRACT]: 'Extract',
  [EProjectStages.UPLOAD]: 'Upload',
  [EProjectStages.READINESS]: 'Readiness',
  [EProjectStages.SMART_DOC]: 'Smart Doc',
  [EProjectStages.TRANSFORM]: 'Transform',
  [EProjectStages.DEPLOY]: 'Deploy',
  [EProjectStages.TEST]: 'Test',
};
