import { Tooltip } from "antd";
import { FaCheck, FaQuestion, FaTimes } from "react-icons/fa";

const ProgressCircle = ({ status, label }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <Tooltip
          title={status === "IN_PROGRESS" ? "SmartDoc generation is in progress." : ""}
          overlayInnerStyle={{
            backgroundColor: "#000",
            borderRadius: "8px",
            color: "#fff",
            padding: "8px",
            fontSize: "14px",
          }}
          // show={status === "IN_PROGRESS" ? undefined : false}
        >
          <svg
            className={`w-8 h-8 ${status === "IN_PROGRESS" ? "animate-spin-slow" : ""}`}
            viewBox="0 0 36 36"
          >
            <path
              className="circle-bg"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#e2e8f0"
              strokeWidth="2"
            />
            <path
              className="circle-progress"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke={status === "IN_PROGRESS" ? "#fbbf24" : status === "DOC_READY" ? "#4caf50" : (status === "NOT_POSSIBLE" || status === "LIC_EXCEEDED" || status === "ERROR" || status === "NOT_CONFIGURED") ? "#ef4444" : "#e2e8f0"}
              strokeWidth="2"
              strokeDasharray="75, 100"
            />
          </svg>
          <div className="absolute inset-0 flex items-center justify-center">
            {status === "DOC_READY" && (
              <Tooltip
                title="SmartDoc generation completed successfully."
                overlayInnerStyle={{
                  backgroundColor: '#000',
                  borderRadius: '8px',
                  color: '#fff',
                  padding: '8px',
                  fontSize: '14px',
                }}
              >
                <FaCheck className="text-white h-full w-full p-2 bg-green-500 rounded-full" />
              </Tooltip>
            )}
            {(status === "NOT_POSSIBLE" || status === "LIC_EXCEEDED" || status === "ERROR") && (
              <Tooltip
                title="SmartDoc generation failed. Please contact CloudFrame Support Team for further assistance."
                overlayInnerStyle={{
                  backgroundColor: '#000',
                  borderRadius: '8px',
                  color: '#fff',
                  padding: '8px',
                  fontSize: '14px',
                }}
              >
                <FaTimes className="text-white h-full w-full p-2 bg-red-500 rounded-full" />
              </Tooltip>
            )}
            {status === "NOT_CONFIGURED" && (
              <Tooltip
                title="OpenAI Settings not configured. Please contact your Admin."
                overlayInnerStyle={{
                  backgroundColor: '#000',
                  borderRadius: '8px',
                  color: '#fff',
                  padding: '8px',
                  fontSize: '14px',
                }}
              >
                <FaQuestion className="text-white h-full w-full p-2 bg-gray-500 rounded-full" />
              </Tooltip>
            )}
          </div>
        </Tooltip>
      </div>
      <span className="pt-2 text-xs text-gray-600">{label}</span>
    </div>
  );
};
export default ProgressCircle;