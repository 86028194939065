import { EAppIntent } from './redux/app-global/app-global.types';

export const helpTexts = [
  {
    [EAppIntent.EXTRACT_ARTIFACT]: [
      {
        id: 1,
        description:
          'Enter the dataset information for the JCLs to be extracted from the mainframe. After entering the dataset information, click the "+" icon to add it. To remove any dataset, click the "-" icon next to the corresponding entry.  It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 2,
        description:
          'Enter the dataset information for the JCL Procedure (PROC) to be extracted from the mainframe. After entering the dataset information, click the "+" icon to add it. To remove any dataset, click the "-" icon next to the corresponding entry.  It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 3,
        description:
          'Enter the dataset information for the JCL Control Cards to be extracted from the mainframe. After entering the dataset information, click the "+" icon to add it. To remove any dataset, click the "-" icon next to the corresponding entry. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 4,
        description:
          'Enter the dataset information for the Compiler Listings to be extracted from the mainframe. After entering the dataset information, click the "+" icon to add it. To remove any dataset, click the "-" icon next to the corresponding entry. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 5,
        description:
          'Enter the dataset information for the Metadata to be extracted from the mainframe. After entering the dataset information, click the "+" icon to add it. To remove any dataset, click the "-" icon next to the corresponding entry. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 6,
        description:
          'Click this option to generate the JCL required to download artifacts from the mainframe. Before downloading, ensure that you have provided the necessary dataset names in each box to create the JCL. It is advisable to have someone with mainframe knowledge for downloading the JCL extract.',
      },
      {
        id: 7,
        description:
          'Select an option from the dropdown to specify how you want to download the JCL. Choosing "Single JCL for All Artifacts" will generate one JCL file for all artifacts. If you need separate JCLs for each artifact, select the required extraction option from the dropdown.',
      },
      {
        id: 8,
        description:
          'Click this button when you are ready to upload the artifacts that you have extracted from your mainframe. This will initiate the next step in the modernization process, where you can begin working with your uploaded artifacts.',
      },
      {
        id: 9,
        description:
          'Enter the dataset information for the ISPF libraries to be extracted from the mainframe. To identify your mainframe ISPF libraries, please follow the steps provided in this article ',
      },
      {
        id: 10,
        description:
          ' After entering the dataset information, click the "+" icon to add it. To remove any dataset, click the "-" icon next to the corresponding entry. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
    ],
    [EAppIntent.REPORT]: [
      {
        id: 1,
        description:
          'Use this option to view and interact with call chain graphs on the canvas. This feature allows you to visually explore the call chains and their relationships within the application.',
      },
      {
        id: 2,
        description:
          'Click this option to quickly view the steps of the JCL, illustrating how each step calls other programs. This graphical representation helps you understand the flow and interactions within the JCL.',
      },
      {
        id: 3,
        description:
          'Some artifacts are missing to generate the code. Clicking on this button will navigate to the Missing Artifact Workspace, where you can add the missing artifact.',
      },
      {
        id: 4,
        description:
          'To exit the graph view, click on the “Hide Graph” button.',
      },
      {
        id: 5,
        description:
          "Click this button to navigate to the SmartDoc page, where you can access a detailed description of the program's code flow. The SmartDoc provides in-depth insights into the program's structure, logic, and dependencies, helping you understand its functionality and assess its readiness for transformation.",
      },
    ],
    [EAppIntent.MISSING_PROC]: [
      {
        id: 1,
        description:
          'Check this option to ignore all the listed missing PROCs in the current transformation process. You can also select individual rows to ignore specific PROCs.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing PROCs that are required for the transformation process. Each PROC represents a procedure needed by the JCL or another PROC.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific JCL or PROC that depends on the listed PROC. It helps you identify which procedures are essential for the correct execution of the JCL or PROC.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing PROC directly from your local system. Upload the corresponding PROC file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing PROC information. The "Upload PROCs" option lets you manually upload the missing PROC files from your local system. The "Extract PROCs from Mainframe" option enables you to enter the dataset information to initiate the extraction process of the missing PROC from mainframe.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several PROCs at once. Use this option to upload the missing PROCs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing PROC from the mainframe. The system will generate the JCL based on the provided dataset names. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
    ],
    [EAppIntent.MISSING_CONTROLCARD]: [
      {
        id: 1,
        description:
          'Check this option to ignore all the listed missing Control Cards in the current transformation process. You can also select individual rows to ignore specific Control Cards.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing Control Cards that are required for the transformation process. Each Control Card represents a set of instructions or parameters needed by the JCL or another Control Card.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific JCL or PROC that depends on the listed Control Card. It helps you identify which Control Cards are essential for the correct execution of the JCL or PROC.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing Control Card directly from your local system. Upload the corresponding Control Card file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing Control Card information. The "Upload Control Cards" option lets you manually upload the missing Control Card files from your local system. The "Extract Control Cards from Mainframe" option enables you to enter the information names to initiate the extraction process of the missing Control Card from mainframe.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Control Cards at once. Use this option to upload the missing Control Cards in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing Control Card from the mainframe. The system will generate the JCL based on the provided dataset names. It is advisable to have someone with mainframe knowledge for providing the dataset information. ',
      },
    ],
    [EAppIntent.MISSING_PROGRAM]: [
      {
        id: 1,
        description:
          'This dropdown lets you manage the status of each listed program. Select Active to resolve the missing program for the transformation process, Ignore to exclude it, or Assembler to specify that it’s acceptable to use stubs for the missing programs. You can apply these settings either globally for all programs or individually for each program in the respective rows.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing Programs that are required for the transformation process. Each Program is an essential component or application needed by the JCL or another Program.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific Program that calls or depends on the listed Program. It helps you identify which Programs are essential for the correct execution of the calling Program.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Programs from Mainframe" option enables you to enter the dataset information to initiate the extraction process of the missing programs.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 8,
        description:
          'Use the following table to specify any naming conventions or patterns where an external program name differs from its internal counterpart. This allows the system to correctly identify and map programs that follow specific naming rules.',
      },
      {
        id: 9,
        description:
          'Use this option to download a CSV template that you can fill in with the required name mappings. The template provides the necessary structure for entering external and internal program names or patterns.',
      },
      {
        id: 10,
        description:
          'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.',
      },
      {
        id: 11,
        description:
          'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.',
      },
      {
        id: 12,
        description:
          'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.',
      },
    ],
    [EAppIntent.RESOLVE_DYNAMIC_CALLS]: {
      programTableData: [
        {
          id: 1,
          description:
            'This dropdown lets you manage the status of each listed program. Select Active to resolve the missing program for the dynamic call, Ignore to exclude it, or Assembler to specify that it’s acceptable to use stubs for the missing programs. You can apply these settings either globally for all programs or individually for each program in the respective rows.',
        },
        {
          id: 2,
          description:
            'This column lists the names of the missing Programs that are required for the transformation process. Each Program is an essential component or application needed by the JCL or another Program.',
        },
        {
          id: 3,
          description:
            'This column indicates the specific Program that calls or depends on the listed Program. It helps you identify which Programs are essential for the correct execution of the calling Program.',
        },
        {
          id: 4,
          description:
            'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.',
        },
        {
          id: 5,
          description:
            'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.',
        },
        {
          id: 6,
          description:
            'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
        },
        {
          id: 7,
          description:
            'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
        },
        {
          id: 8,
          description:
            'Use the following table to specify any naming conventions or patterns where an external program name differs from its internal counterpart. This allows the system to correctly identify and map programs that follow specific naming rules.',
        },
        {
          id: 9,
          description:
            'Use this option to download a CSV template that you can fill in with the required name mappings. The template provides the necessary structure for entering external and internal program names or patterns.',
        },
        {
          id: 10,
          description:
            'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.',
        },
        {
          id: 11,
          description:
            'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.',
        },
        {
          id: 12,
          description:
            'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.',
        },
      ],
      dynamicResolveTable: [
        {
          id: 1,
          description:
            'This column lists the variables used in dynamic calls within the program. These variables represent the program names or parts of program names that are determined at runtime, and resolving them is necessary for accurate transformation.',
        },
        {
          id: 2,
          description:
            'This column indicates the specific program that uses the dynamic call through the listed Program Variable. It helps you identify which Caller Program is dependent on the resolution of the dynamic call.',
        },
        {
          id: 3,
          description:
            'Please select the correct program name from already uploaded programs to resolve the dynamic calls.',
        },
      ],
    },
    [EAppIntent.TRANSFORMATION_PAGE]: [
      {
        id: 1,
        description:
          "Use this option to create a support ticket if you encounter any issues or need assistance during the transformation phase. CloudFrame's customer support team will reach out to guide you through your request and provide the necessary support.",
      },
      {
        id: 2,
        description:
          'The Transformation Summary Report highlights programs that were successfully transformed and identifies those that encountered challenges. This report serves as an essential tool for assessing the transformation outcomes and informing your future strategic planning. It also offers a comprehensive overview of all support tickets raised during the transformation process.',
      },
      {
        id: 3,
        description:
          'The Transform section is your control center for managing the migration of programs ready for transformation. This section is organized into multiple categories, such as Jobs, Programs, and Driver Programs, based on the artifacts you have provided. Each category lists the respective programs that are prepared for migration. You can choose to initiate the migration process individually for each artifact within these categories or collectively for all artifacts in the section. This flexibility allows you to tailor the transformation process to your specific needs, ensuring that each program is migrated according to your timeline and priorities.',
      },
      {
        id: 4,
        description:
          'The Child Libraries displays child programs that are part of artifacts in other sections. These child programs are already included in the main transformation process. However, if you wish to download the code for a specific child program separately, you can do so using this section. This feature provides flexibility, allowing you to access and manage individual child programs as needed, independent of the main artifact transformations.',
      },
      {
        id: 5,
        description:
          'Initiates the transformation process for all listed jobs in the Transformation Queue. By clicking this button, you can start migrating all artifacts collectively, ensuring the relevant transformation rules to each one.',
      },
      {
        id: 6,
        description:
          'Stops any ongoing transformation processes for all artifacts in the queue. Use this button to stop the migration of all jobs, allowing you to make adjustments or reconfigure settings before proceeding further.',
      },
      {
        id: 7,
        description:
          'Lists the names of the jobs that are ready for transformation. Each job represents a set of tasks or processes within your application that can be migrated.',
      },
      {
        id: 8,
        description:
          'Allows you to choose the specific rules or criteria applied to the job during the transformation process, ensuring that the migration aligns with your application’s requirements.',
      },
      {
        id: 9,
        description:
          "View the call chain for the job using the 'View Callchain' option, which provides a graphical representation of how the job interacts with other programs. Having someone with mainframe knowledge on hand can be helpful for analyzing these call chain diagrams and understanding the finer details of program interactions.",
      },
      {
        id: 10,
        description:
          'Allows you to initiate the transformation of the job. Toggle this option to start the migration process for the specific job, converting it according to the applied transformation rules.',
      },
      {
        id: 11,
        description:
          'Lists the names of the driver programs that are ready for transformation.',
      },
      {
        id: 12,
        description:
          'This column provides a checkbox option for each CICS Driver program. Selecting the checkbox will bundle the relevant program as a service and initiate the download process. If the checkbox is left unselected, the program will not be downloaded as a service.',
      },
      {
        id: 13,
        description:
          'View the call chain for the driver program using the "View Callchain" option, which provides a graphical representation of how the driver program interacts with other programs. Having someone with mainframe knowledge available can assist in analyzing this representation and understanding the complexities of program interactions.',
      },
      {
        id: 14,
        description:
          'Allows you to initiate the migration of the driver program. Toggle this option to start the migration process for the specific driver program.',
      },
      {
        id: 15,
        description:
          'Lists the names of the specific child programs that are ready for transformation.',
      },
      {
        id: 16,
        description:
          'Allows you to choose the specific rules or criteria applied to the child program during the transformation process, ensuring that the migration aligns with your application’s requirements.',
      },
      {
        id: 17,
        description:
          "View the call chain for the specific child program using the 'View Callchain' option, which provides a graphical representation of how the child program interacts with other programs. Having mainframe expertise can be beneficial for understanding the interactions and dependencies detailed in this view.",
      },
      {
        id: 18,
        description:
          'Allows you to initiate the migration of the child program. Toggle this option to start the migration process for the specific child program, converting it according to the applied transformation rules.',
      },
      {
        id: 19,
        description:
          'Initiates the transformation process for all listed CICS driver programs in the Transformation Queue. By clicking this button, you can start migrating all artifacts collectively.',
      },
      {
        id: 20,
        description:
          'Stops any ongoing transformation processes for all artifacts in the queue. Use this button to stop the migration of all CICS driver programs, allowing you to make adjustments or reconfigure settings before proceeding further.',
      },
      {
        id: 21,
        description:
          'Initiates the transformation process for all listed child programs in the Child Libraries section. By clicking this button, you can start migrating all artifacts collectively, ensuring the relevant transformation rules to each one.',
      },
      {
        id: 22,
        description:
          'Stops any ongoing transformation processes for all artifacts in the Child Libraries section. Use this button to stop the migration of all Child programs, allowing you to make adjustments or reconfigure settings before proceeding further.',
      },
      {
        id: 23,
        description:
          'Lists the names of the CICS driver programs that are ready for transformation.',
      },
      {
        id: 24,
        description:
          'View the call chain for the driver program using the "View Callchain" option, which provides a graphical representation of how the CICS driver program interacts with other programs. Having mainframe expertise can be valuable for understanding the detailed interactions and dependencies illustrated in this view.',
      },
    ],
    ['INFORMATION_CENTER']: [
      {
        id: 1,
        description:
          'The Information Center provides valuable insights and resources guiding you through each phase of the process for modernization. This interactive step-by-step wizard helps to ensure a smooth and efficient transition from legacy systems to modern solutions.',
      },
      {
        id: 2,
        description:
          'Click this button to access the Missing Artifact Workspace, where you can resolve any missing artifacts identified during the Readiness phase. Please address all missing artifacts before proceeding to the Transform phase. This ensures a smooth and complete transformation process.',
      },
    ],
    ['CALL_CHAIN']: [
      {
        id: 1,
        description:
          'Select the desired program or JCL from the dropdown to view its call chain in the canvas. This allows you to focus on specific artifacts and analyze their relationships within the system.',
      },
      {
        id: 2,
        description:
          "This represents the elements displayed in the canvas, offering an easy reference to understand the different components of your program's structure through color codes.",
      },
      {
        id: 3,
        description:
          "Click this button to automatically adjust the view, ensuring the entire graph fits within the canvas. This helps you get a comprehensive view of the selected artifact's call chain.",
      },
      // {
      //     id:4,
      //     description:"Click this button to exit the interactive canvas view and navigate back to the overall assessment report page."
      // },
    ],
     ['PROJECT_TRANSFORM_SETTINGS'] : [
            {
                id:1,
                description:"This parameter allows you to specify the system routine 'CEE3ABD' code that will be used during the transformation of Java code. The value can range from 1 to 4095. If no value is provided, the default value of 4095 is applied. Configure this parameter to align with the existing COBOL specifications, if not already set."
            },
            {
                id:2,
                description:"This parameter lets you customize the package structure for your programs. By default, programs are saved as 'com.cloudframe.{programid}.dto'. However, admin can modify this to reflect your enterprise naming conventions."
            },
            {
                id:3,
                description:"The Compile Java option allows you to decide whether to compile the generated Java code after transformation. By default, this option is set to “Yes”, ensuring that CodeNavigator compiles the code post-transformation. However, you can change this setting to “No” if you prefer not to compile the Java code after the transformation."
            },
            {
                id:4,
                description:"The Generate Control Advice option lets you decide whether to include Spring control advice in the transformed Java code. This option is applicable only for CICS-based programs and should always be set to “Yes” when transforming a CICS-based application. When enabled, the generated program will incorporate Spring control advice to manage cross-cutting concerns like logging, transaction management, and exception handling in a more modular and maintainable way."

            },
            {
                id:5,
                description:"When this option is enabled, the corresponding COBOL lines are added as comments alongside the Java code, helping you map the generated Java to its original COBOL source. The Add COBOL Lines option is set to 'No' by default. We recommend enabling this option to simplify debugging and issue identification, by providing clear references to the original COBOL code."
            },
            {
                id:6,
                description:"The Stateless Pojo option is set to 'Yes' by default, which generates Java code in a streamlined structure, ensuring better modularity and maintainability. This is particularly useful for CICS-based programs, where operations like ASSIGN, LINK, and GET & PUT are transformed into modular methods. These methods help maintain the original program logic while making the code easier to manage. When enabled, this option generates interfaces for CICS programs and adds method-level context to handle specific operations like ASSIGN and LINK. This approach enhances code clarity and flexibility, ensuring that transactional tasks are handled smoothly during transformation."
            }
        ],
        ['EXPERIMENTAL_CONFIG_SETTINGS'] : [
          {
              id:1,
              description:"This parameter determines whether the generated DTO classes include annotations for getters and setters. When set to “Yes”, DTO classes will be generated with annotations, providing metadata that can be used by frameworks like Spring for dependency injection, validation, and database mapping. Conversely, setting this option to “No” (default) will generate DTO classes as plain POJOs without annotations. While this results in simpler, more lightweight classes, it may limit the functionality available for framework integration."
          },
          {
              id:2,
              description:"The Cache INITIALIZE Statement option improves performance by caching initialized values during the transformation process. By default, this option is set to No, meaning that initialized values are not cached. Enabling this option can enhance performance, but it requires careful consideration. In some cases, it may lead to the removal of old filler values, which could impact the behavior of your transformed programs. It is recommended to review this setting with the CloudFrame team before enabling it, to ensure it aligns with your specific use case and requirements."
          },
          {
              id:3,
              description:"The Flatten DTO’s option allows you to decide whether the generated Java code should be formatted for improved readability. When set to “Yes”, the generated code will not only be formatted for better clarity but also flatten all variables into a single bean. This means that all child variables will be consolidated under their topmost parent variable within the DTO package. The default setting is “No”, giving you flexibility in maintaining your preferred code structure and formatting style."
          },
          {
              id:4,
              description:"The Ignore CICS Error Handling option allows you to control whether the generated Java code includes error handling for CICS response codes during the COBOL to Java transformation process. When this option is set to Yes, the generated Java code will exclude error-handling logic related to CICS. When this option is set to No (default setting), the generated Java code will include detailed CICS error-handling logic. The code will check for CICS response errors and manage them appropriately, such as throwing exceptions if an error is detected."
          },
          {
              id:5,
              description:"The Generate Custom SQLCA option allows you to generate a custom SQLCA in the transformed code with updated, modern messages, which enhances the clarity of database communication and improves error handling. By default, the standard SQLCA is used."
          },
          {
              id:6,
              description:"This option controls the visibility of warnings related to unused variables and imports in the transformed Java code. When set to 'No' (default), these warnings will remain visible in the migrated code. Setting it to 'Yes' will suppress these warnings, hiding any unused variables and imports to improve code cleanliness."
          }
      ],
    [EAppIntent.MISSING_SYMBOLS]: [
      {
        id: 1,
        description:
          'This column lists the missing JCL symbols that need to be defined for the transformation process.',
      },
      {
        id: 2,
        description:
          'This column indicates the specific JCL or PROC that requires the corresponding symbol. It helps you identify where each symbol is needed in the transformation process.',
      },
      {
        id: 3,
        description:
          'Please enter the value for the corresponding missing symbol in this field. The value you provide will be used to replace the missing symbol in the corresponding JCL or PROC.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Control Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.',
      },
      {
        id: 8,
        description:
          'Click this option to download a CSV template. The template allows you to input symbol values offline. Once completed, you can upload the CSV to automatically populate the symbol values on the page.',
      },
      {
        id: 9,
        description:
          'Use this option to upload a completed CSV file with the symbol values. The uploaded file will populate the symbol values on the page, saving you time from entering them manually.',
      },
      {
        id: 10,
        description:
          'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.',
      },
      {
        id: 11,
        description:
          'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.',
      },
      {
        id: 12,
        description:
          'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.',
      },
    ],
    [EAppIntent.ARTIFACT_UPLOAD]: [
      {
        id: 1,
        description:
          'Clicking on this button will validate the uploaded files. Please click this button after uploading the necessary artifacts for migration.',
      },
      {
        id: 2,
        description:
          'Click this button once you have successfully uploaded the artifacts extracted from your mainframe. This will move you forward to the next step in the modernization process.',
      },
    ],
    [EAppIntent.MISSING_METADATA]: [
      {
        id: 1,
        description:
          'Check this option to ignore all the listed missing Metadata in the current transformation process. You can also select individual rows to ignore specific Metadata.',
      },
      {
        id: 2,
        description:
          'This column indicates the specific JCL or PROC that depends on the listed Metadata. It helps you identify which Metadata items are essential for the correct execution of the JCL or PROC.',
      },
      {
        id: 3,
        description:
          'This column lists the names of the missing Metadata that are required for the transformation process. Each Metadata entry represents a set of data definitions or parameters needed by the JCL or another Metadata item.',
      },
      {
        id: 4,
        description:
          'This column specifies the length of each record in the dataset. Enter the appropriate value to ensure the correct processing of data during transformation.',
      },
      {
        id: 5,
        description:
          'This column indicates the format of the records in the dataset (e.g., Fixed, Variable). Select the correct format to match your dataset’s structure for accurate transformation.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Metadata at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This represents the name of the data cluster associated with the missing artifact. Ensure that the correct cluster name is provided to accurately link the metadata during the transformation process.',
      },
      {
        id: 8,
        description:
          'This option allows you to generate the JCL needed to extract the missing Metadata from the mainframe. The system will generate the JCL based on the provided dataset names and ISPF libraries. It is advisable to have someone with mainframe knowledge for providing the dataset information.',
      },
      {
        id: 9,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing Metadata information. The "Upload Metadata" option lets you manually upload the missing Metadata files from your local system. The "Extract Metadata from Mainframe" option enables you to enter the dataset names and ISPF data to initiate the extraction process of the missing Metadata from mainframe.',
      },
      {
        id: 10,
        description:
          'To identify your mainframe ISPF libraries, please follow the steps provided in this article',
      },
    ],
    [EAppIntent.SMART_DOC] : [
      {
        id:1,
        description: 'The SmartDoc page displays a list of your COBOL programs, along with options to visualize call chains and SmartDoc for each program. This help you gain a clear understanding of the structure and flow of your COBOL programs.'
      },
      {
        id:2,
        description: 'Displays the name of the uploaded artifact for which SmartDoc is generated. Use the search bar to quickly locate the artifact by its name.'
      },
      {
        id:3,
        description: "Click to open a detailed SmartDoc report for the selected artifact."
      },
      {
        id:4,
        description: "Click to navigate to the Deploy stage and deploy the generated SmartDoc report to a GitHub repository. This button is enabled only after successful SmartDoc generation."
      },
      {
        id:5,
        description: "Displays the progress and outcome of SmartDoc generation for both Basic and Enhanced versions. A Yellow Spinner indicates that the SmartDoc is being generated, a Green Tick signifies successful SmartDoc generation, a Cross Mark indicates a failure in the generation process, and a Question Mark appears if the Enhanced SmartDoc is unavailable due to missing OpenAI configuration."
      },
      {
        id:6,
        description: "Visible only when SmartDoc generation fails for one or more artifacts. Click to retry generating SmartDoc for the failed artifacts."
      },
    ],
    [EAppIntent.TEST_CHEVRON] : [
      {
        id:1,
        description: 'Click the arrow icon to expand or collapse the section and view the Input/Output files associated with the job. Expanding this section will allow you to upload the output files to compare.'
      },
      {
        id:2,
        description: 'Displays the name of the job available for validation.'
      },
      {
        id:3,
        description: 'Indicates the current status of the validation process. Initially, this status appears as a solid amber circle representing “Pending”. After uploading the file, a green checkmark indicates successful functional equivalence. Whereas, a red cross indicates failure in the functional equivalence process.'
      },
      {
        id:4,
        description: 'Click this button to export the mainframe JCL necessary to generate the input and output files for the specified job. Having someone with mainframe expertise can be helpful for understanding the structure of the exported JCL for this job. '
      },
      {
        id:5,
        description: "Displays the name of the dataset that corresponds to the output files for the job. Each dataset has an unique name based on the output structure of your application."
      },
      {
        id:6,
        description: 'Indicates the total length of each record in the dataset, measured in bytes.'
      },
      {
        id:7,
        description: 'Specifies the format in which the records are stored within the dataset. Common formats include "Fixed Block" and "Variable Block”.'
      },
      {
        id:8,
        description: 'Identifies the origin of the output file. The source can be either Mainframe, Java, or a combination of both, indicating that the comparison will be made between files from these sources.'
      },
      {
        id:9,
        description: 'Click the "Compare" button to upload both the mainframe and Java output files, and perform a functional equivalence comparison. The tool will check for discrepancies between the two output files to confirm successful validation.'
      },
    ],
    [EAppIntent.DEPLOY_PAGE] : [
      {
        id:1,
        description: 'Displays the name of the transformed code artifact ready for deployment.'
      },
      {
        id:2,
        description: 'Shows the current status of the deployment process for each artifact.'
      },
      {
        id:3,
        description: 'Indicates the date and time of the most recent deployment of the artifact.'
      },
      {
        id:4,
        description: 'Allows you to push the selected artifact to the GitHub repository or view the deployed code.'
      },
      {
        id:5,
        description: "Initiates the deployment process of the transformed artifact to the configured GitHub repository."
      },
      {
        id:6,
        description: 'Opens the repository in GitHub to review the deployed code.'
      },
    ],
    ['SYSTEM_SETTINGS']: [
      {
        id: 1,
        description:
          'Set the maximum time (in minutes) allowed for artifact validation during the upload process. Adjust this limit as needed to avoid timeout issues during artifact validation.',
      },
    ],
    ['DEPLOY_SETTINGS']: [
      {
        id: 1,
        description:
          'Enter the GitHub access token to enable secure deployment of transformed code to the GitHub repository. Ensure that the token provides the necessary repository permissions for Project Lead and Project Member users.',
      },
    ],
    ['SMARTDOC_SETTINGS']: [
      {
        id: 1,
        description:
          "Choose the desired Large Language Model (LLM) for SmartDoc functionality. Select 'None' to disable LLM-based features or “OpenAI” to enable OpenAI-powered capabilities.",
      },
      {
        id: 2,
        description:
          "Enter your OpenAI API key to authenticate and enable communication with OpenAI's services. Ensure the key has appropriate access permissions for the selected model.",
      },
      {
        id: 3,
        description:
          "Specify the OpenAI API base URL. Use this to define the endpoint for connecting to OpenAI's services, typically “https://api.openai.com/v1”.",
      },
      {
        id: 4,
        description:
          "OpenAI Model: Displays the AI model associated with the provided OpenAI key.",
      },
    ],
  },
];
