import { MoreHorizontal, ChevronDown, ChevronRight, Slash } from 'lucide-react';
import { Avatar, AvatarFallback } from '../../components/ui/avatar';
import { Button } from '../../components/ui/button';
import { Card, CardContent } from '../../components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '../../components/ui/breadcrumb';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import { getAvatarInitials } from 'src/utils/misc.utils';
import { IProjectApiObject } from '../dashboard/dashboard.service';
import { StringUtils } from 'src/utils/string.utils';
import { useUserProfile } from './useUserProfile';
import CreateNewUserModal from '../admin/users/create-new-user';
import { AddTeamMembers } from '../admin/drafts/components/add-memebers/add-members';
import ProjectCreation from '../project-creation/create-project';

export default function UserProfile() {
  const {
    userProfile,
    showUserModal,
    handleEditUser,
    loading: isLoading,
    error,
    userId,
    setShowUserModal,
    handleUserActivation,
    handleRemoveFromProject,
    projectsUserLeading,
    showEditProjectRoleModal,
    setShowEditProjectRoleModal,
    handleUserProjectRoleUpdate,
    refetchUserProject,
    selectedProject,
    showEditProjectModal,
    setShowEditProjectModal,
    handleEditProject,
  } = useUserProfile();

  const navigate = useNavigate();

  const [alertShown, setAlertShown] = useState({ profileAlert: false });

  if (!isLoading && !!error && !alertShown.profileAlert) {
    console.log('Error Loading profile', error);
    setAlertShown({ profileAlert: true });
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Failed to load profile! Try again later',
    });
    return null;
  }

  if (isLoading) {
    return (
      <div
        className="flex items-center justify-center"
        style={{ minHeight: 'calc(100% - 70px)' }}
      >
        <p>Loading...</p>
      </div>
    );
  }

  if (!isLoading && !userProfile.user) {
    navigate(ROUTES.USERS);
    return null;
  }

  return (
    <div
      className="mx-auto p-6 pt-4 "
      style={{ minHeight: 'calc(100% - 70px)' }}
    >
      <div className="mb-8">
        <Breadcrumb className="bg-white p-4 rounded-md shadow-md">
          <BreadcrumbList className="flex items-center space-x-2">
            <BreadcrumbItem>
              <Link
                className="text-blue-600 text-base hover:text-blue-800 transition duration-200"
                to={ROUTES.USERS}
              >
                Users
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator>
              <Slash className="text-gray-400" />
            </BreadcrumbSeparator>
            <BreadcrumbItem>
              <p className="text-base font-semibold text-gray-700">
                User Profile
              </p>
            </BreadcrumbItem>
          </BreadcrumbList>
          <hr className="mt-4" />
          <h1 className="text-2xl font-semibold mt-4">
            {userProfile.user?.name}
          </h1>
        </Breadcrumb>
      </div>

      <div className="flex justify-between items-start mb-2">
        {/* <h1 className="text-2xl font-semibold">{userProfile.user.name}</h1> */}
        {/* <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Edit user</DropdownMenuItem>
            <DropdownMenuItem>Delete user</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> */}
      </div>

      {/* <p className="text-muted-foreground mb-8">Last active on Oct 29, 2024.</p> */}

      <div className="grid md:grid-cols-[300px,1fr] gap-8">
        <div className="max-h-fit">
          <Card className="shadow-lg rounded-lg border border-gray-200">
            <CardContent className="p-4">
              <div className="flex flex-col items-center text-center mb-4">
                <Avatar className="h-24 w-24 mb-2 border-2 border-blue-500">
                  <AvatarFallback className="text-3xl">
                    {getAvatarInitials(userProfile.user?.name || '')}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className="space-y-4 ">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">
                    Full Name
                  </h3>
                  <p className="text-gray-600">{userProfile.user?.name}</p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">
                    Role
                  </h3>
                  <p className="text-gray-600">
                    {StringUtils.capitalize(userProfile.user?.role || '')}
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">
                    Email Address
                  </h3>
                  <p className="text-gray-600">{userProfile.user?.email}</p>
                </div>

                <div className="pt-4 mt-auto">
                  <Button
                    variant="default"
                    className="w-full border-blue-500 bg-blue-500 text-white transition duration-200 "
                    onClick={handleEditUser}
                  >
                    Modify Details
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="space-y-8">
          <section className="bg-white shadow-md rounded-lg p-6 flex flex-col flex-grow min-h-[425px]">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h2 className="text-2xl font-bold mb-2 text-gray-800">
                  Application Access
                </h2>
                <p className="text-gray-600 flex items-center gap-2">
                  Manage user roles and application access.
                </p>
              </div>
              {userProfile.user?.active ? (
                <Button
                  onClick={() => {
                    handleUserActivation(userProfile.user?.id || 0, true);
                  }}
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold transition duration-200"
                >
                  Deactivate User
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    handleUserActivation(userProfile.user?.id || 0, false);
                  }}
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold transition duration-200"
                >
                  Activate User
                </Button>
              )}
            </div>

            <Table className="min-w-full">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[300px] text-left">Application</TableHead>
                  <TableHead className="text-left">Application Type</TableHead>
                  <TableHead className="text-left">Application Role</TableHead>
                  <TableHead className="w-[100px] text-left">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {userProfile.projects?.map((project: IProjectApiObject) => {
                  const isLeadOfProject = projectsUserLeading.includes(
                    project.projectName,
                  );
                  return (
                    <TableRow
                      key={project.projectId}
                      className="hover:bg-gray-100 transition duration-200"
                    >
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <div>
                            <div className="font-semibold text-gray-800">
                              {project.projectName}
                            </div>
                            <div className="text-sm text-gray-500">
                              {project.projectStatus}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {StringUtils.capitalize(
                          project.projectType.toLowerCase(),
                        )}
                      </TableCell>
                      <TableCell>
                        {/* <Select value={isLeadOfProject ? 'LEAD' : 'MEMBER'}>
                          <SelectTrigger className="w-[180px]">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="MEMBER">
                              Project Member
                            </SelectItem>
                            <SelectItem value="LEAD">Project Lead</SelectItem>
                          </SelectContent>
                        </Select> */}
                        <Button
                          variant="outline"
                          size="sm"
                          className="text-gray-700"
                          onClick={() => {
                            handleUserProjectRoleUpdate(project);
                          }}
                        >
                          {isLeadOfProject ? 'Application Lead' : 'Application Member'}
                          <ChevronDown className="ml-2 h-4 w-4" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              size="default"
                              className="text-gray-700"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              onClick={() => handleEditProject(project)}
                            >
                              Edit Application
                            </DropdownMenuItem>
                            {/* <DropdownMenuItem
                              onClick={() => {
                                handleRemoveFromProject(
                                  +userId,
                                  project.projectId as any,
                                  projectsUserLeading.includes(
                                    project.projectName,
                                  ),
                                );
                              }}
                            >
                              Remove From Project
                            </DropdownMenuItem> */}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!userProfile.projects?.length && (
              <div className="flex items-center justify-center min-h-[240px]">
                <p>No applications found</p>
              </div>
            )}
          </section>
        </div>
      </div>
      {showUserModal && (
        <CreateNewUserModal
          onSubmit={() => setShowUserModal(false)}
          onClose={() => setShowUserModal(false)}
          user={userProfile.user}
        />
      )}
      {showEditProjectRoleModal && selectedProject && (
        <AddTeamMembers
          onClose={() => setShowEditProjectRoleModal(false)}
          project={selectedProject}
          refetchProjects={refetchUserProject as any}
        />
      )}
      {showEditProjectModal && selectedProject && (
        <ProjectCreation
          selectedProject={selectedProject}
          isEditing={true}
          isOpen={showEditProjectModal}
          onClose={() => setShowEditProjectModal(false)}
          onSubmitEdit={refetchUserProject}
        />
      )}
    </div>
  );
}
