import { Tooltip } from "antd";
import React, { useState } from "react";
import { FaBitbucket, FaCheck, FaChevronDown, FaChevronUp, FaGithub, FaGitlab, FaTimes } from "react-icons/fa";
import HelpTextCallout from "src/components/help-text-callout/help-text-callout";
import { helpTexts } from "src/helpTexts";
import DeployProgress from "./modal-deploy/deploy-progress-table";
import { appTheme } from "src/styles/theme";
import ModalDeploy from "./modal-deploy/modal-deploy";
import Swal from "sweetalert2";
import { useGetRepositoryDataQuery } from "src/redux/api-slice";

const DeployTable = ({ deployData, setData, repoConfig }) => {
    const [isCollapsed, setIsCollapsed] = useState<{ [key: string]: boolean }>({});
    const [isRepoModalVisible, setRepoModalVisible] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState('github');
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [rowIndex, setRowIndex] = useState(-1);
    const [isInProgress, setInProgress] = useState(false);
    const [sectionTitle, setSectionTitle] = useState('');
    const [singleRepoUrl, setSingleRepoUrl] = useState('');
    const pageHelpText = helpTexts[0].DEPLOY_PAGE;
    const platformTitles = {
        github: 'GitHub',
    };
    const showModal = (index: number, sectionTitle: string) => {
        setRowIndex(index);
        setSectionTitle(sectionTitle);
        handleRepoModalOpen('github');
    };

    const handleRepoModalOpen = (platform: string) => {
        setSelectedPlatform(platform);
        setRepoModalVisible(true);
    };
    const formSubmitted = () => {
        setIsFormSubmit(true);
        setRepoModalVisible(false);
        startProgress();
    }
    const checkForSection = (title: string) => {
        if (title === 'transformation')
            return repoConfig.useSingleRepoForTransform;
        if (title === 'smartDoc')
            return repoConfig.useSingleRepoForSmartDoc;
        else
            return true;
    }
    const postValidation = (
        status: boolean,
        responseData?: Array<{
            artifactName: string;
            deployedDate: string;
            deployUrl: string;
            deployStatus: string;
            deployType: string;
            errorMessage: string;
        }>
    ) => {
        const titleMapping = {
            "SMART_DOC": "smartDoc",
            "TRANSFORMATION": "transformation",
            "READINESS": "readiness"
        }
        setData((prevData) => {
            const updatedData = prevData.map((section) => {
                if (responseData && status) {
                    const matchingResponses = responseData.filter(
                        (item) => titleMapping[item.deployType] === section.title
                    );

                    if (matchingResponses.length > 0) {
                        const updatedRows = section.rows.map((row) => {
                            const matchingResponse = matchingResponses.find(
                                (res) => res.artifactName === row.jobName
                            );
                            if (repoConfig.useSingleRepository && matchingResponse?.deployUrl) {
                                setSingleRepoUrl(matchingResponse.deployUrl);
                            }
                            if (matchingResponse) {
                                return {
                                    ...row,
                                    isProgress: false,
                                    success: matchingResponse.deployStatus === "PASS",
                                    deployStatus: matchingResponse.deployStatus,
                                    completionDate: matchingResponse.deployedDate,
                                    deployUrl: matchingResponse.deployUrl,
                                    errorMessage: matchingResponse.errorMessage
                                };
                            }

                            return row;
                        });

                        return { ...section, rows: updatedRows };
                    }
                }

                if (!status) {
                    if (repoConfig.useSingleRepository) {
                        const updatedRows = section.rows.map((row) => ({
                            ...row,
                            isProgress: false,
                            success: false,
                            deployStatus:'FAIL'
                        }));
                        return { ...section, rows: updatedRows };
                    } else if (sectionTitle === "smartDoc" && section.title === "smartDoc") {
                        if (repoConfig.useSingleRepoForSmartDoc) {
                            const updatedRows = section.rows.map((row) => ({
                                ...row,
                                isProgress: false,
                                success: false,
                            deployStatus:'FAIL'
                            }));
                            return { ...section, rows: updatedRows };
                        } else {
                            const updatedRows = section.rows.map((row, rIndex) =>
                                rIndex === rowIndex
                                    ? {
                                        ...row,
                                        isProgress: false,
                                        success: false,
                                        deployStatus:'FAIL'
                                    }
                                    : row
                            );
                            return { ...section, rows: updatedRows };
                        }
                    } else if (
                        sectionTitle === "transformation" &&
                        section.title === "transformation"
                    ) {
                        if (repoConfig.useSingleRepoForTransform) {
                            const updatedRows = section.rows.map((row) => ({
                                ...row,
                                isProgress: false,
                                success: false,
                                deployStatus:'FAIL'
                            }));
                            return { ...section, rows: updatedRows };
                        } else {
                            const updatedRows = section.rows.map((row, rIndex) =>
                                rIndex === rowIndex
                                    ? {
                                        ...row,
                                        isProgress: false,
                                        success: false,
                                        deployStatus:'FAIL'
                                    }
                                    : row
                            );
                            return { ...section, rows: updatedRows };
                        }
                    } else if (sectionTitle === "readiness" && section.title === "readiness") {
                        const updatedRows = section.rows.map((row, rIndex) =>
                            rIndex === rowIndex
                                ? {
                                    ...row,
                                    isProgress: false,
                                    success: false,
                                    deployStatus:'FAIL'
                                }
                                : row
                        );
                        return { ...section, rows: updatedRows };
                    }
                }

                return section;
            });

            return updatedData;
        });
        setInProgress(false);
    };
    const startProgress = () => {
        const newData = [...deployData];
        setInProgress(true);
        if (repoConfig.useSingleRepository) {
            newData.forEach((section) => {
                section.rows
                    .filter(row => row.deployStatus !== 'PASS')
                    .forEach((row) => {
                        row.isProgress = true;
                    });
            });
        } else {
            newData.forEach((section) => {
                if (sectionTitle === 'smartDoc' && section.title === 'smartDoc') {
                    if (repoConfig.useSingleRepoForSmartDoc) {
                        section.rows.filter(row => row.deployStatus !== 'PASS').forEach((row) => {
                            row.isProgress = true;
                        });
                    } else {
                        section.rows[rowIndex].isProgress = true;
                    }
                } else if (sectionTitle === 'transformation' && section.title === 'transformation') {
                    if (repoConfig.useSingleRepoForTransform) {
                        section.rows.filter(row => row.deployStatus !== 'PASS').forEach((row) => {
                            row.isProgress = true;
                        });
                    } else {
                        section.rows[rowIndex].isProgress = true;
                    }
                } else if (sectionTitle === 'readiness' && section.title === 'readiness') {
                    section.rows[rowIndex].isProgress = true;
                }
            });
        }
        setData(newData);
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };
    const toggleCollapse = (sectionKey: string) => {
        setIsCollapsed((prevCollapsed) => ({
            ...prevCollapsed,
            [sectionKey]: !prevCollapsed[sectionKey],
        }));
    };
    return (
        <div>
            <div className="mb-2">
                {repoConfig?.useSingleRepository && (
                    <div className="flex gap-2 items-center justify-end shadow-md p-4 border-t border-gray-300">
                        {deployData.some((section) =>
                            section.rows.some((row) => row.deployStatus !== 'PASS')
                        ) && (
                                <div>
                                    <div className="flex justify-end mx-auto">
                                        <button
                                            className={`flex items-center cursor-pointer text-sm bg-[#2ea44f] text-white px-4 py-2 rounded-md border border-transparent shadow-sm hover:bg-[#2c974b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2ea44f] transition-colors duration-200 ${isInProgress ? 'opacity-50' : ''
                                                }`}
                                            onClick={() => showModal(0, 'All')}
                                            disabled={isInProgress}
                                        >
                                            Deploy All Artifacts to a Single Repository
                                        </button>
                                    </div>
                                </div>
                            )}
                    </div>
                )}
            </div>
            {deployData.map((item, index) => (
                <div key={index}>
                    <div style={{ borderBottom: '1px solid ' + appTheme.colors.grey20 }}>
                        <div
                            className="pt-4 ml-0 flex items-center justify-between bg-gray-100 p-4 rounded-md shadow-md"
                            onClick={() => toggleCollapse(item.title)}
                        >
                            <h2 className="text-2xl font-semibold text-gray-800">{item.title.charAt(0).toUpperCase() + item.title.slice(1)} Data</h2>
                            <button className="ml-2 text-gray-600 hover:text-gray-800 transition-colors duration-200">
                                {isCollapsed[item.title] ? <FaChevronDown /> : <FaChevronUp />}
                            </button>
                        </div>
                        {!isCollapsed[item.title] && (
                            <table className="w-full caption-bottom text-sm mb-4 mt-4">
                                <thead className="[&amp;_tr]:border-b ">
                                    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                        <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]">
                                            <div className='flex items-center gap-2 justify-start'>Artifact Name<HelpTextCallout calloutText={pageHelpText[0].description} /></div>
                                        </th>
                                        <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                            <div className='flex items-center gap-2 justify-center ml-4'>Deployment Status<HelpTextCallout calloutText={pageHelpText[1].description} /></div>
                                        </th>
                                        <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                            <div className='flex items-center gap-2 justify-center ml-4'>Latest Deployment<HelpTextCallout calloutText={pageHelpText[2].description} /></div>
                                        </th>
                                        <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[150px]">
                                            <div className='flex items-center gap-2 justify-center'>Deploy<HelpTextCallout calloutText={pageHelpText[3].description} /></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="[&amp;_tr:last-child]:border-0">
                                    {item?.rows?.map((job, jindex) => (
                                        <React.Fragment key={jindex}>
                                            <tr
                                                className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted`}
                                            >
                                                <td className="p-4 align-middle text-left font-medium w-[250px]">
                                                    {job.jobName}
                                                </td>
                                                <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                                    {job.success ? (
                                                        <FaCheck className="text-green-500 inline-block" title='Success' />
                                                    ) : (
                                                        job.deployStatus === 'FAIL' ? (
                                                            <Tooltip
                                                                title={`${job.errorMessage}`}
                                                                overlayInnerStyle={{
                                                                    backgroundColor: '#000',
                                                                    borderRadius: '8px',
                                                                    color: '#fff',
                                                                    padding: '8px',
                                                                    fontSize: '14px',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <FaTimes className="text-red-500 inline-block"/>
                                                            </Tooltip>
                                                        ) : <div className='text-sm rounded-full bg-yellow-500 w-3.5 h-3.5 inline-block' title='Pending...'></div>
                                                    )}
                                                </td>
                                                <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                                    {job.completionDate ? (
                                                        <div className="text-gray-500 text-sm font-semibold">
                                                            {job.completionDate}
                                                        </div>
                                                    ) : (
                                                        <div className="text-gray-400 text-sm font-semibold">
                                                            ----
                                                        </div>
                                                    )}
                                                </td>
                                                {((!checkForSection(item.title)) || jindex === 0 || (checkForSection(item.title) && job.deployStatus !== 'PASS' && deployData[index]?.rows?.findIndex((row) => row.deployStatus !== 'PASS') === jindex)) && (
                                                    <td
                                                        className="p-4 align-middle w-[250px] text-center"
                                                        rowSpan={
                                                            checkForSection(item.title)
                                                                ? job.success && job.deployUrl !== ''
                                                                    ? deployData[index]?.rows?.filter((row) => row.deployStatus === 'PASS').length || 1
                                                                    : deployData[index]?.rows?.filter((row) => row.deployStatus !== 'PASS').length || 1
                                                                : 1
                                                        }
                                                    >
                                                        {job.success && job.deployUrl !== '' ? (
                                                            <div className="flex gap-2 items-center justify-center ml-4">
                                                                <a
                                                                    href={job.deployUrl}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="flex items-center cursor-pointer text-sm bg-[#2ea44f] text-white px-4 py-2 rounded-md border border-transparent shadow-sm hover:bg-[#2c974b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2ea44f] transition-colors duration-200"
                                                                >
                                                                    <span className="mr-2">View in {platformTitles[selectedPlatform]}</span>
                                                                    {platformIcons[selectedPlatform]}
                                                                </a>
                                                                <HelpTextCallout calloutText={pageHelpText[5].description} />
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-center ml-4">
                                                                <Tooltip
                                                                    title={`${repoConfig?.useSingleRepository
                                                                            ? "All artifacts of all the stages are configured by the admin to be deployed to a single repository. Click on the 'Deploy All Artifacts to a Single Repository' button to proceed with deployment. Contact admin/CloudFrame Support Team if you need any assistance."
                                                                            : checkForSection(item.title)
                                                                                ? 'All artifacts are configured by the admin to be deployed to a single repository. Contact admin/CloudFrame Support Team if you need any assistance.'
                                                                                : 'All artifacts are configured by the admin to be deployed to separate repositories. Contact admin/CloudFrame Support Team if you need any assistance.'
                                                                        }`}
                                                                    overlayInnerStyle={{
                                                                        backgroundColor: '#000',
                                                                        borderRadius: '8px',
                                                                        color: '#fff',
                                                                        padding: '8px',
                                                                        fontSize: '14px',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    <div className="flex gap-2 items-center justify-center text-sm ml-4">
                                                                        <button
                                                                            className={`bg-cta text-white p-2 rounded-md ${(isInProgress || repoConfig.useSingleRepository) ? 'opacity-50' : ''
                                                                                }`}
                                                                            onClick={() => showModal(jindex, item.title)}
                                                                            disabled={isInProgress || repoConfig.useSingleRepository}
                                                                        >
                                                                            {job.deployStatus === 'FAIL' ? 'Retry Deploy' : 'Deploy Job'}
                                                                        </button>
                                                                        <HelpTextCallout calloutText={pageHelpText[4].description} />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                            {isFormSubmit && job.isProgress && (
                                                <tr className="bg-white">
                                                    <td className="p-4 bg-gray-100" colSpan={5}>
                                                        <div className="p-6 border bg-white border-blue-200 shadow-lg rounded-lg">
                                                            <DeployProgress
                                                                selectedPlatform={selectedPlatform}
                                                                job={job}
                                                                setData={setData}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {isRepoModalVisible && <ModalDeploy
                            isRepoModalVisible={isRepoModalVisible}
                            setRepoModalVisible={setRepoModalVisible}
                            selectedPlatform={selectedPlatform}
                            formSubmitted={formSubmitted}
                            deployData={deployData}
                            index={rowIndex}
                            sectionTitle={sectionTitle}
                            postValidation={postValidation}
                            repoConfig={repoConfig}
                        />}
                    </div>
                </div>
            ))}
        </div>
    )
}
export default DeployTable;