import { useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { FaNoteSticky } from 'react-icons/fa6';
import { FiFileText, FiSettings } from 'react-icons/fi';
import { SiJira } from 'react-icons/si';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import MemberOnly from '../member-only/member-only';
import TransformTypeOnly from '../transform-type-only/transform-app-type-only';

const ProjectSideBar = ({ activeScreen }) => {
  const location = useLocation();

  const [activeIcon, setActiveIcon] = useState(activeScreen);
  const navigate = useNavigate();

  const routesMap = {
    Home: ROUTES.PROJECT_OVERVIEW,
    Settings: ROUTES.PROJECT_TRANSFROMATION_SETTINGS,
    Reports: ROUTES.PROJECT_REPORT,
    Notes: ROUTES.NOTES,
    Jira: ROUTES.SUPPORT_TICKETS,
  };

  const iconClass = iconName =>
    iconName === activeIcon ? 'text-cta' : 'text-gray-500';

  const onClickItem = (screen: string) => {
    setActiveIcon(screen);
    if (routesMap[screen]) {
      navigate(
        location.search
          ? `${routesMap[screen]}${location.search}`
          : routesMap[screen],
      );
    }
  };

  return (
    <div className="absolute bg-white w-8 mt-4 left-2 px-6 py-6 flex flex-col items-center gap-8 shadow-lg border rounded-md">
      <FaHome
        title="Home"
        size={24}
        className={`cursor-pointer ${iconClass('Home')}`}
        onClick={() => onClickItem('Home')}
      />
      <FaNoteSticky
        title="Notes"
        size={24}
        className={`cursor-pointer ${iconClass('Notes')}`}
        onClick={() => onClickItem('Notes')}
      />
      <FiFileText
        title="Reports"
        size={24}
        className={`cursor-pointer ${iconClass('Reports')}`}
        onClick={() => onClickItem('Reports')}
      />
      <SiJira
        title="Jira"
        size={24}
        className={`cursor-pointer ${iconClass('Jira')}`}
        onClick={() => onClickItem('Jira')}
      />
      <MemberOnly>
      <TransformTypeOnly>
        <FiSettings
          title="Settings"
          size={24}
          className={`cursor-pointer ${iconClass('Settings')}`}
          onClick={() => onClickItem('Settings')}
        />
        </TransformTypeOnly>
      </MemberOnly>
    </div>
  );
};

export default ProjectSideBar;
