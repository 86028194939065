import { Button, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaBitbucket, FaGithub, FaGitlab } from "react-icons/fa";
import SVGLoader from "src/assets/images/svg/loader.icon";
import { useGetRepositoryDataQuery } from "src/redux/api-slice";
import { externalConfig } from "src/utils/misc.utils";
import { getSearchParameter } from "src/utils/url.utils";
import Swal from "sweetalert2";

interface RepositoryData {
    repoName: string;
    branchList: string[];
}

const ModalDeploy = ({ setRepoModalVisible, isRepoModalVisible, selectedPlatform, formSubmitted, deployData, index, postValidation, sectionTitle, repoConfig }) => {

    const projectId = getSearchParameter('projectId');
    const [selectedRepo, setSelectedRepo] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const { data, isLoading } = useGetRepositoryDataQuery(undefined, {
        refetchOnMountOrArgChange: true,
      });
    const [repositories, setRepositories] = useState<RepositoryData[]>([]);
    const [modalConfig, setModalConfig] = useState({
        visible: false,
        title: '',
        placeholder: '',
        actionType: '',
    });
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const fetchRepositories = async () => {
            try {
                if (data?.data) {
                    setRepositories(data?.data?.repoList);
                }
                if (data?.status === 'ERROR') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to Fetch Repositories',
                        text: `${data?.message}. Please contact CloudFrame Support Team for further assistance.`,
                        confirmButtonColor: '#f27474',
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Server Error',
                    text: 'Unable to fetch artifacts status for Deploy. Please contact CloudFrame Support Team for further assistance.',
                    confirmButtonColor: '#f27474',
                });
            }
        }
        fetchRepositories();
    }, [data]);
    
    const openModal = (actionType) => {
        setModalConfig({
            visible: true,
            title: actionType === 'repo' ? 'Create New Repository' : 'Add New Branch',
            placeholder: actionType === 'repo' ? 'Enter repository name' : 'Enter branch name',
            actionType,
        });
        setInputValue('');
    };
    const handleAdd = () => {
        if (inputValue.trim()) {
            if (modalConfig.actionType === 'repo') {
                const updatedRepositories = [...repositories, { repoName: inputValue, branchList: [] }];
                setRepositories(updatedRepositories);
                setSelectedRepo(inputValue);
                setSelectedBranch('');
            } else if (modalConfig.actionType === 'branch' && selectedRepo) {
                const updatedRepositories = repositories?.map((repo) =>
                    repo.repoName === selectedRepo
                        ? { ...repo, branchList: [...repo.branchList, inputValue] }
                        : repo
                );
                setRepositories(updatedRepositories);
                setSelectedBranch(inputValue);
            }
            setModalConfig({ ...modalConfig, visible: false });
        }
    };

    const getBranchList = () => {
        const selectedRepoData = repositories?.find((repo) => repo.repoName === selectedRepo);
        return selectedRepoData?.branchList || [];
    };

    const handleModalCancel = () => {
        setModalConfig({ ...modalConfig, visible: false });
        setInputValue('');
    };

    const platformTitles = {
        github: 'GitHub',
        gitlab: 'GitLab',
        bitbucket: 'Bitbucket'
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };
    const handleRepoModalClose = () => {
        setRepoModalVisible(false);
    };
    const [form] = Form.useForm();

    const handleFormSubmit = () => {
        form
            .validateFields()
            .then(async (values) => {
                const requestBody = {
                    transformationList: [] as any,
                    smartDocList: [] as any,
                    readinessList: [] as any
                };
                const filteredSections = repoConfig.useSingleRepository
                    ? deployData
                    : deployData.filter(section => section.title === sectionTitle);

                filteredSections.forEach(section => {
                    if (section.title === "transformation") {
                        if (repoConfig.useSingleRepoForTransform || repoConfig.useSingleRepository) {
                            requestBody.transformationList.push({
                                repositoryName: selectedRepo,
                                artifacts: section.rows
                                    .filter(row => row.deployStatus !== 'PASS')
                                    .map(row => row.jobName),
                                branchName: selectedBranch,
                                commitMessage: values.commitMessage,
                                directoryName: values.directoryName
                            });
                        } else {
                            requestBody.transformationList.push({
                                repositoryName: selectedRepo,
                                artifacts: [section.rows[index].jobName],
                                branchName: selectedBranch,
                                commitMessage: values.commitMessage,
                                directoryName: values.directoryName
                            });
                        }
                    } else if (section.title === "smartDoc") {
                        if (repoConfig.useSingleRepoForSmartDoc || repoConfig.useSingleRepository) {
                            requestBody.smartDocList.push({
                                repositoryName: selectedRepo,
                                artifacts: section.rows
                                    .filter(row => row.deployStatus !== 'PASS')
                                    .map(row => row.jobName),
                                branchName: selectedBranch,
                                commitMessage: values.commitMessage,
                                directoryName: values.directoryName
                            });
                        } else {
                            requestBody.smartDocList.push({
                                repositoryName: selectedRepo,
                                artifacts: [section.rows[index].jobName],
                                branchName: selectedBranch,
                                commitMessage: values.commitMessage,
                                directoryName: values.directoryName
                            });
                        }
                    } else if (section.title === "readiness") {
                        requestBody.readinessList.push({
                            repositoryName: selectedRepo,
                            artifacts: section.rows.map(row => row.jobName),
                            branchName: selectedBranch,
                            commitMessage: values.commitMessage,
                            directoryName: values.directoryName
                        });
                    }
                });
                const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-test-service/deploy/${projectId}`;
                try {
                    formSubmitted();
                    const response = await axios.post(url, requestBody)
                    console.log(response)                    
                    if (!response.data?.data) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Server Error',
                            text: `${response.data.message} Please contact CloudFrame Support Team for further assistance.`,
                            confirmButtonColor: '#f27474',
                        });
                        postValidation(false);
                    } else if (response.data.status !== 'SUCCESS') {
                        Swal.fire({
                            icon: 'error',
                            title: 'Server Error',
                            text: `${response.data.message} Please contact CloudFrame Support Team for further assistance.`,
                            confirmButtonColor: '#f27474',
                        });
                        postValidation(true, response.data?.data);
                    }
                    else {
                        postValidation(true, response.data?.data);  
                    }
                } catch (error) {
                    console.error(error)
                    postValidation(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Unable to push artifacts to Github. Please contact CloudFrame Support Team for further assistance.',
                        confirmButtonColor: '#f27474',
                    });
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
                postValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Failed',
                    text: 'Please enter all the necessary details required for deploying to GitHub.',
                    confirmButtonColor: '#f27474',
                });
            });
    };

    return (
        <div>
            <Modal
                title={
                    <div className="text-center font-semibold text-lg flex items-center justify-center">
                        {platformIcons[selectedPlatform]}
                        <span className="ml-2">Deploy to {platformTitles[selectedPlatform]}</span>
                    </div>
                }
                open={isRepoModalVisible}
                onOk={handleFormSubmit}
                onCancel={handleRepoModalClose}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={handleRepoModalClose}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" className = {`${(selectedRepo === '' || selectedBranch === '') ? 'opacity-50' : ''}`} onClick={handleFormSubmit} disabled = {(selectedRepo === '' || selectedBranch === '')}>
                        Deploy
                    </Button>,
                ]}
            >
                <hr className="my-4 border-gray-300" />
                {isLoading ?
                    <div className="flex flex-col items-center justify-center">
                        <SVGLoader width={60} height={60} />
                        <p className="pb-2">Fetching Repository details...</p>
                    </div>
                    :
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            branch: 'master',
                            commitMessage: 'Initial Commit',
                        }}
                    >
                        <Form.Item label="Repository Name" 
                            rules={[{ required: true, message: 'Please enter Repository name!' }]} 
                            style={{ marginBottom: '16px' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Select
                                    placeholder="Select a repository"
                                    value={selectedRepo}
                                    onChange={(value) => {
                                        setSelectedRepo(value);
                                        setSelectedBranch('');
                                    }}
                                    style={{ flex: 1, marginBottom: '8px', minWidth: 0, overflow: 'hidden' }}
                                >
                                    {repositories?.map((repo) => (
                                        <Select.Option key={repo.repoName} value={repo.repoName}>
                                            {repo.repoName}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Button
                                    type="primary"
                                    onClick={() => openModal('repo')}
                                    style={{
                                        flexShrink: 0,
                                        marginBottom: '8px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Create Repository
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item label="Branch Name" required style={{ marginBottom: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Select
                                    placeholder="Select a branch"
                                    value={selectedBranch}
                                    onChange={(value) => setSelectedBranch(value)}
                                    style={{ flex: 1, marginBottom: '8px', minWidth: 0, overflow: 'hidden' }}
                                >
                                    {getBranchList().map((branch) => (
                                        <Select.Option key={branch} value={branch}>
                                            {branch}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Button
                                    type="primary"
                                    onClick={() => openModal('branch')}
                                    style={{
                                        flexShrink: 0,
                                        marginBottom: '8px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    New Branch
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={
                                <span>
                                    Directory Name{' '}
                                    <span style={{ color: 'gray', fontSize: '0.875rem' }}>
                                        (This is the target directory where your artifacts will be stored. If not specified, the artifact name will be used by default.)
                                    </span>
                                </span>
                            }
                            name="directoryName">
                            <Input placeholder="Enter Directory name (default: Artifact name)" />
                        </Form.Item>
                        <Form.Item
                            label="Commit Message"
                            name="commitMessage"
                            rules={[{ required: true, message: 'Please enter a commit message!' }]}
                        >
                            <Input placeholder="Enter a commit message" />
                        </Form.Item>
                    </Form>
                }
            </Modal>
            <Modal
                title={modalConfig.title}
                open={modalConfig.visible}
                onOk={handleAdd}
                onCancel={handleModalCancel}
                okText="Add"
                cancelText="Cancel"
            >
                <Input
                    placeholder={modalConfig.placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </Modal>
        </div>
    )
}
export default ModalDeploy;