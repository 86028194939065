import React, { useEffect, useState } from 'react';
import { ISubTable } from '../table';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import useSubTable from './useSubTable';
import axios from 'axios';
import { RootState } from '../../../../redux/store';
import {
  EAppIntent,
  EAppModal,
  ESmartDocStatus,
  SmartDocStatusDetail,
} from '../../../../redux/app-global/app-global.types';
import { setActiveModal, setCurrentIntent, setSmartDocStatus } from '../../../../redux/app-global';
import { SharedService } from '../../../../services/shared-service';
import Swal from 'sweetalert2';
import HelpTextCallout from '../../../../components/help-text-callout/help-text-callout';
import { Spin, Tooltip } from 'antd';
import SmartDocSpinner from '../../../../assets/images/svg/smartDocLoader.icon';
import { MdBlock } from 'react-icons/md';
import { externalConfig } from '../../../../utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';
import { IoIosWarning } from 'react-icons/io';
import { useIsProjectLead } from 'src/pages/project-overview/card/stages-component/useIsProjectLead';
import ProgressCircle from '../progress-circle';
import { AiOutlineReload } from "react-icons/ai";

const SubTable = (table: ISubTable) => {
  const dispatch = useDispatch();
  const startDocStatus = useSelector(
    (state: RootState) => state.appGlobal.smartDocStatus || {},
  );
  const licenseStatusDetails = useSelector(
    (state: RootState) => state.appGlobal.licenseStatusDetails,
  )
  const { showCallChainModal } = useSubTable();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterOption, setFilterOption] = useState<string>('All');
  const [smartDocData, setSmartDocData] = useState<ISubTable>(table);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [generating, setGenerating] = useState<Record<string, boolean>>({});
  const projectId = getSearchParameter('projectId');
  const isProjectLead = useIsProjectLead();
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
    
  const onClickDocumentation = async (name: string) => {
    setGenerating(prev => ({ ...prev, [name]: true }));
    await handleViewDocumentation(name);
  };
  console.log(startDocStatus)
  const redirectToDeploy = () => {
    dispatch(setCurrentIntent(EAppIntent.DEPLOY_PAGE));
  }
  const hasFailedArtifacts = smartDocData.rows?.some((data, index) => {
    const statusValues = [
      startDocStatus[data.programName]?.basicDocStatus || smartDocData.rows[index]?.basicDocStatus,
      startDocStatus[data.programName]?.enhancedDocStatus || smartDocData.rows[index]?.enhancedDocStatus,
      startDocStatus[data.programName]?.smartDocStatus || smartDocData.rows[index]?.status,
    ];
    return statusValues.some(status => ['NOT_POSSIBLE', 'LIC_EXCEEDED', 'NOT_CONFIGURED', 'ERROR', 'PENDING'].includes(status));
  });

  const handleBulkDocGen = async () => {
    const object: Record<string, SmartDocStatusDetail> = {};
    const payload: string[] = [];

    smartDocData.rows?.forEach((data, index) => {
      const statusValues = [
        startDocStatus[data.programName]?.basicDocStatus || smartDocData.rows[index]?.basicDocStatus,
        startDocStatus[data.programName]?.enhancedDocStatus || smartDocData.rows[index]?.enhancedDocStatus,
        startDocStatus[data.programName]?.smartDocStatus || smartDocData.rows[index]?.status,
      ];

      if (statusValues.some(status => ['NOT_POSSIBLE', 'LIC_EXCEEDED', 'NOT_CONFIGURED', 'ERROR', 'PENDING'].includes(status))) {
        object[data.programName] = {
          basicDocStatus: ESmartDocStatus.INPROGRESS,
          enhancedDocStatus: ESmartDocStatus.INPROGRESS,
          smartDocStatus: ESmartDocStatus.INPROGRESS,
        };
        payload.push(data?.programName);
      }
    });

    if (payload.length <= 0) {
      return;
    }
    const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/smartdoc/${projectId}`
    try {
      const response = await axios.post(url, payload);
      if (response?.data.status === 'ERROR') {
        Swal.fire({
          title: 'Error',
          text: `${response.data.message}. Please contact CloudFrame Support Team for further assistance.`,
          icon: 'error',
          confirmButtonText: 'Close',
        });
      }
      if (response?.data.status === 'SUCCESS') {
        dispatch(setSmartDocStatus(object));
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: `Unable to start document generation. Please contact CloudFrame Support Team for further assistance.`,
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
  }
  const handleViewDocumentation = async (key: string) => {
    axios
      .get(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/scan/smart-doc/${projectId}/${key}`,
      )
      .then(res => {
        SharedService.documentationHtmlContent = res.data;
        SharedService.documentationProgramName = key;
        dispatch(setActiveModal(EAppModal.DOCUMENTATION_MODAL));
        setGenerating(prev => ({ ...prev, [key]: false }));
      })
      .catch(err => {
        console.error('Unable to fetch documentation: ', err);
        setGenerating(prev => ({ ...prev, [key]: false }));
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch the documentation. Please contact Support Team',
          icon: 'error',
          confirmButtonText: 'Close',
        });
      });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const debounce = (func: any, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleToggleChange = async (name: string) => {
    setLoading(prev => ({ ...prev, [name]: true }));
    const url = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/smartdoc/${projectId}`
    try {
      const response = await axios.post(url, [name]);
      if (response?.data.status === 'ERROR') {
        Swal.fire({
          title: 'Error',
          text: `${response.data.message}. Please contact CloudFrame Support Team for further assistance.`,
          icon: 'error',
          confirmButtonText: 'Close',
        });
      }
      if (response?.data.status === 'SUCCESS') {
        const newStatus: Record<string, SmartDocStatusDetail> = {};
        newStatus[name] = {
          basicDocStatus: ESmartDocStatus.INPROGRESS,
          enhancedDocStatus: ESmartDocStatus.INPROGRESS,
          smartDocStatus: ESmartDocStatus.INPROGRESS,
        }
        dispatch(setSmartDocStatus(newStatus));
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: `Unable to start document generation. Please contact CloudFrame Support Team for further assistance.`,
        icon: 'error',
        confirmButtonText: 'Close',
      });
    } finally {
      setLoading(prev => ({ ...prev, [name]: false }));
    }
  }

  const filterRows = (name: string) => {
    if (
      searchQuery &&
      !name.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return false;
    }
    return true;
  };

  return (
    <div key={smartDocData?.title}>
      <div
        className="pt-4 ml-0 flex items-center justify-between bg-gray-100 p-4 rounded-md shadow-md"
        onClick={toggleCollapse}
      >
        <h2 className="text-2xl font-semibold text-gray-800">{smartDocData?.title}</h2>
        <button className="ml-2 text-gray-600 hover:text-gray-800 transition-colors duration-200">
          {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      {!isCollapsed && (
        <>
          <div className="flex items-center justify-between mb-4 mt-4 ml-6">
            <div className="flex flex-row gap-2">
              <input
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="flex-1 rounded-md border border-input px-3 py-2 text-sm max-w-[20rem]"
              />
            </div>
            <div className="flex flex-row gap-2 ml-auto mr-4">
              {hasFailedArtifacts && (
                <div className="flex items-center space-x-2">
                  <HelpTextCallout
                    calloutText={table.helpTexts[5].description}
                  />
                  <button
                    disabled={!isProjectLead}
                    style={{ opacity: isProjectLead ? 1 : 0.5 }}
                    onClick={handleBulkDocGen}
                    className='flex gap-2 items-center px-3 py-2 bg-red-500 text-white rounded-md text-sm'
                  >
                    <AiOutlineReload /> Regenerate SmartDoc for failed artifacts
                  </button>
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className="p-6">
            <div className="relative w-full overflow-auto">
              <table className="w-full caption-bottom text-sm">
                <thead className="[&amp;_tr]:border-b ">
                  <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
                      <div className="flex items-center gap-2 text-sm">
                        Name{' '}
                        <HelpTextCallout
                          calloutText={table.helpTexts[1].description}
                        />
                      </div>
                    </th>
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
                      <div className="flex items-center gap-2 text-sm">
                        View Report{' '}
                        <HelpTextCallout
                          calloutText={table.helpTexts[2].description}
                        />
                      </div>
                    </th>
                    <th
                      className={`h-12 px-4 text-left align-middle font-medium text-muted-foreground`}
                    >
                      <div className="flex items-center justify-center gap-2 text-sm">
                        <span
                        >
                          Deploy
                        </span>
                        <HelpTextCallout
                          calloutText={table.helpTexts[3].description}
                        />
                      </div>
                    </th>

                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]">
                      <div className="flex items-center justify-center gap-2 text-sm">
                        SmartDoc Status{' '}
                        <HelpTextCallout
                          calloutText={table.helpTexts[4].description}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="[&amp;_tr:last-child]:border-0">
                  {smartDocData?.rows
                    .filter(row => filterRows(row.programName))
                    .map((row, index) => (
                      <React.Fragment key={row.programName}>
                        <tr
                          className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted`}
                        >
                          <td className="p-4 align-middle font-medium">
                            {row.programName}
                          </td>
                          <td className="p-4 w-[200px]">
                            {(startDocStatus[row.programName] ? startDocStatus[row.programName].smartDocStatus !== ESmartDocStatus.LOC_EXCEEDED : row.status !== ESmartDocStatus.LOC_EXCEEDED) ? (
                              <Tooltip
                                title={`${(startDocStatus[row.programName] ? startDocStatus[row.programName].basicDocStatus === ESmartDocStatus.INPROGRESS : row.basicDocStatus === ESmartDocStatus.INPROGRESS) ? "Please wait until the SmartDoc generation is completed." : "Click on this button to view the SmartDoc report"}`}
                                overlayInnerStyle={{
                                  backgroundColor: '#000',
                                  borderRadius: '8px',
                                  color: '#fff',
                                  padding: '8px',
                                  fontSize: '14px',
                                }}
                              >
                                <button
                                  className={`flex items-center px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors duration-200 ${!(startDocStatus[row.programName] ? startDocStatus[row.programName].basicDocStatus === ESmartDocStatus.SUCCESS : smartDocData.rows[index].basicDocStatus === ESmartDocStatus.SUCCESS) ? 'opacity-50' : ''}`}
                                  onClick={() =>
                                    onClickDocumentation(row.programName)
                                  }
                                  disabled={!(startDocStatus[row.programName] ? startDocStatus[row.programName].basicDocStatus === ESmartDocStatus.SUCCESS : smartDocData.rows[index].basicDocStatus === ESmartDocStatus.SUCCESS)}
                                >
                                  {generating[row.programName]
                                    ? 'Fetching Report...'
                                    : 'Show Report'}
                                </button>
                              </Tooltip>
                            ) : (
                              <div className="flex">
                                <Tooltip
                                  title="Smart documentation for this artifact is not enabled as the license limit is exceeded. Please contact CloudFrame Support Team for assitance."
                                  overlayInnerStyle={{
                                    backgroundColor: '#000',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    padding: '8px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <IoIosWarning className="text-red-600 ml-8" />
                                </Tooltip>
                              </div>
                            )}
                          </td>
                          <td className="align-middle">
                            <div className='flex items justify-center'>
                              <button
                                className={`flex items-center justify-center px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors duration-200`}
                                disabled={!(startDocStatus[row.programName]?.basicDocStatus === ESmartDocStatus.SUCCESS || smartDocData.rows[index].basicDocStatus === ESmartDocStatus.SUCCESS )}
                                onClick={redirectToDeploy}
                                style={{
                                  opacity:
                                    !(startDocStatus[row.programName]?.basicDocStatus === ESmartDocStatus.SUCCESS || smartDocData.rows[index].basicDocStatus === ESmartDocStatus.SUCCESS)
                                      ? 0.5
                                      : 1.0,
                                }}
                              >
                                Deploy
                              </button>
                            </div>
                          </td>
                          <td className="p-4 align-middle w-[250px]">
                            <div className='mt-4 flex flex-row items-center justify-center gap-4'>
                              <ProgressCircle status={startDocStatus[row.programName] ? startDocStatus[row.programName].basicDocStatus : smartDocData.rows[index].basicDocStatus} label="Basic SmartDoc" />
                              <ProgressCircle status={startDocStatus[row.programName] ? startDocStatus[row.programName].enhancedDocStatus : smartDocData.rows[index].enhancedDocStatus} label="Enhanced SmartDoc" />
                            </div>
                          </td>
                        </tr>
                        {/* {!['', 'NOT_GENERATED', undefined, 'DISABLED'].includes(
                          startDocStatus[row.programName]?.basicDocStatus || startDocStatus[row.programName]?.enhancedDocStatus || startDocStatus[row.programName]?.smartDocStatus,
                        ) && (
                            <tr className="bg-gray-100">
                              <td colSpan={4} className="p-2">
                                <div className="progress-widget">
                                  <div className="p-2 text-center">
                                    <p className="text-sm font-semibold text-gray-700">SmartDoc Generation Progress</p>
                                  </div>
                                  <div className='mt-4 flex flex-row items-center justify-center gap-6'>
                                    <ProgressCircle status={startDocStatus[row.programName]?.basicDocStatus} label="Basic SmartDoc" />
                                    <ProgressCircle status={startDocStatus[row.programName]?.enhancedDocStatus} label="Enhanced SmartDoc" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )} */}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubTable;
