import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  Artifact,
  EAppIntent,
  EAppModal,
  EArtifactStatus,
  ESmartDocStatus,
  IAppGlobalState,
  IIgnoredMissingArtifacts,
  ILicenseDetails,
  ITransformationStatus,
  IUploadedArtifacts,
  IValidationFiles,
  MissingPage,
  TableData,
  IInvalidFileDetails,
  ILicenseStatusDetails,
  SmartDocStatusDetail,
} from './app-global.types';

const initialState: IAppGlobalState = {
  activeModal: null,
  currentIntent: null,
  currentContent: {},
  customChatMessage: [],
  resetChatbot: false,
  scanCompleted: false,
  formSubmitted: false,
  emailSent: false,
  userDetails: null,
  scannerErrorMessage: '',
  uploadedArtifacts: undefined,
  scanJclFailed: false,
  isCallChainLoaded: false,
  scanProgress: 0,
  missingPrograms: [],
  missingUtilities: [],
  license: {
    Environment: 'ONPREM',
    fileUploadLimit: 1000,
    licenseType: 'ONPREM',
    enableCdn: '',
    licenseFor: '',
    category: '',
    licensedProduct: '',
    lineOfCode: '',
    maxFileSize: '',
    licenseId: '',
    licensedTill: '',
    isLicenseActivate: 'false',
    isLicenseValid: 'false',
  },
  supportDetails: null,
  artifactStatus: {},
  smartDocStatus: {},
  artifactTransformProfile: {},
  artifactDownloadAsService: {},
  missingPages: [],
  uploadedControlCards: undefined,
  uploadedMetadata: undefined,
  uploadedMissingArtifacts: undefined,
  uploadedPrograms: undefined,
  procData: [],
  controlCardData: [],
  programData: [],
  dynamicCallData: [],
  jclData: [],
  userLoginDetails: null,
  ignoredMissingArtifacts: undefined,
  validationProgress: 0,
  validationData: {},
  invalidFilesData: {},
  licenseStatusDetails: null,
};

export const appGlobalSlice = createSlice({
  name: 'app-global',
  initialState,
  reducers: {
    setActiveModal: (state, action: PayloadAction<EAppModal | null>) => {
      state.activeModal = action.payload;
    },
    setCurrentIntent(state, action: PayloadAction<EAppIntent | null>) {
      state.currentIntent = action.payload;
    },
    setCurrentContent(state, action: PayloadAction<any>) {
      state.currentContent = action.payload;
    },
    pushCustomChatMessage: (
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) => {
      const { id, message } = action.payload;
      state.customChatMessage = [...state.customChatMessage, { id, message }];
    },
    popCustomChatMessage: state => {
      state.customChatMessage = state.customChatMessage.slice(0, -1);
    },
    clearCustomChatMessages: state => {
      state.customChatMessage = [];
    },
    setResetChatbot: (state, action: PayloadAction<boolean>) => {
      state.resetChatbot = action.payload;
    },
    setScanCompleted: (state, action: PayloadAction<boolean>) => {
      state.scanCompleted = action.payload;
    },
    setFormSubmitted: (state, action: PayloadAction<boolean>) => {
      state.formSubmitted = action.payload;
    },
    setEmailSent: (state, action: PayloadAction<boolean>) => {
      state.emailSent = action.payload;
    },
    setUserDetails: (
      state,
      action: PayloadAction<IAppGlobalState['userDetails']>,
    ) => {
      state.userDetails = action.payload;
    },
    setSupportDetails: (
      state,
      action: PayloadAction<IAppGlobalState['supportDetails']>,
    ) => {
      state.supportDetails = action.payload;
    },
    setScannerErrorMessage: (state, action: PayloadAction<string>) => {
      state.scannerErrorMessage = action.payload;
    },
    setUploadedArtifacts: (
      state,
      action: PayloadAction<IUploadedArtifacts | undefined>,
    ) => {
      state.uploadedArtifacts = action.payload;
    },
    setScanJclFailed: (state, action: PayloadAction<boolean>) => {
      state.scanJclFailed = action.payload;
    },
    setIsCallChainLoaded: (state, action: PayloadAction<boolean>) => {
      state.isCallChainLoaded = action.payload;
    },
    setScanProgress: (state, action: PayloadAction<number>) => {
      state.scanProgress = action.payload;
    },
    setMissingPrograms: (state, action: PayloadAction<string[]>) => {
      state.missingPrograms = action.payload;
    },
    setMissingUtilities: (state, action: PayloadAction<string[]>) => {
      state.missingUtilities = action.payload;
    },
    setLicense: (state, action: PayloadAction<ILicenseDetails | null>) => {
      state.license = action.payload;
    },
    setReportData: (state, action: PayloadAction<any>) => {
      state.reportData = action.payload;
    },
    setArtifactStatusObject: (
      state,
      action: PayloadAction<{ [key: string]: EArtifactStatus }>,
    ) => {
      if (!state.artifactStatus) state.artifactStatus = {};
      state.artifactStatus = { ...state.artifactStatus, ...action.payload };
    },
    resetArtifactStatusObject: (
      state,
      action: PayloadAction<{ [key: string]: EArtifactStatus }>,
    ) => {
      state.artifactStatus = action.payload || {};
    },
    setArtifactStatus: (
      state,
      action: PayloadAction<{
        key: string;
        status: EArtifactStatus;
      }>,
    ) => {
      if (!state.artifactStatus) state.artifactStatus = {};
      state.artifactStatus[action.payload.key] = action.payload.status;
    },
    setSmartDocStatus: (
      state,
      action: PayloadAction<{ [key: string]: SmartDocStatusDetail }>,
    ) => {
      if (!state.smartDocStatus) state.smartDocStatus = {};
      state.smartDocStatus = { ...state.smartDocStatus, ...action.payload };
    },
    clearArtifactStatus: state => {
      state.artifactStatus = {};
    },
    clearSmartDocStatus: state => {
      state.smartDocStatus = {};
    },
    setArtifactTransformProfileObject: (
      state,
      action: PayloadAction<{ [key: string]: string }>,
    ) => {
      state.artifactTransformProfile = action.payload;
    },
    setArtifactTransformProfile: (
      state,
      action: PayloadAction<{
        key: string;
        value: string;
      }>,
    ) => {
      if (!state.artifactTransformProfile) state.artifactTransformProfile = {};
      state.artifactTransformProfile[action.payload.key] = action.payload.value;
    },
    clearArtifactTransformProfile: state => {
      state.artifactTransformProfile = {};
    },
    setArtifactDownloadAsServiceObject: (
      state,
      action: PayloadAction<{ [key: string]: string }>,
    ) => {
      state.artifactDownloadAsService = action.payload;
    },
    setArtifactDownloadAsService: (
      state,
      action: PayloadAction<{
        key: string;
        value: string;
      }>,
    ) => {
      if (!state.artifactDownloadAsService)
        state.artifactDownloadAsService = {};
      state.artifactDownloadAsService[action.payload.key] =
        action.payload.value;
    },
    clearArtifactDownloadAsServiceObject: state => {
      state.artifactDownloadAsService = {};
    },
    setNextMissingPage: (
      state,
      action: PayloadAction<{ page?: EAppIntent }>,
    ) => {
      state.nextMissingPage = action.payload.page;
    },
    setSupportTickets: (
      state,
      action: PayloadAction<{ id: string; title: string }[]>,
    ) => {
      state.supportTicketsGenerated = action.payload;
    },
    setNextMissingPageTest: (state, action: PayloadAction<MissingPage[]>) => {
      state.missingPages = action.payload;
    },
    setUploadedMissingArtifacts: (
      state,
      action: PayloadAction<IUploadedArtifacts | undefined>,
    ) => {
      state.uploadedMissingArtifacts = action.payload;
    },
    setProcData: (state, action: PayloadAction<TableData[]>) => {
      state.procData = action.payload;
    },
    setProgramData: (state, action: PayloadAction<TableData[]>) => {
      state.programData = action.payload;
    },
    setControlCardData: (state, action: PayloadAction<TableData[]>) => {
      state.controlCardData = action.payload;
    },
    setDynamicCallData: (state, action: PayloadAction<TableData[]>) => {
      state.dynamicCallData = action.payload;
    },
    setJCLCallData: (state, action: PayloadAction<Artifact[]>) => {
      state.jclData = action.payload;
    },
    updateTransformationProgress: (
      state,
      action: PayloadAction<{ [key: string]: number }>,
    ) => {
      state.transformationProgress = {
        ...state.transformationProgress,
        ...action.payload,
      };
    },
    setTransformationProgress: (
      state,
      action: PayloadAction<{ [key: string]: number }>,
    ) => {
      state.transformationProgress = action.payload;
    },
    setTransformationStatus: (
      state,
      action: PayloadAction<{ [key: string]: ITransformationStatus }>,
    ) => {
      state.transformationStatus = {
        ...state.transformationStatus,
        ...action.payload,
      };
    },
    resetTransformationStatus: (
      state,
      action: PayloadAction<{ [key: string]: ITransformationStatus }>,
    ) => {
      state.transformationStatus = action.payload;
    },
    setUserLoginDetails: (
      state,
      action: PayloadAction<IAppGlobalState['userLoginDetails']>,
    ) => {
      state.userLoginDetails = action.payload;
    },
    setIgnoredMissingArtifacts: (
      state,
      action: PayloadAction<IIgnoredMissingArtifacts | undefined>,
    ) => {
      state.ignoredMissingArtifacts = action.payload;
    },
    setValidationProgress: (state, action: PayloadAction<number>) => {
      state.validationProgress = action.payload;
    },
    setValidationFiles: (
      state,
      action: PayloadAction<{ [key: string]: IValidationFiles }>,
    ) => {
      if (!state.validationData) state.validationData = {};
      state.validationData = { ...state.validationData, ...action.payload };
    },
    clearValidationFiles: state => {
      state.validationData = {};
    },
    setInvalidFileData: (
      state,
      action: PayloadAction<{ [key: string]: IInvalidFileDetails[] }>,
    ) => {
      if (!state.invalidFilesData) state.invalidFilesData = {};
      state.invalidFilesData = { ...action.payload };
    },
    clearInvalidFileData: state => {
      state.invalidFilesData = {};
    },
    setLicenseStatusDetails: (
      state,
      action: PayloadAction<ILicenseStatusDetails | null>,
    ) => {
      state.licenseStatusDetails = action.payload;
    },
    logOut: state => {
      return { ...initialState, license: state.license };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveModal,
  setCurrentIntent,
  setCurrentContent,
  pushCustomChatMessage,
  popCustomChatMessage,
  clearCustomChatMessages,
  setResetChatbot,
  setScanCompleted,
  setFormSubmitted,
  setEmailSent,
  setUserDetails,
  setScannerErrorMessage,
  setUploadedArtifacts,
  setScanJclFailed,
  setIsCallChainLoaded,
  setScanProgress,
  setMissingPrograms,
  setMissingUtilities,
  setLicense,
  setReportData,
  setSupportDetails,
  setArtifactStatus,
  clearArtifactStatus,
  setSmartDocStatus,
  clearSmartDocStatus,
  setArtifactStatusObject,
  setArtifactTransformProfileObject,
  setArtifactTransformProfile,
  clearArtifactTransformProfile,
  setArtifactDownloadAsServiceObject,
  setArtifactDownloadAsService,
  clearArtifactDownloadAsServiceObject,
  setNextMissingPage,
  setSupportTickets,
  setNextMissingPageTest,
  setUploadedMissingArtifacts,
  setProcData,
  setControlCardData,
  setProgramData,
  setDynamicCallData,
  updateTransformationProgress,
  setTransformationProgress,
  setTransformationStatus,
  setJCLCallData,
  setUserLoginDetails,
  setIgnoredMissingArtifacts,
  setValidationProgress,
  setValidationFiles,
  clearValidationFiles,
  setInvalidFileData,
  clearInvalidFileData,
  setLicenseStatusDetails,
  logOut,
  resetArtifactStatusObject,
  resetTransformationStatus,
} = appGlobalSlice.actions;

export default appGlobalSlice.reducer;
