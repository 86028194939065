import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const fetchReportData = async (projectId: string) => {
  const response = await axios.post(
    `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/scan/result/${projectId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response.data;
};
