import axios from 'axios';
import { ReactNode } from 'react';
import { externalConfig } from 'src/utils/misc.utils';

export interface IAppVersion {
  logFileName: string;
  name: string;
  version: string;
  platformVersion: string;
}
export const getAppVersion = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/app/version`,
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching app version:', error);
    throw error;
  }
};
