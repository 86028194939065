import axios from 'axios';
import { useCallback, useState } from 'react';
import {
  useGetProjectDependenciesQuery,
  useGetUserProfileQuery,
  useGetUsersQuery,
  useToggleUserStatusMutation,
} from 'src/redux/api-slice';
import { externalConfig } from 'src/utils/misc.utils';
import { getSearchParameter } from 'src/utils/url.utils';
import Swal from 'sweetalert2';
import { IProjectApiObject } from '../dashboard/dashboard.service';

export const useUserProfile = () => {
  const userId = getSearchParameter('id');

  const [showUserModal, setShowUserModal] = useState(false);
  const [showEditProjectRoleModal, setShowEditProjectRoleModal] =
    useState(false);
  const [selectedProject, setSelectedProject] = useState<IProjectApiObject>();
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const projectDependenciesQuery = useGetProjectDependenciesQuery();
  const [toggleUserStatus] = useToggleUserStatusMutation();

  const {
    data: userProjects,
    isLoading,
    error,
    refetch,
  } = useGetUserProfileQuery({ userId }, { skip: !userId });

  const {
    data: users,
    isLoading: usersLoading,
    refetch: refetchUsers,
  } = useGetUsersQuery();

  const currentUser = users?.find(user => user.id === +userId);
  const userProfile = { user: currentUser, projects: userProjects || [] };

  const projectsUserLeading =
    userProfile?.projects
      ?.filter(project => {
        const user = project.projectMemberList.find(
          (user: any) => user.userId === +userId,
        );

        if (user && user.memberType === 'LEAD') {
          return true;
        }

        return false;
      })
      ?.map(project => project.projectName) || [];

  const handleEditUser = useCallback(async () => {
    setShowUserModal(true);
  }, []);

  const handleUserActivation = useCallback(
    async (id: number, isActive: boolean) => {
      const projectsMessage =
        projectsUserLeading.length > 0
          ? ` They are currently leading the following applications: ${projectsUserLeading.join(', ')}. These applications will be moved to draft status unless a new lead is added.`
          : '';

      const result = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: isActive
          ? `Are you sure you want to deactivate this user? Deactivating this user will remove them from all applications they are part of.${projectsMessage}`
          : 'Are you sure you want to reactivate this user?',
        showCancelButton: true,
        confirmButtonText: isActive
          ? 'Yes, deactivate it!'
          : 'Yes, reactivate it!',
        cancelButtonText: 'No, cancel!',
      });
      if (result.isConfirmed) {
        await toggleUserStatus({ userId: id + '' }).unwrap();
        await refetch();
        refetchUsers();
        Swal.fire(
          isActive ? 'Deactivated!' : 'Reactivated!',
          isActive
            ? 'User has been deactivated.'
            : 'User has been reactivated.',
          'success',
        );
      }
    },
    [projectsUserLeading],
  );

  const handleRemoveFromProject = useCallback(
    async (id: number, projectId: string, isLead: boolean) => {
      const projectsMessage = isLead
        ? `Are you sure you want to remove this user? The user currently leading this application! These application will be moved to draft status unless a new lead is added.`
        : 'Are you sure you want to remove this user?';

      const result = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: projectsMessage,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove User',
        cancelButtonText: 'No, Cancel!',
      });
      if (result.isConfirmed) {
        // await axios.put(
        //   `${externalConfig.REACT_APP_LOGIN_URL}/user/change-status/${id}`,
        // );
        await refetch();
        Swal.fire('Removed!', 'User is removed from the applications.', 'success');
      }
    },
    [],
  );

  const handleUserProjectRoleUpdate = useCallback(
    (project: IProjectApiObject) => {
      setSelectedProject(project);
      setShowEditProjectRoleModal(true);
    },
    [],
  );

  const handleEditProject = useCallback(
    async (project: IProjectApiObject) => {
      if (!projectDependenciesQuery.isLoading) {
        const dependencies = projectDependenciesQuery.data?.filter(
          (depProject: any) => {
            return depProject.projectId === project.projectId;
          },
        );

        setSelectedProject({ ...project, dependencyList: dependencies });
        setShowEditProjectModal(true);
      }
    },
    [projectDependenciesQuery],
  );

  const loading =
    isLoading || usersLoading || projectDependenciesQuery.isLoading;

  return {
    showUserModal,
    handleEditUser,
    userProfile,
    loading,
    error,
    setShowUserModal,
    handleUserActivation,
    handleRemoveFromProject,
    userId,
    projectsUserLeading,
    showEditProjectRoleModal,
    setShowEditProjectRoleModal,
    handleUserProjectRoleUpdate,
    selectedProject,
    refetchUserProject: refetch,
    showEditProjectModal,
    setShowEditProjectModal,
    handleEditProject,
  };
};
