import React, { useState, useEffect } from 'react';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { Switch } from 'src/components/ui/switch';
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
    SelectValue,
} from 'src/components/ui/select';
import { helpTexts } from 'src/helpTexts';
import { useGetGlobalProjectAdminSettingsQuery, useGetTransformationGlobalProjectAdminSettingsQuery} from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { useUser } from 'src/hooks/useUser';
import { useLocation } from 'react-router-dom';
import { externalConfig } from 'src/utils/misc.utils';
import axios from 'axios';

const ExperimentalConfig = () => {
    const { data, error, isLoading, refetch} = useGetTransformationGlobalProjectAdminSettingsQuery();

    const transformationSettings = data?.filter(item => item.type === "TRANSFORMATION");

    const [settings, setSettings] = useState(transformationSettings?.[0]?.settings || {});

    useEffect(() => {
        if (transformationSettings?.[0]?.settings) {
            setSettings(transformationSettings?.[0]?.settings); 
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Unable to load global Experimental Config Application Settings. Please try again later.',
            });
        }
    }, [error]);

    const handleUpdateOverrideSettings = async () => {
        try {
            const filteredSettings = {
                generateAnnotation: settings.generateAnnotation,
                cacheSingletonSelect: settings.cacheSingletonSelect,
                flattenDto: settings.flattenDto,
                ignoreCicsErrorHandling: settings.ignoreCicsErrorHandling,
                generateCustomSqlca: settings.generateCustomSqlca,
                cleanupCompileWarnings: settings.cleanupCompileWarnings
            };

            const response = await axios.put(
                `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project-settings`,
                {
                    settings : filteredSettings,
                    id: transformationSettings?.[0]?.id
                }
            );
            if (response?.data?.status === 'SUCCESS') {
                Swal.fire({
                    icon: 'success',
                    title: 'Experimental Config Settings Updated',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
                refetch();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Experimental Config Settings Error',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
            }
        } catch (error) {
            console.error("Error updating override settings", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again later.',
                confirmButtonText: 'OK',
            });
        }
    };


    const updateSetting = (index, updates) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            ...updates
        }));
    };
    
    const handleSelectChange = (index, fieldName, newValue) => {
        updateSetting(index, { [fieldName]: newValue });  
    };    

    const FieldWrapper = ({ label, helpText, children }) => (
        <div className="flex flex-col">
            <label className="block text-md font-medium mb-2 flex items-center">
                {label}
                <HelpTextCallout calloutText={helpText} />
            </label>
            {children}
        </div>
    );

    const SelectField = ({ label, value, onChange, helpText }) => {
        const options = [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' }
        ];

        return (
            <FieldWrapper label={label} helpText={helpText}>
                 <Select onValueChange={onChange} value={value}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select an option" />
                    </SelectTrigger>
                    <SelectContent>
                        {options.map(option => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </FieldWrapper>
        );
    };
  
    const pagHelpTexts = helpTexts[0].EXPERIMENTAL_CONFIG_SETTINGS;

    const noProjectSettings = settings && Object.keys(settings).length === 0;

    return (
        <div className="p-1 bg-gray-100 flex justify-left items-left">
            <div className="bg-white shadow-2xl rounded-lg p-8 w-full max-w-4xl">
                {isLoading ? (
                    <div>Loading...</div>
                ) : noProjectSettings ? (
                    <div className="text-center text-lg text-gray-500">
                        No Experimental Config Application Settings
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-6">
                        <SelectField
                            label="Generate With Annotations"
                            value={settings.generateAnnotation}
                            onChange={value => handleSelectChange(0, 'generateAnnotation', value)}
                            helpText={pagHelpTexts[0].description}
                        />
                         <SelectField
                            label="Cache INITIALIZE Statement"
                            value={settings.cacheSingletonSelect}
                            onChange={value => handleSelectChange(1,'cacheSingletonSelect', value)}
                            helpText={pagHelpTexts[1].description}
                        />
                        <SelectField
                            label="Flatten DTO's"
                            value={settings.flattenDto}
                            onChange={value => handleSelectChange(2, 'flattenDto', value)}
                            helpText={pagHelpTexts[2].description}
                        />
                         <SelectField
                            label="Ignore CICS Error Handling"
                            value={settings.ignoreCicsErrorHandling}
                            onChange={value => handleSelectChange(3, 'ignoreCicsErrorHandling', value)}
                            helpText={pagHelpTexts[3].description}
                        />
                        <SelectField
                            label="Generate Custom Sqlca"
                            value={settings.generateCustomSqlca}
                            onChange={value => handleSelectChange(4, 'generateCustomSqlca', value)}
                            helpText={pagHelpTexts[4].description}
                        />
                        <SelectField
                            label="Cleanup Compile Warnings"
                            value={settings.cleanupCompileWarnings}
                            onChange={value => handleSelectChange(5, 'cleanupCompileWarnings', value)}
                            helpText={pagHelpTexts[5].description}
                        />
                       
                    </div>
                )}
                {!isLoading && !noProjectSettings && (
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleUpdateOverrideSettings}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                        >
                            Save Experimental Config Settings
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExperimentalConfig;
