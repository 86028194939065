import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const getGlobalProjectSettings = async (projectId: string) => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project-settings/${projectId}`,
    );

    const { data } = res;

    if (data?.status === 'ERROR') {
      return data;
    }

    if (data?.status === 'SUCCESS') {
      return data.data;
    }
  } catch (error) {
    console.error('Error fetching application settings:', error);
    return [];
  }
};

export const updateProjectSettings = async (
  projectId: string,
  settings: any[],
) => {
  try {
    const res = await axios.put(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project-settings/${projectId}`,
      {
        transformSettings: 'SomeTransformSetting',
        overrideOptions: settings.map(s => Object.values(s)[0]),
      },
    );
    return res.data;
  } catch (error) {
    console.error('Error updating application settings:', error);
    return null;
  }
};

export const getGlobalAdminProjectSettings = async () => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-openai-service/cf/ai/get-openai-config`,
    );
    return res.data;
  } catch (error) {
    console.error('Error fetching application settings:', error);
    return [];
  }
};

export const getGlobalAdminTPSettings = async () => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/validation/config`,
    );
    return res.data;
  } catch (error) {
    console.error('Error fetching application settings:', error);
    return [];
  }
};

export const getTransformGlobalAdminProjectSettings = async () => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-chatbot-controller/project-settings`,
    );
    return res.data.data;
  } catch (error) {
    console.error('Error fetching application settings:', error);
    return [];
  }
};

export const getDeploySettings = async () => {
    try {
        const res = await axios.get(`${externalConfig.REACT_APP_GATEWAY_URL}/cf-test-service/deploy/config`);
        return res.data;
    } catch (error) {
        console.error('Error fetching Deploy settings:', error);
        return null; 
    }
  };