import axios from 'axios';
import { externalConfig } from 'src/utils/misc.utils';

export const getLicenseData = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-license-service/license/info`,
    );
    return {
      LicenseType: response?.data?.data?.LicenseType,
      Environment: response?.data?.data?.Environment,
      ClientName: response?.data?.data?.LicensedFor,
      LicensedProduct: response?.data?.data?.LicensedProduct,
      LicenseId: response?.data?.data?.LicenseId,
      PlatformExpiryDate: response?.data?.data?.PlatformExpiryDate,
      PlatformEffectiveDate: response?.data?.data?.PlatformEffectiveDate,
      ServicedeskID :  response?.data?.data?.ServicedeskID,
      LinesOfCode: response?.data?.data?.LinesOfCode,
      SmartDocLOC :  response?.data?.data?.SmartDocLOC
    };
  } catch (error) {
    console.error('Error fetching license data:', error);
    throw error;
  }
};

export const uploadLicenseFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.post(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-license-service/license/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading license file:', error);
    throw error;
  }
};

export const getLicenseMetadata = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-license-service/license/metadata`,
    );
    return response.data?.data;
  } catch (error) {
    console.error('Error fetching license metadata:', error);
    throw error;
  }
};

export const submitActivationKey = async (secureCode: string) => {
  try {
    const response = await axios.post(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-license-service/license/activate`,
      { activationCode: secureCode },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching license metadata:', error);
    throw error;
  }
};

export const getLicenseGraph = async () => {
  try {
    const response = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-license-service/license-usage/get-usage/all/all`,
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching license data:', error);
    throw error;
  }
};

export const getTotalLicenseCount = async (projectId: string) => {
  try {
    const res = await axios.get(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-license-service/license-usage/get-usage/${projectId}`,
    );

    const { data } = res;

    if (data?.status === 'ERROR') {
      return data;
    }

    if (data?.status === 'SUCCESS') {
      return data.data;
    }
  } catch (error) {
    console.error('Error fetching application settings:', error);
    return [];
  }
};
