import React, { useState } from 'react';
import NotesCard from '../../components/notes-card/notes-card';
import { INoteCardProps } from '../../components/notes-card/notes-card.types';
import { FiX } from 'react-icons/fi';
import { AvatarStack } from 'src/components/ui/avatar-stack';
import { setActiveModal } from 'src/redux/app-global';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { EAppModal } from 'src/redux/app-global/app-global.types';
import ProjectSideBar from 'src/components/project-sidebar/project-sidebar';
import {
  useGetProjectDetailQuery,
  useGetProjectNotesQuery,
} from 'src/redux/api-slice';
import Swal from 'sweetalert2';
import { getSearchParameter } from 'src/utils/url.utils';
import dayjs from 'dayjs';
import AddNotesWidget from 'src/components/add-notes-widget/add-notes-widget';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';

const NotesPage = () => {
  const projectId = getSearchParameter('projectId');
  const {
    isLoading,
    error,
    data: notes = [],
  } = useGetProjectNotesQuery({ projectId });

  const project = useGetProjectDetailQuery({ projectId });

  const [alertsShown, setAlertsShown] = useState({ notes: false });
  const dispatch = useDispatch();
  const activeModal = useSelector(
    (state: RootState) => state.appGlobal.activeModal,
  );

  const [selectedDetailNote, setSelectedDetailNote] =
    useState<INoteCardProps | null>(null);

  if (error && !alertsShown.notes) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load application notes. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, notes: true });
  }

  const handleCloseModal = () => {
    dispatch(setActiveModal(null));
    setSelectedDetailNote(null);
  };

  const handleNoteClick = (note: INoteCardProps) => {
    setSelectedDetailNote(note);
    dispatch(setActiveModal(EAppModal.PROJECT_DETAIL_NOTE_WIDGET));
  };
  
  const navigate = useNavigate();

  const dashboard = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div className="p-4 flex flex-row bg-[#F3F4F4] h-[100vh]">
      <div className="fixed bottom-8 right-10">
        <div className="h-14 w-14 bg-grey20 rounded-full flex items-center justify-center">
          <AddNotesWidget />
        </div>
      </div>
      <div className="mx-4 mt-0 mb-0 pb-0 mr-0 h-full w-8">
        <ProjectSideBar activeScreen={'Notes'} />
      </div>
      <button
        className="absolute  right-8 bg-blue-600 text-white text-sm px-3 py-2 rounded-md shadow hover:bg-blue-600 transition"
        onClick={dashboard}
      >
      Back to Dashboard
      </button>
      <div className="mt-4 ml-8">
        <div>
          <h1 className="text-2xl font-bold">Application Notes</h1>
          <p className="mt-2">
            Keep important details, ideas, and observations in one place. Use
            this section to jot down any notes related to your application&apos;s
            transformation journey.
          </p>
          {isLoading || project.isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mt-8">
              {notes.length === 0 && (
                <p className="text-gray-500">
                  <strong>No notes found.</strong> Consider adding some notes to keep track of
                  your application details.
                </p>
              )}
              {notes.map(note => (
                <NotesCard
                  key={note.id}
                  {...note}
                  onClick={() => handleNoteClick(note)}
                />
              ))}
            </div>
          )}
        </div>

        {activeModal === EAppModal.PROJECT_DETAIL_NOTE_WIDGET &&
          selectedDetailNote && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-2xl shadow-2xl max-w-3xl w-[80vh]  overflow-y-auto relative">
                <FiX
                  onClick={handleCloseModal}
                  className="absolute top-3 right-3 text-gray-500 hover:text-red-500 text-2xl cursor-pointer transition-transform transform hover:scale-110"
                />
                <div className="text-center mb-6">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {selectedDetailNote.subject}
                  </h2>
                </div>

                <div className="flex flex-col space-y-4">
                  <p className="flex-1">
                    <strong>Author:</strong> {selectedDetailNote.createdBy}
                  </p>
                  <p className="flex-1">
                    <strong>Application Name:</strong> {project.data?.projectName}
                  </p>

                  <p className="flex-1">
                    <strong>Date:</strong>{' '}
                    {dayjs(selectedDetailNote.createdTime).format(
                      'DD MMM YYYY',
                    )}
                  </p>
                </div>
                <p className="mb-4 mt-4 text-gray-700 text-base leading-relaxed">
                  <strong>Description:</strong>
                  <br />
                  {selectedDetailNote.description}
                </p>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default NotesPage;
