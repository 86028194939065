import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal,
  setFormSubmitted,
  setSupportDetails,
  setSupportTickets,
} from '../../../redux/app-global';
import { FaFileImage, FaTimesCircle } from 'react-icons/fa';
import DataTable from './DataTable';
import { RootState } from '../../../redux/store';
import { externalConfig } from '../../../utils/misc.utils';
import axios from 'axios';

const mockSupportTickets = [
  {
    id: 'CF-1567',
    title: 'Transformation Failure: Error Code - 6789',
  },
  {
    id: 'CF-1568',
    title: 'Transformation Process Halted',
  },
  {
    id: 'CF-1569',
    title: 'COBOL Program has compatibility issues',
  },
];

const SupportTicket = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [files, setFiles] = useState<
    { file: File; name: string; size: number; type: string }[]
  >([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [titleError, setTitleError] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const supportDetails = useSelector(
    (state: RootState) => state.appGlobal.supportDetails,
  );

  useEffect(() => {
    if (supportDetails) {
      setTitle(supportDetails.title);
      setDescription(supportDetails.description);
      setFiles(supportDetails.files);
    }
  }, [supportDetails]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesList: FileList = e.target.files;
      const filesArray = Array.from(filesList).map(file => ({
        file: file,
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      setFiles(filesArray);
    }
  };

  const handleSubmission = async () => {
    const payload = {
      title,
      description,
      files,
    };
    const result = await axios.post(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/user/registration`,
      payload,
    );

    if (result.data.status === 'SUCCESS') {
      dispatch(
        setSupportDetails({
          title,
          description,
          files,
        }),
      );
      setTitle('');
      setDescription('');
      setFiles([]);
      dispatch(setFormSubmitted(true));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (title.trim() === '') {
      setTitleError('Title is required');
    } else {
      setTitleError('');
      dispatch(setSupportTickets(mockSupportTickets));
      setIsSubmitted(true);
    }
  };

  function handleClose() {
    dispatch(setActiveModal(null));
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    if (e.target.value.trim() !== '') {
      setTitleError('');
    }
  };

  const handleFileImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClearFiles = () => {
    setFiles([]);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50">
          <div className="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-auto max-w-2xl max-h-[44rem] border rounded-lg bg-white p-8">
            <div className="flex flex-col justify-start items-center p-4 pt-0">
              <div>
                <h2
                  style={{
                    fontSize: '1.25rem',
                    fontWeight: '500',
                    color: '#374151',
                  }}
                >
                  Create Support Ticket
                </h2>
              </div>
              <div className="w-full mt-4">
                <hr />
              </div>
              <div></div>
              {isSubmitted ? (
                <div
                  className="rounded-lg border bg-card text-card-foreground shadow-sm w-full max-w-4xl mx-auto"
                  data-v0-t="card"
                >
                  <div className="flex flex-col space-y-1.5 p-6">
                    <p className="text-sm text-muted-foreground">
                      Following Support tickets are created successfully
                    </p>
                  </div>
                  <div className="p-6 pt-0">
                    <div className="relative w-full overflow-auto">
                      <table className="w-full caption-bottom text-sm">
                        <thead className="[&amp;_tr]:border-b">
                          <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                              Ticket ID
                            </th>
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                              Title
                            </th>
                          </tr>
                        </thead>
                        <tbody className="[&amp;_tr:last-child]:border-0">
                          {mockSupportTickets.map(supportTicket => (
                            <tr
                              key={supportTicket.id}
                              className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                            >
                              <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 font-medium">
                                {supportTicket.id}
                              </td>
                              <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
                                {supportTicket.title}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <div
                    className="w-full mt-4"
                    style={{ textAlign: 'center', fontWeight: '500' }}
                  >
                    Please fill the below details to create the support ticket
                  </div>

                  <div className="w-full mt-4">
                    <label
                      htmlFor="Title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title (*)
                    </label>
                    <input
                      type="text"
                      name="title"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter Title"
                      onChange={handleTitleChange}
                      value={title}
                      style={{
                        width: '100%',
                        borderRadius: '0',
                        border: '1px solid #ccc',
                        backgroundColor: '#fff',
                        padding: '0.5rem 0.75rem',
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        color: '#333',
                        outline: 'none',
                        transition: 'color 0.2s ease-in-out',
                        height: 'unset',
                        fontWeight: '400',
                      }}
                    />
                    {titleError && (
                      <p className="text-red-500 text-xs mt-1">{titleError}</p>
                    )}
                  </div>
                  <div className="w-full mt-4">
                    <label
                      htmlFor="Description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <textarea
                      name="Description"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter Description"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      style={{
                        width: '100%',
                        borderRadius: '0',
                        border: '1px solid #ccc',
                        backgroundColor: '#fff',
                        padding: '0.5rem 0.75rem',
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        color: '#333',
                        outline: 'none',
                        transition: 'color 0.2s ease-in-out',
                        height: 'unset',
                        fontWeight: '400',
                      }}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <label
                      htmlFor="File"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Attach Support Files (Error logs are attached
                      automatically)
                    </label>
                    <div className="relative flex items-center w-full mt-4">
                      <input
                        id="fileInput"
                        type="file"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        multiple
                      />

                      <input
                        type="text"
                        placeholder="Select Logs File"
                        className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-10"
                        style={{
                          borderRadius: '0',
                          border: '1px solid #ccc',
                          backgroundColor: '#fff',
                          padding: '0.5rem 0.75rem',
                          fontSize: '1rem',
                          lineHeight: '1.5',
                          color: '#333',
                          outline: 'none',
                          transition: 'color 0.2s ease-in-out',
                          height: 'unset',
                          fontWeight: '400',
                        }}
                        readOnly
                        value={
                          files.length > 0
                            ? `${files.length} file(s) selected`
                            : ''
                        }
                      />

                      <FaFileImage
                        className="absolute right-3 cursor-pointer text-gray-500"
                        size={20}
                        onClick={handleFileImageClick}
                      />
                      <FaTimesCircle
                        className="absolute right-10 cursor-pointer text-red-500"
                        size={20}
                        onClick={handleClearFiles}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="w-full mt-4 ml-4"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                marginTop: '1rem',
                justifyContent: 'flex-start',
              }}
            >
              {isSubmitted ? (
                <button
                  type="submit"
                  className="bg-blue-500"
                  style={{
                    borderRadius: 4,
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                    fontSize: '1.1rem',
                    color: '#fff',
                    borderWidth: 0,
                    cursor: 'pointer',
                    outline: 'none',
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="submit"
                    className="bg-blue-500"
                    style={{
                      borderRadius: 4,
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      paddingLeft: '1.5rem',
                      paddingRight: '1.5rem',
                      fontSize: '1.1rem',
                      color: '#fff',
                      borderWidth: 0,
                      cursor: 'pointer',
                      outline: 'none',
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button
                    style={{
                      borderRadius: 4,
                      border: '1px solid grey',
                      backgroundColor: 'transparent',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      paddingLeft: '1.5rem',
                      paddingRight: '1.5rem',
                      fontSize: '1.1rem',
                      color: '#333',
                      cursor: 'pointer',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SupportTicket;
