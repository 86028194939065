import { useUser } from 'src/hooks/useUser';
import { IMemberOnlyProps } from './member-only-types';

const MemberOnly = ({ children }: IMemberOnlyProps) => {
  const user = useUser();

  if (user?.role === 'Admin') {
    return null;
  }

  return <>{children}</>;
};

export default MemberOnly;
