import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  CreateProjectProps,
  IDependentProject,
  IProjectFormData,
} from './create-project.types';
import { ProjectDetails } from './create-project-stages/project-details';
import { ProjectDependency } from './create-project-stages/project-dependency-details';
import { IoCreate } from 'react-icons/io5';
import { IUser } from '../admin/users/users.service';
import {
  useCreateNewProjectMutation,
  useLazyGetProjectDependenciesQuery,
  useLazyGetProjectsQuery,
  useUpdateProjectMutation,
} from 'src/redux/api-slice';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { SharedService } from 'src/services/shared-service';

const CreateProject = ({
  isOpen,
  onClose,
  selectedProject,
  isEditing,
  onSubmitEdit,
}: CreateProjectProps) => {
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const [currentPage, setCurrentPage] = useState(1);

  const [projectFormData, setProjectFormData] = useState<IProjectFormData>({
    projectId: isEditing && selectedProject ? selectedProject.projectId : 0,
    projectName:
      isEditing && selectedProject ? selectedProject.projectName : '',
    projectType:
      isEditing && selectedProject
        ? selectedProject.projectType === 'TRANSFORMATION'
          ? 'Transform'
          : 'SmartDoc'
        : '',
    startDate: isEditing && selectedProject ? selectedProject.startDate : '',
    endDate: isEditing && selectedProject ? selectedProject.endDate : '',
    organizationName: license?.licenseFor || '',
    organizationId: '',
    teamMembers:
      isEditing && selectedProject
        ? selectedProject.projectMemberList?.filter(
            member => member.memberType === 'MEMBER',
          ) || []
        : [],
    projectLead:
      isEditing && selectedProject
        ? selectedProject.projectMemberList?.find(
            member => member.memberType === 'LEAD',
          ) || null
        : null,
    dependentProjects:
      isEditing && selectedProject ? selectedProject.dependencyList || [] : [],
  });

  const [createNewProject, { isLoading }] = useCreateNewProjectMutation();
  const [updateProject, { isLoading: isUpdating }] = useUpdateProjectMutation();
  const projectsQuery = useLazyGetProjectsQuery();
  const projectDependenciesQuery = useLazyGetProjectDependenciesQuery();

  useEffect(() => {
    if (selectedProject) {
      setProjectFormData({
        projectId: isEditing && selectedProject ? selectedProject.projectId : 0,
        projectName:
          isEditing && selectedProject ? selectedProject.projectName : '',
        projectType:
          isEditing && selectedProject
            ? selectedProject.projectType === 'TRANSFORMATION'
              ? 'Transform'
              : 'SmartDoc'
            : '',
        startDate:
          isEditing && selectedProject ? selectedProject.startDate : '',
        endDate: isEditing && selectedProject ? selectedProject.endDate : '',
        organizationName: license?.licenseFor || '',
        organizationId: '',
        teamMembers:
          isEditing && selectedProject
            ? selectedProject.projectMemberList?.filter(
                member => member.memberType === 'MEMBER',
              ) || []
            : [],
        projectLead:
          isEditing && selectedProject
            ? selectedProject.projectMemberList?.find(
                member => member.memberType === 'LEAD',
              ) || null
            : null,
        dependentProjects:
          isEditing && selectedProject
            ? selectedProject.dependencyList || []
            : [],
      });
    }
  }, []);

  const handleProjectFormChange = (
    key: string,
    value: string | IUser | IUser[] | IDependentProject[] | null,
  ) => {
    if (
      selectedProject?.completePercentage === 100 &&
      ['teamMembers', 'projectLead'].includes(key)
    ) {
      Swal.fire({
        icon: 'error',
        text: 'You cannot change the team members once the application is already completed.',
      });
      return;
    }
    setProjectFormData({ ...projectFormData, [key]: value });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleProjectDetailsValidation = () => {
    const {
      projectName,
      projectType,
      startDate,
      endDate,
      teamMembers,
      projectLead,
      dependentProjects,
    } = projectFormData;

    const validations: Array<{
      condition: boolean;
      icon: SweetAlertIcon;
      title: string;
      text: string;
    }> = [
      {
        condition: !projectName,
        icon: 'warning',
        title: 'Missing Application Name',
        text: 'Please enter a application name.',
      },
      {
        condition: !projectType,
        icon: 'warning',
        title: 'Missing Application Type',
        text: 'Please select a application type.',
      },
      {
        condition: !startDate,
        icon: 'warning',
        title: 'Missing Start Date',
        text: 'Please select a start date.',
      },
      {
        condition: !endDate,
        icon: 'warning',
        title: 'Missing End Date',
        text: 'Please select an end date.',
      },
      {
        condition: endDate < startDate,
        icon: 'error',
        title: 'Invalid Date Range',
        text: 'End date should be greater than start date.',
      },
      {
        condition: teamMembers.length > 0 && !projectLead,
        icon: 'warning',
        title: 'Missing Application Lead',
        text: 'Please select a application lead.',
      },
      {
        condition:
          isEditing &&
          dependentProjects.some(
            dp => dp.projectId === selectedProject?.projectId,
          ),
        icon: 'error',
        title: 'Invalid Dependent Application',
        text: 'Same application cannot be added in dependent project.',
      },
    ];

    for (const { condition, icon, title, text } of validations) {
      if (condition) {
        Swal.fire({ icon, title, text });
        return false;
      }
    }

    return true;
  };

  const resetFormData = () => {
    setCurrentPage(1);
    setProjectFormData({
      projectId: 0,
      projectName: '',
      projectType: '',
      startDate: '',
      endDate: '',
      organizationName: license?.licenseFor || '',
      organizationId: '',
      teamMembers: [],
      projectLead: null,
      dependentProjects: [],
    });
  };

  const handleClose = () => {
    setCurrentPage(1);
    resetFormData();
    onClose();
  };

  useEffect(() => {
    if (isEditing && selectedProject) {
      console.log(
        'selected project dates:',
        selectedProject.startDate,
        selectedProject.endDate,
      );
      const allMembers =
        selectedProject.projectMemberList?.map(member => ({
          ...member,
          id: member.userId,
          name: member.name,
          role: member.memberType,
        })) || [];
      const projectLead =
        allMembers.find(member => member.memberType === 'LEAD') || null;
      const dependentProjects =
        selectedProject.dependencyList?.map((dependency: any) => ({
          ...dependency,
          projectId: dependency.projectId,
          projectName: dependency.projectName,
          stage: dependency.stage,
          reason: dependency.reason,
        })) || [];
      setProjectFormData({
        ...projectFormData,
        projectId: selectedProject.projectId,
        projectName: selectedProject.projectName,
        projectType:
          selectedProject.projectType?.toLowerCase() === 'smart_doc'
            ? 'SmartDoc'
            : 'Transformation',
        startDate: selectedProject.startDate,
        endDate: selectedProject.endDate,
        teamMembers: allMembers,
        projectLead: projectLead,
        dependentProjects: dependentProjects,
        organizationName: license?.licenseFor || '',
      });
    } else {
      setProjectFormData({
        ...projectFormData,
        organizationName: license?.licenseFor || '',
      });
    }
  }, [isEditing, selectedProject, license]);

  const handleCreate = async () => {
    const isFormValid = handleProjectDetailsValidation();

    if (!isFormValid) {
      return;
    }

    let response;
    if (isEditing) {
      response = await updateProject(projectFormData);
      console.log(
        'Application edit:',
        selectedProject?.projectId,
        projectFormData,
      );
    } else {
      response = await createNewProject(projectFormData);
      console.log('Application create:', response);
    }

    if (response) {
      if (response.data.status === 'ERROR') {
        if (response.data && response.data.message) {
          const circularDependency = response.data.message?.includes(
            'circular dependency',
          );
          Swal.fire({
            icon: 'error',
            title: circularDependency
              ? 'Dependency Configuration Failed'
              : isEditing
                ? 'Application edit failed'
                : 'Application creation failed',
            text: `${response.data.message}.Please choose a different Application.`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Application creation failed',
            text: 'Please try again later.',
          });
        }
        return;
      }
    }
    onClose();

    Swal.fire({
      icon: 'success',
      title: isEditing
        ? 'Application updated successfully'
        : 'Application created successfully',
    });
    if (onSubmitEdit) {
      onSubmitEdit();
    }
    resetFormData();
    await projectsQuery[0]();
    await projectDependenciesQuery[0]();
    // if (SharedService.refetchProjectsInDashBoard) {
    //   SharedService.refetchProjectsInDashBoard();
    // }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-5 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-2xl max-w-2xl w-full h-[95%] overflow-hidden flex flex-col relative">
        <div className="flex justify-between items-center pb-2 sticky top-0 bg-white z-10 py-2 border-b">
          <h2 className="text-xl font-bold">
            {isEditing ? 'Edit Application' : 'Create New Application'}
          </h2>

          <button
            onClick={handleClose}
            className="flex items-center justify-center w-9 h-9 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
            aria-label="Close modal"
          >
            <FaTimes size={15} />
          </button>
        </div>

        <div className="flex flex-col h-full relative">
          <div className=" relative mt-4 max-h-[82%] overflow-auto overflow-x-hidden flex-grow">
            {currentPage === 1 && (
              <ProjectDetails
                projectFormData={projectFormData}
                handleProjectFormDataChange={handleProjectFormChange}
                isEditing={isEditing}
              />
            )}
            {currentPage === 2 && (
              <ProjectDependency
                projectFormData={projectFormData}
                handleProjectFormDataChange={handleProjectFormChange}
              />
            )}
          </div>
          <div className="h-[10%] flex flex-col items-center  bg-white z-10 py-2 border-t pt-4 justify-end">
            {projectFormData.projectType !== 'SmartDoc' ? (
              <div className="flex space-x-2 mb-4">
                <div
                  className={`w-3 h-3 rounded-full ${currentPage === 1 ? 'bg-blue-500' : 'bg-gray-300'}`}
                />
                <div
                  className={`w-3 h-3 rounded-full ${currentPage === 2 ? 'bg-blue-500' : 'bg-gray-300'}`}
                />
              </div>
            ) : (
              <div className="h-3 mb-4" />
            )}

            <div className="flex justify-between w-full">
              {currentPage > 1 && projectFormData.projectType != 'SmartDoc' && (
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={isLoading}
                  className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
                >
                  <span>&larr;</span>&nbsp;&nbsp;
                  <span>Previous</span>
                </button>
              )}

              <div className="flex justify-end w-full mt-auto">
                {currentPage < 2 &&
                  projectFormData.projectType != 'SmartDoc' && (
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={isLoading}
                      className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
                    >
                      Next &nbsp;&rarr;
                    </button>
                  )}

                {projectFormData.projectType === 'SmartDoc' && (
                  <button
                    onClick={handleCreate}
                    disabled={isLoading}
                    className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition flex items-center space-x-2"
                  >
                    <span>{isEditing ? 'Update' : 'Create'}</span>
                    <span>
                      <IoCreate />
                    </span>
                  </button>
                )}

                {currentPage === 2 &&
                  projectFormData.projectType != 'SmartDoc' && (
                    <button
                      onClick={handleCreate}
                      disabled={isLoading}
                      className="bg-blue-500 text-sm text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition flex items-center space-x-2"
                    >
                      <span>{isEditing ? 'Update' : 'Create'}</span>
                      <span>
                        <IoCreate />
                      </span>
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
