import { useGetProjectDetailQuery } from 'src/redux/api-slice';
import ProjectSideBar from '../../../src/components/project-sidebar/project-sidebar';
import StagesComponent from './card/stages-component/stages-component';
import ProjectArtifacts from './project-artifacts/project-artifacts';
import ProjectOverviewTile from './project-overview-tile/project-overview-tile';
import ProjectStatus from './project-status/project-status';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/App.navigation';
import AddNotesWidget from 'src/components/add-notes-widget/add-notes-widget';
import { useSetProjectContext } from './card/stages-component/useSetProjectContext';

const artifacts = [
  {
    id: 1,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
  {
    id: 2,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
  {
    id: 3,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
  {
    id: 4,
    name: 'JCL1',
    type: 'JCL',
    updatedDate: '02 Sep 2024',
    uploadedBy: 'John Doe',
    stage: 'Transform',
  },
];

const ProjectOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('projectId') || ''; // || 'bd8dfb56-e06e-47d0-b37a-49a536e3c271';

  const [alertsShown, setAlertsShown] = useState({
    projectDetail: false,
    missingProjectId: false,
  });

  const { data, isLoading, error } = useGetProjectDetailQuery(
    { projectId },
    { refetchOnFocus: true, refetchOnMountOrArgChange: 10 },
  );

  useSetProjectContext();

  useEffect(() => {
    if (!projectId) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [projectId]);

  if (error && !alertsShown.projectDetail) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to load application details. Please try again later.',
    });
    setAlertsShown({ ...alertsShown, projectDetail: true });
  }

  const dashboard = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div className="flex flex-row bg-whiteSmoothLight w-full">
      <div className="fixed bottom-8 right-10">
        <div className="h-14 w-14 bg-grey20 rounded-full flex items-center justify-center">
          <AddNotesWidget />
        </div>
      </div>
      <div className="mx-4 my-4 mb-0 pb-0 mr-0 h-[100vh] w-8">
        <ProjectSideBar activeScreen={'Home'} />
      </div>
      <div className="w-full mx-4 my-4 px-4 pb-8">
        <button
          className="bg-blue-600 text-white text-sm px-3 py-2 rounded-md relative ml-auto float-right"
          onClick={dashboard}
        >
          Back to Dashboard
        </button>

        <div>
          <h3 className="text-2xl font-bold my-4">Application Overview</h3>
          <p>
            Review the key details and progress of your application. Track the
            transformation steps, upload necessary artifacts, and monitor your
            journey from COBOL to Java.
          </p>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div className="flex justify-start mx-0 my-8 gap-6">
              <ProjectOverviewTile />
              <StagesComponent />
            </div>
            <div>
              <ProjectStatus />
            </div>
            <div className="mt-8 bg-white p-6 py-8 mb-8 rounded-md shadow-md">
              <ProjectArtifacts rows={data?.projectArtifactList || []} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProjectOverview;
