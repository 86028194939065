import axios from 'axios';
import { useEffect, useState } from 'react';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { helpTexts } from 'src/helpTexts';
import { useGetGlobalProjectTPSettingsQuery } from 'src/redux/api-slice';
import { externalConfig } from 'src/utils/misc.utils';
import Swal from 'sweetalert2';

const TokenProcessor = () => {
    const { data, error, isLoading, refetch } = useGetGlobalProjectTPSettingsQuery();
    const [settings, setSettings] = useState({
        validationTimeOut: data?.data?.validationTimeoutInMins||'',
    });
    const pageHelpTexts = helpTexts[0].SYSTEM_SETTINGS;
    useEffect(() => {
        setSettings({
            validationTimeOut: data?.data?.validationTimeoutInMins|| '',
          })
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Unable to load global Admin Token Processor Settings. Please try again later.',
            });
        }
    }, [error,data]);

    const handleUpdateOverrideSettings = async () => {
        try {
            const response = await axios.put(
                `${externalConfig.REACT_APP_GATEWAY_URL}/cf-scanner-api/validation/config`,

                {
                    validationTimeoutInMins: settings.validationTimeOut,
                }
            );
            if (response?.data?.status === 'SUCCESS') {
                Swal.fire({
                    icon: 'success',
                    title: 'Successfully Updated',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
                refetch();
            }
            if (response?.data?.status === 'ERROR') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error Occured',
                    text: response?.data?.message,
                    confirmButtonText: 'OK',
                });
            }
        } catch (error: any) {
            console.error('Error Occurred', error?.response?.data);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error?.response?.data?.message,
                confirmButtonText: 'OK',
            });
        }
    };

    const validationTimeoutInMins = (e) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            validationTimeOut: e.target.value,
        }));
    };

    const noProjectSettings = settings && Object.keys(settings).length === 0;

    return (
        <div className="p-1 min-h-screen">
            <div className="max-w-3xl bg-white shadow-md rounded-lg p-6">
                {isLoading ? (
                    <div>Loading...</div>
                ) : noProjectSettings ? (
                    <div className="text-center text-lg text-gray-500">No Token Processor Settings</div>
                ) : (
                    <>
                        <div className="mt-6">
                                <label className="block text-md font-medium mb-2 flex items-center gap-2">
                                    Validation Timeout Limit (in minutes)
                                    <HelpTextCallout
                                        calloutText={pageHelpTexts[0].description}
                                    />
                                </label>
                                <input
                                    type="text"
                                    value={settings.validationTimeOut}
                                    onChange={validationTimeoutInMins}
                                    className="border rounded p-2 w-full"
                                />
                        </div>
                    </>
            )}
            {isLoading || noProjectSettings ? null : (
                <div className="mt-6 flex justify-end">
                    <button
                        onClick={handleUpdateOverrideSettings}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        Save System Settings
                    </button>
                </div>
            )}
        </div>
    </div >
  );
};

export default TokenProcessor;
