import {
  initializeIcons,
  PrimaryButton,
  Toggle,
  IconButton,
  TooltipHost,
} from '@fluentui/react';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import SVGTrash from '../../../assets/images/svg/trash.icon';
import ProgramTable from '../../../components/data-table/data-program-table';
import { VALID_PROGRAM_FILE_TYPES } from './missing-program-types';
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import axios from 'axios';
import Dropdown from '../../../components/custom-dropdown/custom-dropdown';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal,
  setCurrentIntent,
  setIgnoredMissingArtifacts,
  setNextMissingPage,
  setNextMissingPageTest,
  setUploadedMissingArtifacts,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  IUploadedArtifacts,
  TableData,
} from '../../../redux/app-global/app-global.types';
import React from 'react';
import { SharedService } from '../../../services/shared-service';
import {
  commaSeparated,
  downloadExtractJcl,
  externalConfig,
  isValidDataset,
} from '../../../utils/misc.utils';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import UploadList from '../../../components/upload-list/upload-list';
import MissingUploadList, {
  UploadMissingListRef,
} from '../../../components/missing-artifact-upload/missing-artifact-upload';
import {
  ExtractJclRequest,
  ExtractType,
} from '../extract-artifact/extract-artifact.model';
import { FaSpinner } from 'react-icons/fa';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import Swal from 'sweetalert2';
import { MissingPageService } from '../../../services/missing-artifacts.service';
import { useGetMissingProgramsQuery } from '../../../redux/api-slice';
import { startScan } from '../../../components/upload-list/upload-list.service';
import { getSearchParameter } from 'src/utils/url.utils';
initializeIcons();

const MissingPrograms = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const options = ['Active', 'Ignore', 'Assembler'];
  const [data, setData] = useState<TableData[]>([]);
  const [missingArtifacts, setMissingArtifacts] =
    useState<IUploadedArtifacts>();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([{ internal: '', external: '' }]);
  const [headerOption, setHeaderOption] = useState('Active');
  const programData = useSelector(
    (state: RootState) => state.appGlobal.programData,
  );
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [alertShown, setAlertShown] = useState({ missingPrograms: false });
  const missingProgramQuery = useGetMissingProgramsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const pagHelpTexts = helpTexts[0].MISSING_PROGRAM;
  const projectId = getSearchParameter('projectId');

  const triggerFileUpload = (event, itemKey) => {
    if (uploadMissingListRef.current) {
      uploadMissingListRef.current.handleFileUpload(event, itemKey);
    }
  };
  const handleFilesUpdate = updatedFiles => {
    setFiles(updatedFiles);
  };
  const headers: React.ReactNode[] = [
    <Dropdown
      options={options}
      selectedOption={headerOption}
      onOptionSelect={option => handleHeaderDropdownChange(option)}
      key="1"
    />,
    'Program',
    'Called By',
    !toggleValue && !data.every(item => item.uploaded) && 'Upload',
  ];
  const rowFields = item => [
    <Dropdown
      options={options}
      selectedOption={item.selectedOption}
      onOptionSelect={option => handleOptionSelect(option, item.key)}
      key={item.key}
    />,
    <div
      className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
      key={item.key}
    >
      {item.name}
    </div>,
    item.requiredBy.callerName.length <= 4 ? (
      <div
        className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
        key={item.key}
      >
        {item.requiredBy.callerName.join(', ')}
      </div>
    ) : (
      <div key={item.key}>
        <TooltipHost
          id={`tooltip-${item.requiredBy.callerName[0]}`}
          content={item.requiredBy.callerName
            .slice(4, item.requiredBy.callerName.length - 1)
            .join(', ')}
        >
          <div
            className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
          >
            {item.requiredBy.callerName.slice(0, 3).join(', ')}
          </div>
          <span
            className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
          >
            ... and {item.requiredBy.callerName.length - 3} more
          </span>
        </TooltipHost>
      </div>
    ),
    !toggleValue &&
    (item.uploaded ? (
      ''
    ) : (
      <MissingUploadBtn
        handleFileUpload={triggerFileUpload}
        item={item}
        buttonStyle={{
          backgroundColor:
            item.selectedOption !== 'Active' ? '#e0e0e0' : '#007bff',
          cursor:
            item.selectedOption !== 'Active' ? 'not-allowed' : 'pointer',
        }}
        accept={`${VALID_PROGRAM_FILE_TYPES.map(type => '.' + type).join(',')}`}
      />
    )),
  ];
  useEffect(() => {
    if (programData && programData?.length > 0) {
      setData(programData);
      return;
    }

    const { missingData = [], ignoredData = [] } =
      missingProgramQuery.data || {};

    const transformedData: TableData[] = missingData.map(
      (item: any, index: number) => ({
        key: index,
        selectedOption: 'Active',
        name: item.name,
        requiredBy: item.referredIn,
        uploaded: null,
      }),
    );
    const missingLen = transformedData.length;
    ignoredData.forEach((item: any, index: number) => {
      transformedData.push({
        key: index + missingLen + 1,
        selectedOption: 'Ignore',
        name: item.name,
        requiredBy: item.referredIn,
        uploaded: null,
      } as any);
    });
    setData(transformedData);
  }, [missingProgramQuery]);

  const handleHeaderDropdownChange = newOption => {
    // const newOption = e.target.value;
    setHeaderOption(newOption);
    setData(
      data.map(row => ({
        ...row,
        selectedOption: newOption,
      })),
    );
  };

  const handleInputChange = (index, column, value) => {
    if (value.trim()) {
      setInternalError(null);
    }
    const newRows = [...rows];
    newRows[index][column] = value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { internal: '', external: '' }]);
  };

  const handleRemoveRow = index => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleOptionSelect = (option, index) => {
    const newData = [...data];
    newData[index].selectedOption = option;
    setData(newData);
  };
  const sendProgramInfo = async () => {
    const formData = new FormData();
    const requestBody: any = [];
    data.forEach(row => {
      const item = {
        name: row.name,
        status: row.selectedOption || '',
        file: row.uploaded || '',
        referredIn: row.requiredBy || [],
      };
      requestBody.push(item);
    });

    formData.append('missingData', JSON.stringify(requestBody));

    Object.values(files).forEach(file => {
      console.log((file as any).originalFile);
      if (file) {
        formData.append('fileList', (file as any).originalFile);
      }
    });

    try {
      setLoading(true);
      const response = await axios.post(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/missing-artifact/${projectId}/PROGRAM`,
        formData,
      );

      if (
        response.data?.status !== 'SUCCESS' ||
        response.data?.message === 'Some artifacts are missing '
      ) {
        Swal.fire({
          title: 'Resolve all missing artifacts',
          text:
            response.data?.message ||
            'Failed to upload missing artifacts. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });

        await missingProgramQuery.refetch();
        return;
      }

      dispatch(setUploadedMissingArtifacts(missingArtifacts));

      const nextIntent = MissingPageService.getRouteFromAction(
        response.data?.data?.nextAction,
      );

      if (nextIntent === 'REPORT') {
        dispatch(setIgnoredMissingArtifacts(response.data?.data?.ignoredData));
      }

      if (nextIntent === EAppIntent.UPLOADING_ARTIFACT) {
        const scanStarted = await startScan(dispatch);
        if (!scanStarted) {
          return;
        }
      }

      dispatch(setCurrentIntent(nextIntent));
    } catch (err) {
      console.log(err);
      SharedService.genericAlertModal.title =
        'Upload of Missing Artfacts Failed!';
      SharedService.genericAlertModal.description =
        (err as any)?.message || 'Something went wrong !';
      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
      }, 0);
    } finally {
      setLoading(false);
    }
  };
  const changeDisplay = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setToggleValue(checked);
  };
  const downloadCSVTemplate = () => {
    const header = ['Internal Program Patterns', 'External Program Patterns'];
    const csvRows = [
      header.join(','),
      ...rows.map(row => [row.internal, row.external].join(',')),
    ];

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'MissingProgramsTemplate.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCSVFileUpload = event => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: results => {
          const parsedRows = results.data
            .filter(
              row =>
                row['Internal Program Patterns'] ||
                row['External Program Patterns'],
            )
            .map(row => ({
              internal: row['Internal Program Patterns'] || '',
              external: row['External Program Patterns'] || '',
            }));
          setRows(parsedRows);
        },
        error: error => {
          console.error('Error parsing CSV:', error);
        },
      });
    }
  };
  const [datasetNames, setDatasetNames] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validationError = commaSeparated(value.trim());

    if (validationError) {
      setError(validationError);
      setSuccess(null);
    } else {
      setError(null);
      setSuccess(null);
    }
    setDatasetNames(value);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const validationError = commaSeparated(datasetNames.trim());
    if (validationError) {
      setError(validationError);
      setSuccess(null);
      return;
    }

    if (!datasetNames.trim()) {
      setError('Dataset name is required.');
      setSuccess(null);
      return;
    }

    const datasetArray = datasetNames.split(',').map(name => name.trim());

    const data: ExtractJclRequest = {
      dataset: {
        listing: datasetArray,
      },
      downloadFor: 'LISTING' as ExtractType,
    };

    const isDownloadSuccessful = await downloadExtractJcl(data);

    if (isDownloadSuccessful) {
      setSuccess('Data successfully posted and file downloaded!');
      setError(null);
      setDatasetNames('');
    } else {
      setError('Failed to post data or download the file. Please try again.');
      setSuccess(null);
    }
  };

  const [internalError, setInternalError] = useState<string | null>(null);
  const [internalSuccess, setInternalSuccess] = useState<string | null>(null);
  const InternalFormSubmit = async () => {
    const requestBody: any = [];
    rows.forEach(row => {
      const item = {
        [row.internal]: row.external,
      };
      if (row.external !== '' && row.internal !== '') requestBody.push(item);
    });

    if (Object.keys(requestBody).length === 0) {
      setInternalError('Please enter at least one valid pattern.');
      setInternalSuccess(null);
      return;
    }
    try {
      const apiUrl = `${externalConfig.REACT_APP_GATEWAY_URL}/cf-report-service/missing-artifact/program-mapping/${projectId}`;
      const response = await axios.post(apiUrl, requestBody);
      setInternalSuccess('Data successfully posted!');
      if (response.data?.data) {
        const transformedData: TableData[] = response.data?.data.map(
          (item: any, index: number) => ({
            key: index,
            selectedOption: 'Active',
            name: item.name,
            requiredBy: item.referredIn,
            uploaded: null,
          }),
        );
        setData(transformedData);
        setInternalError(null);
        return;
      }
      setData([]);
    } catch (err) {
      setInternalError('Failed to post data. Please try again.');
      setInternalSuccess(null);
    }
  };

  if (missingProgramQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (missingProgramQuery.isError && !alertShown.missingPrograms) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Unable to fetch Missing Program details. Please try again later.',
      footer: 'Please contact support',
      showCancelButton: true,
      confirmButtonText: 'Retry',
      cancelButtonText: 'Go Back',
    }).then(result => {
      if (result.isConfirmed) {
        missingProgramQuery.refetch();
        setAlertShown({ missingPrograms: false });
      } else {
        dispatch(setCurrentIntent(EAppIntent.REPORT));
      }
    });
    setAlertShown({ missingPrograms: true });
    return null;
  }

  return (
    <div
      className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}
    >
      <div className="p-2 px-8 mb-4">
        <StepperComponent />
      </div>
      <div className="mb-2 border rounded-lg px-8 py-8 shadow max-w-full">
        <h1 className="text-3xl font-semibold mb-4">
          Missing Artifacts Workspace
        </h1>

        <hr />
        <div className="flex flex-col gap-4">
          <div className="text-left mt-4 gap-4 text-l font-semibold">
            Missing Program Information
          </div>
          <div>
            Use this workspace to resolve missing programs by uploading files, providing name mappings, or extracting programs from the mainframe. Define naming patterns, upload individual programs, or upload bundled files. Alternatively, switch to "Extract Programs from Mainframe" mode to provide dataset names containing the missing program details, generate the required extraction JCL, and extract the programs directly from the mainframe. Ensure all actions are completed to address the missing programs before proceeding.
          </div>
          <div className="flex items-center gap-2">
            <HelpTextCallout calloutText={pagHelpTexts[4].description} />
            <span className="text-lg">Upload Programs</span>
            <Toggle className="mt-2" onChange={changeDisplay} />
            <span className="text-lg">Extract Programs from Mainframe</span>
          </div>
          {toggleValue && (
            <div>
              <h3>Provide dataset names below (comma-separated) for extracting missing Program files. Then, click "Generate Extraction JCL" to create the necessary JCL for extracting the Program files:</h3>
              <input
                type="text"
                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                value={datasetNames}
                onChange={handleChange}
                placeholder="Enter dataset names..."
              />
              {error && (
                <TooltipHost content={error} id="error-tooltip">
                  <div className="mt-2 text-red-500">{error}</div>
                </TooltipHost>
              )}
              {success && (
                <TooltipHost content={success} id="success-tooltip">
                  <div className="mt-2 text-green-500">{success}</div>
                </TooltipHost>
              )}
            </div>
          )}
          <div>
            {toggleValue && (
              <div className="flex flex-row items-center mt-1">
                <HelpTextCallout calloutText={pagHelpTexts[6].description} />
                <PrimaryButton
                  text={'Generate Extraction JCL'}
                  className="rounded-md"
                  styles={{ root: { backgroundColor: '#1363DF' } }}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </div>
          {!toggleValue && (
            <>
              <div className="font-semibold mt-2">Option 1: Map Program Name Patterns</div>
            </>
          )}
          {!toggleValue ? (
            <>
              <div className="flex flex-row items-center">
                <p>Use this section to define mappings for internal and external program name patterns.</p>
                <HelpTextCallout calloutText={pagHelpTexts[7].description} />
              </div>
              <div className="flex flex-row items-center justify-end gap-2">
                <HelpTextCallout calloutText={pagHelpTexts[8].description} />
                <p
                  className="cursor-pointer hover:text-blue-500 text-blue-700 text-sm"
                  onClick={downloadCSVTemplate}
                >
                  Download CSV Template
                </p>
                <HelpTextCallout calloutText={pagHelpTexts[9].description} />
                <label
                  htmlFor="csvUpload"
                  className="cursor-pointer hover:text-blue-500 rounded transition duration-300 inline-block text-blue-700 text-sm"
                >
                  Upload CSV
                </label>
                <input
                  id="csvUpload"
                  type="file"
                  accept=".csv"
                  onChange={handleCSVFileUpload}
                  className="hidden"
                />
              </div>
              <div className="mb-2 border rounded-lg p-4 px-2 shadow">
                <div className="container mx-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left font-semibold divide-y divide-gray-200">
                          <div className="flex flex-row items-center gap-2">
                            Internal Program Patterns
                            <HelpTextCallout
                              calloutText={pagHelpTexts[10].description}
                            />
                          </div>
                        </th>
                        <th className="px-4 py-2 text-left font-semibold divide-y divide-gray-200">
                          <div className="flex flex-row items-center gap-2">
                            External Program Patterns
                            <HelpTextCallout
                              calloutText={pagHelpTexts[10].description}
                            />
                          </div>
                        </th>
                        <th className="px-4 py-2 text-left font-semibold divide-y divide-gray-200">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2">
                            <input
                              type="text"
                              value={row.internal}
                              placeholder="Enter Pattern"
                              onChange={e =>
                                handleInputChange(
                                  index,
                                  'internal',
                                  e.target.value,
                                )
                              }
                              className={`w-full rounded px-2 py-1 ${row.internal === '' ? 'border border-gray-300' : ''}`}
                            />
                          </td>
                          <td className="px-4 py-2">
                            <input
                              type="text"
                              value={row.external}
                              placeholder="Enter Pattern"
                              onChange={e =>
                                handleInputChange(
                                  index,
                                  'external',
                                  e.target.value,
                                )
                              }
                              className={`w-full rounded px-2 py-1 ${row.internal === '' ? 'border border-gray-300' : ''}`}
                            />
                          </td>

                          <td className="px-4 py-2 text-l">
                            <button
                              onClick={() => handleRemoveRow(index)}
                              className="bg-red-500 text-white px-2 py-1 rounded text-sm hover:bg-red-700"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="mt-4 flex flex-row items-center justify-end mr-16">
                    <button
                      onClick={handleAddRow}
                      className="px-4 py-2 right-0 hover:text-blue-500 text-blue-700 text-sm"
                    >
                      Add Row
                    </button>
                  </div>
                  <div>
                    {internalError && (
                      <TooltipHost content={internalError} id="error-tooltip">
                        <div className="mt-2 text-red-500">{internalError}</div>
                      </TooltipHost>
                    )}
                    {internalSuccess && (
                      <TooltipHost content={internalSuccess} id="success-tooltip">
                        <div className="mt-2 text-green-500">
                          {internalSuccess}
                        </div>
                      </TooltipHost>
                    )}
                  </div>
                  <div className="mt-4 mx-4">
                    <button
                      className="px-4 py-2 right-0 bg-blue-500 text-white rounded-md text-sm"
                      onClick={InternalFormSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {!toggleValue ? (
            <div>
              <div className="font-semibold">Option 2: Upload Missing Programs Individually</div>
              <p className="mt-4 my-4">
                Resolve missing programs by uploading each program file individually. Select the action (Active, Ignore, Assembler) for each program and use the "Upload" button to attach the corresponding files.
              </p>
            </div>
          ) : (
            <div className="mt-2 text-l font-semibold">Review Missing Program Information</div>
          )}
          <ReusableTable
            items={data}
            headers={headers}
            rowFields={rowFields}
            helpTexts={pagHelpTexts}
          />
          {!toggleValue ? (
            <MissingUploadList
              ref={uploadMissingListRef}
              setData={setData}
              setMissingArtifacts={setMissingArtifacts as any}
              type="Programs"
              data={data}
              onFilesUpdate={handleFilesUpdate}
              pageHelpText={pagHelpTexts}
            />
          ) : (
            ''
          )}
        </div>

        {!toggleValue && (
          <div>
            {Array.isArray(data) &&
              (data.every(
                item =>
                  item.selectedOption !== 'Active' || item.uploaded !== null,
              ) ||
                Object.values(files).length > 0 ||
                toggleValue ||
                true) && (
                <div className="flex flex-row gap-4 items-center">
                  <PrimaryButton
                    text="Save and Continue"
                    className="mt-8 rounded-md"
                    styles={{ root: { backgroundColor: '#1363DF' } }}
                    onClick={sendProgramInfo}
                    disabled={loading}
                  />
                  {loading && (
                    <div className="flex gap-2 mt-4">
                      <FaSpinner className="animate-spin" />
                      <p>Updating Missing data ....</p>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
export default MissingPrograms;
