import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Dropdown from 'src/components/custom-dropdown/custom-dropdown';
import HelpTextCallout from 'src/components/help-text-callout/help-text-callout';
import { helpTexts } from 'src/helpTexts';
import { useGetGlobalProjectAdminSettingsQuery } from 'src/redux/api-slice';
import { externalConfig } from 'src/utils/misc.utils';
import Swal from 'sweetalert2';

const SmartDoc = () => {
  const { data, error, isLoading } = useGetGlobalProjectAdminSettingsQuery();
  const pageHelpTexts = helpTexts[0].SMARTDOC_SETTINGS;
  console.log(data)

  // const transformationSettings = data?.filter(item => item.type === 'SMART_DOC');
  const [settings, setSettings] = useState({
    useLlmModel: 'OpenAI',
    openApiKey: data?.openai_api_key || '',
    openAiBaseUrl: data?.openai_base_url || '',
    openAIModel: data?.openai_model || '',
  });

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Unable to load global Admin Experimental Config Settings. Please try again later.',
      });
    }
  }, [error]);

  const handleUpdateOverrideSettings = async () => {
    try {
      const response = await axios.post(
        `${externalConfig.REACT_APP_GATEWAY_URL}/cf-openai-service/cf/ai/update-openai-config`,
        {
          openai_api_key : settings?.openApiKey,
          openai_base_url : settings?.openAiBaseUrl
        }
      );
      if (response?.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Updated',
          text: response?.data,
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occured',
          text: response?.data,
          confirmButtonText: 'OK',
        });
      }
    } catch (error: any) {
      console.error('Error Occurred', error?.response?.data);
      let errorMessage =
        'An unexpected error occurred. Please try again later.';
      const messageMatch = error?.response?.data?.match(/'message': '([^']+)'/);
      if (messageMatch && messageMatch[1]) {
        errorMessage = messageMatch[1];
      }
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
        confirmButtonText: 'OK',
      });
    }
  };

  const handleSelectLLM = newValue => {
    setSettings(prevSettings => ({
      ...prevSettings,
      useLlmModel: newValue,
    }));
  };

  const handleOpenAIKeyChange = e => {
    setSettings(prevSettings => ({
      ...prevSettings,
      openApiKey: e.target.value,
    }));
  };

  const handleOpenAIBaseUrlChange = e => {
    setSettings(prevSettings => ({
      ...prevSettings,
      openAiBaseUrl: e.target.value,
    }));
  };

  const noProjectSettings = settings && Object.keys(settings).length === 0;

  const options = ['None', 'OpenAI'];

  return (
    <div className="p-1 min-h-screen">
      <div className="max-w-3xl bg-white shadow-md rounded-lg p-6">
        {isLoading ? (
          <div>Loading...</div>
        ) : noProjectSettings ? (
          <div className="text-center text-lg text-gray-500">
            No SmartDoc Settings
          </div>
        ) : (
          <>
            <label className="block text-md font-medium mb-2 flex items-center gap-2">
              Select LLM
              <HelpTextCallout calloutText={pageHelpTexts[0].description} />
            </label>
            <Dropdown
              options={options}
              selectedOption={settings.useLlmModel}
              onOptionSelect={handleSelectLLM}
            />

            {settings.useLlmModel === 'OpenAI' && (
              <>
                <div className="mt-6">
                  <label className="block text-md font-medium mb-2 flex items-center gap-2">
                    OpenAI Key
                    <HelpTextCallout calloutText={pageHelpTexts[0].description} />
                  </label>
                  <input
                    type="password"
                    value={settings.openApiKey}
                    onChange={handleOpenAIKeyChange}
                    className="border rounded p-2 w-full"
                  />
                </div>
                <div className="mt-6">
                  <label className="block text-md font-medium mb-2 flex items-center gap-2">
                    OpenAI Base Url
                    <HelpTextCallout calloutText={pageHelpTexts[2].description} />
                  </label>
                  <input
                    type="text"
                    value={settings.openAiBaseUrl}
                    onChange={handleOpenAIBaseUrlChange}
                    className="border rounded p-2 w-full"
                  />
                </div>
                <div className="mt-6">
                  <label className="block text-md font-medium mb-2 flex items-center gap-2">
                    OpenAI Model
                    <HelpTextCallout calloutText={pageHelpTexts[3].description} />
                  </label>
                  <input
                    type="text"
                    value={settings.openAIModel}
                    className="border rounded p-2 w-full"
                    disabled
                  />
                </div>
              </>
            )}
          </>
        )}
        {isLoading || noProjectSettings ? null : (
          <div className="mt-6 flex justify-end">
            <button
              onClick={handleUpdateOverrideSettings}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
            >
              Save SmartDoc Settings
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SmartDoc;
