import axios, { AxiosError } from 'axios';
import { SESSION_HEADER } from '../constants/app.constant';
import { externalConfig } from 'src/utils/misc.utils';
import Swal from 'sweetalert2';

let isTokenRefreshing = false;
let tokenRefreshPromise: Promise<any> | null = null;

const refreshAuthToken = async () => {
  try {
    const refreshToken = sessionStorage.getItem('refreshToken');
    const refreshResult = await axios.post(
      `${externalConfig.REACT_APP_GATEWAY_URL}/cf-user-service/auth/refresh/token`,
      { refreshToken },
    );
    return refreshResult.data;
  } catch (refreshErr) {
    return Promise.reject(refreshErr);
  }
};

const setAuthHeaders = (token?: string) => {
  axios.defaults.maxBodyLength = 1073741824; // 1 GB in bytes;
  axios.defaults.maxContentLength = 1073741824; // 1 GB in bytes;

  axios.interceptors.request.use(config => {
    const accessToken = sessionStorage.getItem('accessToken') || token;
    const headers = config.headers;
    headers[SESSION_HEADER] = sessionStorage.getItem(SESSION_HEADER);
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async (error: AxiosError) => {
      if (
        error.response &&
        error.config &&
        error.response.status === 401 &&
        error.response?.data
      ) {
        try {
          const latestAuthToken = sessionStorage.getItem('accessToken');

          if (
            latestAuthToken &&
            `Bearer ${latestAuthToken}` !== error.config.headers.Authorization
          ) {
            error.config.headers.Authorization = `Bearer ${latestAuthToken}`;
            return axios(error.config);
          }

          if (!isTokenRefreshing) {
            isTokenRefreshing = true;
            tokenRefreshPromise = refreshAuthToken();
          }

          const refreshResult = await tokenRefreshPromise;

          if (refreshResult.data.accessToken) {
            sessionStorage.setItem(
              'accessToken',
              refreshResult.data.accessToken,
            );
            sessionStorage.setItem(
              'refreshToken',
              refreshResult.data.refreshToken,
            );
            error.config.headers.Authorization = refreshResult.data.accessToken;
            return axios(error.config);
          }
        } catch (refreshErr) {
          Swal.fire({
            icon: 'error',
            title: 'Session Expired',
            text: 'Your session has expired! Please login again to continue using the application.',
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonText: 'Login',
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
          return Promise.reject(refreshErr);
        } finally {
          isTokenRefreshing = false;
          tokenRefreshPromise = null;
        }
      }
      return Promise.reject(error);
    },
  );
};

export default setAuthHeaders;
