import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/ui/table';

const VersionTemplate = ({ getStatusBadge, services }: any) => {
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Service Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Version</TableHead>
            <TableHead>Platform Version</TableHead>
            <TableHead className="hidden md:table-cell">
              Log File Name
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="overflow-auto">
          {services.map(service => (
            <TableRow key={service.name} className="group">
              <TableCell className="font-medium min-w-[14rem]">
                {service.name
                  .replaceAll(/-/g, ' ')
                  .toLowerCase()
                  .replace(/\b\w/g, l => l.toUpperCase())}
              </TableCell>
              <TableCell className="min-w-[10rem]">
                {getStatusBadge(service.status)}
              </TableCell>
              <TableCell className="min-w-[10rem]">{service.version}</TableCell>
              <TableCell>{service.platformVersion}</TableCell>
              <TableCell className="hidden md:table-cell font-mono text-sm text-muted-foreground">
                {service.logFileName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default VersionTemplate;
